import { useEffect } from 'react';

// Функция для установки метки времени последнего входа
const setLastLoginTime = () => {
  localStorage.setItem('lastLoginTime', Date.now().toString());
};

// Функция для получения метки времени последнего входа
const getLastLoginTime = () => {
  return parseInt(localStorage.getItem('lastLoginTime'), 10);
};

// Функция для проверки, прошло ли больше 7 дней с последнего входа
const shouldClearStorage = () => {
  const lastLoginTime = getLastLoginTime();
  if (!lastLoginTime) return false; // если метка времени отсутствует
  const now = Date.now();
  const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  return now - lastLoginTime > sevenDaysInMilliseconds;
};

// Функция для очистки определенных ключей из localStorage
const clearLocalStorageKeys = (keys) => {
  keys.forEach(key => {
    localStorage.removeItem(key);
  });
};

const useClearLocalStorageOnInactivity = (keys) => {
  useEffect(() => {
    if (shouldClearStorage()) {
      clearLocalStorageKeys(keys);
    }
    // Устанавливаем метку времени последнего входа при монтировании компонента
    setLastLoginTime();
  }, [keys]);
};

export default useClearLocalStorageOnInactivity;
