import React from 'react';
import { Stack, Box } from '@mui/material';
import { sectionsStyle } from '../../styles';
import PageTitle from '../PageTitle/PageTitle';
import bg from '../../images/repairs_responsibility/repairs_responsibility.jpeg';
import bg2 from '../../images/repairs_responsibility/picture1.jpeg';
import bg3 from '../../images/repairs_responsibility/picture2.jpeg';
import { fonts } from '../../fonts';
import useWindowWidth from '../../utils/screenSize';
import { FormattedMessage } from 'react-intl';
import LazyLoad from 'react-lazyload';

const OurResponsibility = () => {
  return (
    <>
      {useWindowWidth() >= 1200 ? (
        <Stack sx={{ alignItems: 'center', width: '100%' }}>
          <Stack
            gap="100px"
            sx={{ width: 'calc(100% - 40px)', maxWidth: '1600px' }}
          >
            <PageTitle
              text={<FormattedMessage id={'responsibility_work_principle'} />}
              optionStyle={{ fontSize: '70px' }}
            />
            <LazyLoad offset={100}>
              <Stack direction="row" gap="30px">
                <Stack gap="30px">
                  <Stack direction="row" gap="20px">
                    <img
                      src={bg3}
                      alt=""
                      width="100%"
                      style={{
                        borderRadius: '5px',
                        width: 'calc(100%/2 - 10px)',
                      }}
                    />
                    <img
                      src={bg2}
                      alt=""
                      width="100%"
                      style={{
                        borderRadius: '5px',
                        width: 'calc(100%/2 - 10px)',
                      }}
                    />
                  </Stack>
                  <Box
                    component="p"
                    sx={{ ...fonts.fontInter16_400, lineHeight: '24px' }}
                  >
                    <FormattedMessage
                      id={'responsibility_work_principle_text1'}
                    />
                  </Box>
                  <Box
                    component="p"
                    sx={{ ...fonts.fontInter16_400, lineHeight: '24px' }}
                  >
                    <FormattedMessage
                      id={'responsibility_work_principle_text2'}
                    />
                  </Box>
                </Stack>
                <img
                  src={bg}
                  alt=""
                  style={{ width: 'calc(100%/2 - 10px)', borderRadius: '10px' }}
                />
              </Stack>
            </LazyLoad>
          </Stack>
        </Stack>
      ) : (
        <Stack gap="30px">
          <Stack sx={{ ...sectionsStyle }} gap="30px">
            <PageTitle
              text={<FormattedMessage id={'responsibility_work_principle'} />}
            />
            <Stack direction="row" gap="20px">
              <img
                src={bg3}
                alt=""
                width="100%"
                style={{ borderRadius: '5px', width: 'calc(100%/2 - 10px)' }}
              />
              <img
                src={bg2}
                alt=""
                width="100%"
                style={{ borderRadius: '5px', width: 'calc(100%/2 - 10px)' }}
              />
            </Stack>
            <Box
              component="p"
              sx={{ ...fonts.fontInter16_400, lineHeight: '24px' }}
            >
              <FormattedMessage id={'responsibility_work_principle_text1'} />
            </Box>
            <Box
              component="p"
              sx={{ ...fonts.fontInter16_400, lineHeight: '24px' }}
            >
              <FormattedMessage id={'responsibility_work_principle_text2'} />
            </Box>
          </Stack>
          <img src={bg} alt="" width="100%" />
        </Stack>
      )}
    </>
  );
};

export default OurResponsibility;
