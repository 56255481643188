import React from 'react';
import { Stack } from '@mui/material';
import bg from '../../images/chooseDroneSectionImg.jpeg';
import { sectionsStyle } from '../../styles.js';
import PageTitle from '../PageTitle/PageTitle';
import BlueButton from '../Buttons/BlueButton';
import { fonts } from '../../fonts.js';
import PinkButton from '../Buttons/PinkButton';
import useWindowWidth from '../../utils/screenSize';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import  LazyLoad  from 'react-lazyload';

const ChooseDrone = () => {
  const stylesThisSection = {
    position: 'relative',
    backgroundImage: `url(${bg})`,
    overflow: 'hidden',
    padding: '50px 0px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  const pseudoElementStyle = {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(166, 177, 226, 0.6)',
    zIndex: 1,
  };
  const stylesThisSectionHD = {
    position: 'relative',
    alignItems:'center',
    justifyContent:'center',
    height: '612px',
    backgroundImage: `url(${bg})`,
    overflow: 'hidden',
    padding: '50px 20px',
    backgroundSize: '100%',
    backgroundRepeat:'no-repeat',
    backgroundPosition: '0px -100px',
  };
 
  
  return (
    <>
      {useWindowWidth() >= 1200 ? (
        <LazyLoad offset={100}>
          <Stack  sx={{ ...stylesThisSectionHD }}>
            {' '}
            <div style={pseudoElementStyle} />
            <Stack gap='60px' sx={{zIndex: '2',width:'100%',maxWidth:'1600px'}}>
              <Stack direction='row'  sx={{alignItems:'center'}}>
                <PageTitle text={<FormattedMessage id={"explore_new_heights"}/>} optionStyle={{fontSize:'70px'}}/> &nbsp; &nbsp;
                <Link to={"/catalog"}>
                  <BlueButton
                   width={'682px'}
                   height={'100px'}
                    text={<FormattedMessage id={"choose_drone"}/>}
                    optionStyle={{ ...fonts.fontPoppins80 }}
                  />
                </Link>
              </Stack>
              <PageTitle text={<FormattedMessage id={"rental_for_your_next_venture"}/>} optionStyle={{fontSize:'70px',textAlign:'center'}}/>
              <Link to='/catalog' style={{maxWidth:'311px',margin: 'auto'}}>
                <PinkButton
                  text={<FormattedMessage id={"get_started"}/>}
                  width="311px"
                  height="72px"
                  optionStyle={{ margin: 'auto' }}
                />
              </Link>
            </Stack>
          </Stack>
        </LazyLoad>
      ) : (
        <LazyLoad offset={100}>
          <Stack sx={{ ...stylesThisSection }}>
            <div style={pseudoElementStyle} />
            <Stack sx={{ ...sectionsStyle, zIndex: '2' }}>
              <PageTitle text={<FormattedMessage id={"explore_new_heights"}/>} />
              <Link to={'/catalog'}>
                <BlueButton
                  text={<FormattedMessage id={"choose_drone"}/>}
                  optionStyle={{ ...fonts.fontPoppins40 }}
                />
              </Link>
              <PageTitle
                text={<FormattedMessage id={"rental_for_your_next_venture"}/>}
                optionStyle={{ textAlign: 'center' }}
              />
              <Link to='/catalog' style={{maxWidth:'240px',margin: 'auto'}}>
                <PinkButton
                  text={<FormattedMessage id={"get_started"}/>}
                  width="240px"
                  optionStyle={{ margin: 'auto' }}
                />
              </Link>
            </Stack>
          </Stack>
        </LazyLoad>
      )}
    </>
  );
};

export default ChooseDrone;
