import React, { useRef } from 'react';
import { primary } from '../../../colors';
import { fonts } from '../../../fonts';
import { Stack } from '@mui/material';

const VerificationCodeInputs = ({ code, setCode,verification }) => {
  // Refs для всех инпутов
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  // Обработчик изменения значения в инпуте
  const handleChange = (index, e) => {
    const value = e.target.value;
    // Устанавливаем значение в соответствующее поле
    const newCode = [...code];
    newCode[index] = value;
  
    setCode(newCode);
    // Передвигаем фокус к следующему инпуту, если значение введено
    if (value !== '' && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  // Обработчик нажатия клавиши Backspace для удаления символа
  const handleBackspace = (index, e) => {
    if (e.keyCode === 8 && index > 0 && code[index] === '') {
      inputRefs[index - 1].current.focus();
    }
  };

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
      {code.map((value, index) => (
        <input
          autoComplete="off"
          
          key={index}
          type="text"
          value={value}
          onChange={(e) => handleChange(index, e)}
          onKeyDown={(e) => handleBackspace(index, e)}
          maxLength={1} // Ограничение на ввод одного символа
          ref={inputRefs[index]} // Привязываем ref к каждому инпуту
          style={{
            textAlign: 'center',
            width: '43px',
            height: '43px',
            borderRadius: '10px',
            borderColor: primary.blue,
            backgroundColor: primary.blue,
            color: '#fff',
            ...fonts.fontInter24,
          }}
        />
      ))}
    </Stack>
  );
};

export default VerificationCodeInputs;
