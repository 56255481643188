import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userRegistration } from '../../../store/slices/usersSlice.js';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { userRegistrationSchema } from '../../../utils/validationSchems.js';
import styles from './RegistrationForm.module.scss';
import { fonts } from '../../../fonts.js';
import PinkButton from '../../Buttons/PinkButton';
import { Box } from '@mui/material';
import useWindowWidth from '../../../utils/screenSize';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { setIsError } from '../../../store/slices/usersSlice.js';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import stylesSweetAlert from '../../../custom-sweetalert.module.scss';
const initialValues = {
  email: '',
  password1: '',
  password2: '',
};
const customStyleSweetAlert = {
  container: stylesSweetAlert.custom_swal_container,
  title: stylesSweetAlert.custom_swal_title,
  content: stylesSweetAlert.custom_swal_content,
  confirmButton: stylesSweetAlert.custom_swal_confirm_button,
  popup: stylesSweetAlert.swal2_popup,
  icon: stylesSweetAlert.custom_swal_icon,
};
const RegistrationForm = ({ intl }) => {
  const widthButton = useWindowWidth() >= 1200 ? '472px' : '168px';
  const heightButton = useWindowWidth() >= 1200 ? '72px' : '46px';
  const dispatch = useDispatch();
  const { errorRegistration} = useSelector((state) => state.users);
 

  useEffect(() => {
    if (errorRegistration.email) {
      console.log(errorRegistration.email[0]);
      Swal.fire({
        title: intl.formatMessage({ id: 'warning' }),
        text:intl.formatMessage({ id: 'choose_another_email' }),
        icon: 'warning',
        confirmButtonText: 'Ok',
        customClass: customStyleSweetAlert,
      });
      dispatch(setIsError());
    }
  }, [errorRegistration,dispatch]);


  const onSubmit = (values, { resetForm, setSubmitting }) => {
    dispatch(userRegistration(values));
    resetForm();
    setSubmitting(false);
  };

  return (
    <div className={styles.form}>
      <Formik
        initialValues={initialValues}
        validationSchema={userRegistrationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form style={{ width: '100%' }}>
            <div>
              <Field type="email" id="email" name="email"  placeholder={intl.formatMessage({ id: 'email' })}  style={{borderColor:'rgba(67, 97, 238, 0.2)'}}/>
              <ErrorMessage name="email">
                {(msg) => (
                  <div className={styles.error}>
                    <FormattedMessage id={msg} />
                  </div>
                )}
              </ErrorMessage>
            </div>

            <div>
              <Field
                type="password"
                id="password1"
                name="password1"
                placeholder={intl.formatMessage({ id: 'password' })}
                style={{borderColor:'rgba(67, 97, 238, 0.2)'}}
              />
             <ErrorMessage name="password1">
                {(msg) => (
                  <div className={styles.error}>
                    <FormattedMessage id={msg} />
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div>
              <Field
                type="password"
                id="password2"
                name="password2"
                placeholder={intl.formatMessage({ id: 'confirm_pass' })}
                style={{borderColor:'rgba(67, 97, 238, 0.2)'}}
              />
              <ErrorMessage name="password2">
                {(msg) => (
                  <div className={styles.error}>
                    <FormattedMessage id={msg} />
                  </div>
                )}
              </ErrorMessage>
            </div>
            <Box
              component="p"
              sx={{
                margin: '10px',
                ...fonts.fontInter16,
                textAlign: 'center',
                lineHeight: '2',
              }}
            >
             <FormattedMessage id={"password_must_be"}/>
            </Box>
            <PinkButton
              type="submit"
              text={<FormattedMessage id={"registration"}/>}
              width={widthButton}
              height={heightButton}
              optionStyle={{ margin: '10px' }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default injectIntl(RegistrationForm);
