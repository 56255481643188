import React from 'react';
import { Stack, Box } from '@mui/material';
import PinkButton from '../Buttons/PinkButton';
import { useNavigate } from 'react-router-dom';
import { fonts } from '../../fonts';
import useWindowWidth from '../../utils/screenSize';
import { FormattedMessage } from 'react-intl';
const RepairService = ({ title, description }) => {
  console.log(title);
  const navigate = useNavigate();
  const redirectLink = () => {
    navigate(`/order/${encodeURIComponent(title.props.id)}`);
  };
  return (
    <>
      {useWindowWidth() >= 1200 ? (
        <Stack
          gap="15px"
          sx={{
            backgroundColor: '#fff',
            width: 'calc(100% - 40px)',
            maxWidth:'381px',
            height:'293px',
            maxHeight:'293px',
            borderRadius: '10px',
            alignItems: 'center',
            padding: '15px 0px',
            position:'relative'
          }}
        >
          <Box sx={{ ...fonts.fontInter24, textAlign: 'center', }}>
            {title}
          </Box>
        
            <Box sx={{ ...fonts.fontInter18_400 ,position:'absolute', bottom:'130px'}}>{description}</Box>
              <PinkButton
                text={<FormattedMessage id={'get_free_quote'}/>}
                width={'311px'}
                height={'72px'}
                action={redirectLink}
                optionStyle={{ fontSize: '16px',position:'absolute', bottom:'30px'}}
              />
          
        
        </Stack>
      ) : (
        <Stack
          gap="15px"
          sx={{
            backgroundColor: '#fff',
            width: 'calc(100% - 40px)',
            borderRadius: '10px',
            alignItems: 'center',
            padding: '15px 0px',
          }}
        >
          <Box sx={{ ...fonts.fontInter18_600, textAlign: 'center' }}>
            {title}
          </Box>
          <Box sx={{ ...fonts.fontInter16_400 }}>{description}</Box>
          <PinkButton
            text={<FormattedMessage id={'get_free_quote'}/>}
            width={'240px'}
            height={'46px'}
            optionStyle={{ fontSize: '16px' }}
            action={redirectLink}
          />
        </Stack>
      )}
    </>
  );
};

export default RepairService;
