import React from 'react';
import { Stack, Box } from '@mui/material';
import { sectionsStyle } from '../../styles';

import { fonts } from '../../fonts';
import { primary } from '../../colors';
import useWindowWidth from '../../utils/screenSize';
import { FormattedMessage } from 'react-intl';

const RepairMotivation = () => {
  const spanStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: primary.blue,
    border: `2px solid ${primary.blue}`,
    width: '237px',
    height: '54px',
    borderRadius: '100px',
  };
  const spanStyleHD = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: primary.blue,
    border: `2px solid ${primary.blue}`,
    maxWidth: '475px',
    padding:'20px 30px',
    height: '54px',
    borderRadius: '100px',
  };
  const boxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 'calc(100% - 20px)',
    backgroundColor: primary.blue,
    height: '115px',
    borderRadius: '10px',
    color: '#fff',
    padding: '0px 10px',
    lineHeight: '24px',
  };
  const boxStyleHD = {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-around',
    flexDirection: 'column',
    width: 'calc(100% - 20px)',
    maxWidth:'361px',
    backgroundColor: primary.blue,
    height: '207px',
    borderRadius: '10px',
    color: '#fff',
    padding: '20px 10px 0px 10px',
    lineHeight: '24px',
    gap:'40px'
  };
  return (
    <>
      {useWindowWidth() >= 1200 ? (
        <Stack sx={{width:'100%',alignItems:'center'}}>
          <Stack sx={{flexDirection:'row',width:'calc(100% - 40px)',maxWidth:'1600px'}}>
            <Box
              component="p"
              sx={{ ...fonts.fontPoppins70, lineHeight: '105px',maxWidth:"700px" }}
            >
              <FormattedMessage id={"why_should_you_repair"}/>
             {' '}
              <span
                style={{
                  ...spanStyleHD,
                }}
              >
                <FormattedMessage id={"your_drone"}/>
              </span>
              <FormattedMessage id={"with_us"}/>?
            </Box>
            <Stack direction='row' gap='32px'>
              <Box sx={{ ...boxStyleHD }}>
                <Box component="h5" sx={{ ...fonts.fontInter24 }}>
                <FormattedMessage id={"comfort"}/>
                </Box>
                <Box
                  component="p"
                  sx={{ textAlign: 'center', ...fonts.fontInter24 }}
                >
                  <FormattedMessage id={"you_will_receive"}/>
                </Box>
              </Box>
              <Box sx={{ ...boxStyleHD }}>
                <Box component="h5" sx={{ ...fonts.fontInter24 }}>
                <FormattedMessage id={"reliability"}/>
                </Box>
                <Box
                  component="p"
                  sx={{ textAlign: 'center', ...fonts.fontInter24 }}
                >
                  <FormattedMessage id={"you_will_receive_professional"}/>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack sx={{ ...sectionsStyle }}>
          <Box
            component="p"
            sx={{ ...fonts.fontPoppins32, lineHeight: '48px' }}
          >
            <FormattedMessage id={"why_should_you_repair"}/>{' '}
            <span
              style={{
                ...spanStyle,
              }}
            >
              <FormattedMessage id={"your_drone"}/>
            </span>
            <FormattedMessage id={"with_us"}/>?
          </Box>
          <Box sx={{ ...boxStyle }}>
            <Box component="h5" sx={{ ...fonts.fontInter18_600 }}>
            <FormattedMessage id={"comfort"}/>
            </Box>
            <Box
              component="p"
              sx={{ textAlign: 'center', ...fonts.fontInter16_400 }}
            >
              <FormattedMessage id={"you_will_receive"}/>
            </Box>
          </Box>
          <Box sx={{ ...boxStyle }}>
            <Box component="h5" sx={{ ...fonts.fontInter18_600 }}>
            <FormattedMessage id={"reliability"}/>
            </Box>
            <Box
              component="p"
              sx={{ textAlign: 'center', ...fonts.fontInter16_400 }}
            >
              <FormattedMessage id={"you_will_receive_professional"}/>
            </Box>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default RepairMotivation;
