import React from 'react';
import { Slider, Typography, Box } from '@mui/material';
import { fonts } from '../../fonts';
import { FormattedMessage } from 'react-intl';
const RangeInput = ({filterData,handleChangePrice,min_price_month,max_price_month}) => {


  const handleChange = (event, newValue) => {
    handleChangePrice(newValue);
  };

  return (
    <Box width={300}>
      <Typography id="range-slider" gutterBottom component="h4" sx={{ ...fonts.fontInter24 }}>
      <FormattedMessage id={"price"}/>
      </Typography>
      <Slider
        value={filterData.price}
        onChange={handleChange}
        aria-labelledby="range-slider"
        valueLabelDisplay="auto"
        min={min_price_month}
        max={max_price_month}
      />
      <Typography>
      <FormattedMessage id={"selected_value"}/>: {filterData.price}
      </Typography>
    </Box>
  );
};

export default RangeInput;

