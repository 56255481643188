import React, { useState } from 'react';
import { Stack, Menu, MenuItem, IconButton } from '@mui/material';
import { MenuHeader, CardImg } from '../Icons/Icons';
import { Link, useNavigate } from 'react-router-dom';
import PinkButton from '../Buttons/PinkButton';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

import { fonts } from '../../fonts';
import { setIsRegister } from '../../store/slices/usersSlice.js';
import RegistrationIndicator from '../RegistrationIndicator/RegistrationIndicator';
import pictureMenu1 from '../../images/menuHeaderHd/myOrders.png';
import { useScroll } from '../ScrollContext/ScrollContext';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useDispatch } from 'react-redux';
import logo from '../../images/drone_logo.svg'
import { FormattedMessage } from 'react-intl';
import {

  TwitterIcon,
  YouTubeIcon,
  InstagramIcon,
  FacebookIcon,
} from '../Icons/Icons';
const HeaderMob = ({ indicator }) => {
  const { scrollToSection } = useScroll();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);

  const exitProfile = () => {
    handleClose1();
    localStorage.clear();
    dispatch(setIsRegister(false));
    navigate('/');
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  return (
    <>
      <Stack
        component="header"
        sx={{
          position: 'fixed',
          zIndex: '1000',
          top: '0px',
          left: '0px',
          width: 'calc(100% - 20px)',
          backgroundColor: 'white',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        <div>
          <IconButton
            sx={{ padding: '0px' }}
            id="basic-button"
            aria-controls={open1 ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? 'true' : undefined}
            onClick={handleClick1}
          >
            <MenuHeader />
          </IconButton>

          <Menu
           disableScrollLock={true}
            id="basic-menu"
            anchorEl={anchorEl1}
            open={open1}
            onClose={handleClose1}
            PaperProps={{
              style: {
                marginTop: '16px',
                width: '100%', // Пример: минимальная ширина

                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Пример: тень
                borderRadius: '8px', // Пример: скругление углов
              },
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              {indicator ? (
                <Link to="/profile">
                  <PinkButton text={<FormattedMessage id="profile"/>} width={'210px'} />
                </Link>
              ) : (
                <Link to="/login">
                  <PinkButton text={<FormattedMessage id="log_in"/>} width={'210px'} />
                </Link>
              )}
              <RegistrationIndicator />
              <LanguageSelector handleClose1={handleClose1}/>
            </MenuItem>

            <MenuItem onClick={handleClose1} sx={{ ...fonts.fontInter18_500 }}>
              {' '}
              <Link
                to="/catalog"
                style={{ textDecoration: 'none', color: '#000' }}
              >
                <FormattedMessage id="catalog"/>
              </Link>
            </MenuItem>
            <MenuItem
              onClick={handleClose1}
              sx={{ ...fonts.fontInter18_500 }}
              onClick={() => {
                navigate('/');
                scrollToSection('RepairDrones');
                handleClose1()
              }}
            >
             <FormattedMessage id="how_it_work"/>
            </MenuItem>
            <MenuItem onClick={handleClose1} sx={{ ...fonts.fontInter18_500 }}>
              {' '}
              <Link
                to="/repair"
                style={{ textDecoration: 'none', color: '#000' }}
              >
               <FormattedMessage id="drone_repair"/>
              </Link>
            </MenuItem>
            <MenuItem
              onClick={handleClose1}
              sx={{ ...fonts.fontInter18_500, margin: '15px 0px' }}
            >
              <Link
                to="/my-order-list"
                style={{
                  textDecoration: 'none',
                  color: '#000',
                  display: 'flex',
                  gap: '20px',
                }}
              >
                <img src={pictureMenu1} alt="" />
                <span><FormattedMessage id="my_orders"/></span>
              </Link>
            </MenuItem>
            <MenuItem
              onClick={exitProfile}
              sx={{ ...fonts.fontInter18_500, margin: '15px 0px' }}
            >
              <Stack
                direction="row"
                sx={{
                  textDecoration: 'none',
                  color: '#000',
                  display: 'flex',
                  gap: '20px',
                }}
              >
                <ExitToAppIcon />
                <span><FormattedMessage id="exit"/></span>
              </Stack>
            </MenuItem>

            <MenuItem
              direction="row"
              gap="33px"
              sx={{ alignItems: 'center', justifyContent: 'space-around' }}
            >
              <Link
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YouTubeIcon fill1={'#000'} fill2={'#fff'} />
              </Link>
              <Link
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon fill={'#000'} />
              </Link>
              <Link
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon fill={'#000'} />
              </Link>
             
            </MenuItem>
          </Menu>
        </div>
        <Link to="/" style={{ textDecoration: 'none' }}>
        <img src={logo} alt="" width='250px'/>
        </Link>

        <Link to="/cart">
          <IconButton sx={{ padding: '0px', width: '54px', height: '54px' }}>
            <CardImg />
          </IconButton>
        </Link>
      </Stack>
    </>
  );
};

export default HeaderMob;
