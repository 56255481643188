import React from 'react';
import { Stack, Box } from '@mui/material';
import PageTitle from '../PageTitle/PageTitle';
import OrderStatusSelector from '../OrderStatusSelector/OrderStatusSelector';
import { fonts } from '../../fonts';
import CustomerOrdersList from '../CustomerOrdersList/CustomerOrdersList';
import PinkButton from '../Buttons/PinkButton';
import { primary } from '../../colors';
import { sectionsStyle } from '../../styles';

const CustomerOrderMob = ({filteredOrders,isSelect,setSelect,selectStatus}) => {
  return (
    <Stack sx={{ ...sectionsStyle, gap: '40px' }}>
          <PageTitle text={'My orders'} />{' '}
          <OrderStatusSelector
            isSelect={isSelect}
            setSelect={setSelect}
            selectStatus={selectStatus}
          />
          <Box component="p" sx={{ ...fonts.fontInter24 }}>
            Order information
          </Box>
          <CustomerOrdersList orders={filteredOrders} />
         
            <PinkButton
              text={'Leave a review'}
              optionStyle={{
                backgroundColor: '#fff',
                color: primary.pink,
                margin:'auto',
                border:`1px solid ${primary.pink}`,
              }}
            />
            
         
        </Stack>
  );
}

export default CustomerOrderMob;
