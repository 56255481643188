import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProductList } from '../../store/slices/productsSlice.js';
import { Box, Stack, Button } from '@mui/material';
import PinkButton from '../Buttons/PinkButton';
import ProductList from '../ProductList/ProductList';
import { fonts } from '../../fonts.js';
import { primary } from '../../colors.js';
import PageTitle from '../PageTitle/PageTitle';
import { sectionsStyle } from '../../styles.js';
import useWindowWidth from '../../utils/screenSize';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const ProductsSection = () => {
  const fontStyle =
  useWindowWidth() >= 1200 ? fonts.fontPoppins70 : fonts.fontPoppins32;
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);
  let isPopularProducts = [];
  if (products) {
    isPopularProducts = products.filter((product) => product.is_popular);
  }

  useEffect(() => {
    dispatch(getProductList());
  }, [dispatch]);
  return (
    <Stack sx={{alignItems:'center'}}>
      <Stack
        component="section"
        sx={{
          ...sectionsStyle,
          margin: 'auto',
          maxWidth: '1600px',
          width: 'calc(100% - 40px)',
        }}
      >
        <PageTitle
          text={<FormattedMessage id={"most_popular"}/>}
          optionStyle={{ fontSize: fontStyle.fontSize }}
        />
        <Box
          sx={{
            gap: useWindowWidth() >= 1200 ? '64px' : '25px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ProductList propsProducts={isPopularProducts} />
          <Box
            sx={{
              gap: useWindowWidth() >= 1200 ? '80px' : '15px',
              display: 'flex',
              flexDirection: useWindowWidth() >= 1200 ? 'row' : 'column',
            }}
          >
            <Link to='/catalog'>
              <PinkButton
                text={<FormattedMessage id={"view_all"}/>}
                width={useWindowWidth() >= 1200 ? '406px' : '238px'}
                height={useWindowWidth() >= 1200 ? '72px' : '46px'}
              />
            </Link>
            <Link to='/consultation'>
              <Button
                variant="contained"
                sx={{
                  textTransform: 'none',
                  backgroundColor: '#fff',
                  color: primary.pink,
                  border: `1px solid ${primary.pink}`,
                  width: useWindowWidth() >= 1200 ? '406px' : '238px',
                  height: useWindowWidth() >= 1200 ? '72px' : '46px',
                  borderRadius: '100px',
                  ...fonts.fontInter18_600,
                  '&:hover': {
                    backgroundColor: primary.pink,
                    color: '#fff',
                  },
                }}
              >
               <FormattedMessage id={"get_advise"}/>
              </Button>
            </Link>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ProductsSection;
