import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as restController from '../../api/rest/restController';
import { pendingReducer, rejectedReducer } from '../../utils/store';

const REVIEWS_SLICE_NAME = 'reviews';
const initialState = {
  posts:[],
  faqs: [],
  reviews: [],
  error: null,
  isFetching: false,
};

export const getFAQList = createAsyncThunk(
  `${REVIEWS_SLICE_NAME}/getFAQList`,
  async (params = {}, thunkAPI) => {
    try {
      const data = await restController.getFAQList(params);
      return data;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);
export const get_WP_POST = createAsyncThunk(
  `${REVIEWS_SLICE_NAME}/get_WP_POST`,
  async (params = {}, thunkAPI) => {
    try {
      const data = await restController.get_WP_POST(params);
      console.log('get_WP_POST slice',data);
      return data;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);
export const getReviewsList = createAsyncThunk(
  `${REVIEWS_SLICE_NAME}/getReviewsList`,
  async (params = {}, thunkAPI) => {
    try {
      const data = await restController.getReviewsList(params);
      return data;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);

const extraReducers = (builder) => {
  builder.addCase(getFAQList.pending, pendingReducer);
  builder.addCase(getFAQList.fulfilled, (state, action) => {
    state.isFetching = false;
    state.faqs = action.payload;
  });
  builder.addCase(getFAQList.rejected, rejectedReducer);



  builder.addCase(getReviewsList.pending, pendingReducer);
  builder.addCase(getReviewsList.fulfilled, (state, action) => {
    state.isFetching = false;
    state.reviews = action.payload;
  });
  builder.addCase(getReviewsList.rejected, rejectedReducer);

  builder.addCase(get_WP_POST.pending, pendingReducer);
  builder.addCase(get_WP_POST.fulfilled, (state, action) => {
    const existingPost = state.posts.find(post => post.id === action.payload.id);
    if (!existingPost) {
      state.posts.push(action.payload);
    }
    state.isFetching = false;
  });
  builder.addCase(get_WP_POST.rejected, rejectedReducer);
};




const reviewSlice = createSlice({
  name: REVIEWS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers,
});
export default reviewSlice.reducer;
