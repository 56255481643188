import React from 'react';
import { Stack, Box } from '@mui/material';
import PageTitle from '../PageTitle/PageTitle';
import LazyLoad from 'react-lazyload';
import img4 from '../../images/how_it_works_section/img4.png';
import img3 from '../../images/how_it_works_section/img3.png';
import img2 from '../../images/how_it_works_section/img2.png';
import img1 from '../../images/how_it_works_section/img1.png';
import { fonts } from '../../fonts.js';
import { sectionsStyle } from '../../styles.js';
import RepairDronesSectionHD from '../RepairDronesSectionHD/RepairDronesSectionHD';
import useWindowWidth from '../../utils/screenSize';
import { useScroll } from '../ScrollContext/ScrollContext';
import { FormattedMessage } from 'react-intl';
const RepairDrones = () => {
  const { scrollToRef } = useScroll();
  return (
    <div ref={scrollToRef} id="scrollToRef">
      {' '}
      {useWindowWidth() >= 1200 ? (
        <RepairDronesSectionHD/>
      ) : (
        <Stack
          component="section"
          sx={{ backgroundColor: '#e4dbe6', padding: '30px 0px' }}
        >
          <Stack sx={{ ...sectionsStyle }}>
            <PageTitle text={<FormattedMessage id={"how_it_work"}/>} optionStyle={{margin:'auto'}}/>
            <Box>
              <Box
              
                sx={{
                  display: 'flex',
                  ...fonts.fontPoppins40,
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                1.
                <LazyLoad offset={100}><img src={img4} alt="img drone" width="137" /></LazyLoad>
              </Box>
              <Box
                component="h3"
                mb="10px"
                sx={{ ...fonts.fontInter24, textAlign: 'center' }}
              >
                <FormattedMessage id={"choose_your_drone"}/>
              </Box>
              <Box
                component="p"
                sx={{ ...fonts.fontInter16_400, textAlign: 'center',lineHeight:'24px' }}
              >
               <FormattedMessage id={"how_it_works_1"}/>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  ...fonts.fontPoppins40,
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                2.
                <LazyLoad offset={100}><img src={img3} alt="img drone" width="137" /></LazyLoad>
              </Box>
              <Box
                component="h3"
                mb="10px"
                sx={{ ...fonts.fontInter24, textAlign: 'center' }}
              >
                <FormattedMessage id={"verification_and_payment"}/>
              </Box>
              <Box
                component="p"
                sx={{ ...fonts.fontInter16_400, textAlign: 'center',lineHeight:'24px' }}
              >
                <FormattedMessage id={"how_it_works_2"}/>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  ...fonts.fontPoppins40,
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                3.
                <LazyLoad offset={100}><img src={img2} alt="img drone" width="137" /></LazyLoad>
              </Box>
              <Box
                component="h3"
                mb="10px"
                sx={{ ...fonts.fontInter24, textAlign: 'center' }}
              >
                <FormattedMessage id={"use_your_drone"}/>
              </Box>
              <Box
                component="p"
                
                sx={{ ...fonts.fontInter16_400, textAlign: 'center',lineHeight:'24px' }}
              >
                <FormattedMessage id={"how_it_works_3"}/>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  ...fonts.fontPoppins40,
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                4.
                <LazyLoad offset={100}><img src={img1} alt="img drone" width="137" /></LazyLoad>
              </Box>
              <Box
                component="h3"
                mb="10px"
                sx={{ ...fonts.fontInter24, textAlign: 'center' }}
              >
                <FormattedMessage id={"return_the_drone"}/>
              </Box>
              <Box
                component="p"
                sx={{ ...fonts.fontInter16_400, textAlign: 'center',lineHeight:'24px' }}
              >
                <FormattedMessage id={"how_it_works_4"}/>
              </Box>
            </Box>
          </Stack>
        </Stack>
      )}
    </div>
  );
};

export default RepairDrones;
