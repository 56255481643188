import React from 'react';
import { Stack, Box } from '@mui/material';
import { primary } from '../../colors';
import { fonts } from '../../fonts';
import { FormattedMessage } from 'react-intl';
import visa from '../../images/cards/visa.png';
import mastercard from '../../images/cards/mastercard-icon.png';
import maestro from '../../images/cards/maestro.png';
import amex from '../../images/cards/american-express.svg';
const ProductRentTerms = () => {
  return (
    <Stack  gap="16px" sx={{alignItems:'center'}}>
     <Stack gap="15px"  sx={{backgroundColor:primary.blue,color:'#fff',padding:'20px',width:'calc(100% - 80px)',borderRadius:'10px'}}>
       <Box sx={{...fonts.fontInter18_600,textAlign:'center'}}><FormattedMessage id={"delivery_return"}/></Box>
       <Box sx={{...fonts.fontInter16_400,textAlign:'center',lineHeight:'24px'}}><FormattedMessage id={"delivery_price"}/></Box>
     </Stack>
     <Stack gap="15px"  sx={{backgroundColor:primary.blue,color:'#fff',padding:'20px',width:'calc(100% - 80px)',borderRadius:'10px'}}>
       <Box sx={{...fonts.fontInter18_600,textAlign:'center'}}><FormattedMessage id={"easy_payment"}/></Box>
       <Box sx={{...fonts.fontInter16_400,textAlign:'center',lineHeight:'24px'}}><FormattedMessage id={"no_deposit"}/></Box>
       <Stack direction="row"  sx={{backgroundColor:'#fff',padding:'4px',borderRadius:'4px',alignItems:'center',justifyContent:'space-around'}}>
                  {' '}
                  <img src={visa} alt="" style={{ width: '48px',height:"32px" }} />
                  <img src={mastercard} alt="" style={{ width: '48px',height:"32px" }} />
                  <img src={maestro} alt="" style={{ width: '48px',height:"24px" }} />
                  <img src={amex} alt="" style={{ width: '48px',height:"32px" }} />
                </Stack>
     </Stack>
     <Stack gap="15px"  sx={{backgroundColor:primary.blue,color:'#fff',padding:'20px',width:'calc(100% - 80px)',borderRadius:'10px'}}>
       <Box sx={{...fonts.fontInter18_600,textAlign:'center'}}><FormattedMessage id={"tech_in_top_condition"}/></Box>
       <Box sx={{...fonts.fontInter16_400,textAlign:'center',lineHeight:'24px'}}><FormattedMessage id={"all_checked"}/></Box>
     </Stack>
    </Stack>
  );
}

export default ProductRentTerms;
