import axios from 'axios';
import CONSTANTS from '../constants';
import history from '../browserHistory';

const instance = axios.create({
  baseURL: CONSTANTS.BASE_URL,
});

// Request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem(CONSTANTS.ACCESS_TOKEN);
    if (token) {
      config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
    }
    return config;
  },
  (err) => Promise.reject(err)
);

// Response interceptor
instance.interceptors.response.use(
  (response) => {
    if (response.data.access) {
      window.localStorage.setItem(CONSTANTS.ACCESS_TOKEN, response.data.access);
    }
    if (response.data.refresh) {
      window.localStorage.setItem(CONSTANTS.REFRESH_TOKEN, response.data.refresh);
    }
    return response;
  },
  async (err) => {
    console.log('Interceptor triggered');
    console.log('Error:', err);
    const originalRequest = err.config;
      if (err.response && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = window.localStorage.getItem(CONSTANTS.REFRESH_TOKEN);
        if (refreshToken) {
          try {
            const refreshResponse = await axios.post(`${CONSTANTS.BASE_URL}/api/v1/token/refresh/`, {
              refresh: refreshToken,
            });
            const newAccessToken = refreshResponse.data.access;
            window.localStorage.setItem(CONSTANTS.ACCESS_TOKEN, newAccessToken);
            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
            console.log('Retrying original request with new access token');
            return axios(originalRequest);
          } catch (error) {
            console.error('Error during token refresh:', error);
            history.replace('/login');
            return Promise.reject(error);
          }
        } else {
          console.log('No refresh token found. Redirecting to login.');
          localStorage.clear();
          // history.replace('/');
          return ;
        }
      } else {
        localStorage.clear();
        // history.replace('/');
        return ;
      }
  
  }
);

export default instance;
