import React from 'react';
import { Button } from '@mui/material';
import { primary } from '../../colors.js';
const BlueButton = (props) => {

  return (
    <Button
      type={props.type}
      variant="contained"
      sx={{
        textTransform:'none',
        backgroundColor: primary.blue,
        borderRadius: '100px',
        width: props.width,
        height: props.height,
        '&:hover': {
          backgroundColor:primary.violet,
        },
     ...props.optionStyle
      }}
    >
      {props.text}
    </Button>
  );
};

export default BlueButton;
