import React, { useEffect, useState, Fragment } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { sectionsStyle } from '../../styles';
import { getUser } from '../../store/slices/usersSlice';
import ProfileForm from '../../components/Forms/ProfileForm/ProfileForm';
import ProfileMob from '../../components/Profile/ProfileMob';
import useWindowWidth from '../../utils/screenSize';
import ProfileHD from '../../components/Profile/ProfileHD';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
const Profile = () => {
  const { user, isRegister } = useSelector((state) => state.users);
  const [isChange, setIsChange] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const size = useWindowWidth() >= 1200 ? true : false;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch, isChange]);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      if (isRegister) {
        setIsLoading(false);
      } 
      else{navigate('/login')}
    }, 1000);
  }, [dispatch, isRegister,navigate]);
  


  if (isLoading) {
    return (
      <Stack
        gap="10px"
        sx={{ alignItems: 'center', justifyContent: 'center', height: '100vh' }}
      >
        <CircularProgress />
        Loading...
      </Stack>
    );
  }

  
    return (
      <>
        <Header />
        <Stack mt="86px" sx={{ ...sectionsStyle }}>
          {!isChange ? (
            size ? (
              <ProfileHD user={user} setIsChange={setIsChange} />
            ) : (
              <ProfileMob user={user} setIsChange={setIsChange} />
            )
          ) : (
            <ProfileForm
              user={user}
              isChange={isChange}
              setIsChange={setIsChange}
            />
          )}
        </Stack>
        <Footer />
      </>
    );
  
};

export default Profile;
