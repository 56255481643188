import React, { useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PinkButton from '../../Buttons/PinkButton';
import { Stack, Box } from '@mui/material';
import { fonts } from '../../../fonts';
import { useNavigate } from 'react-router-dom';
import { orderProcessingValidationSchemas } from '../../../utils/validationSchems.js';
import { orderCreate } from '../../../store/slices/customerOrdersSlice.js';
import { primary } from '../../../colors';
import PageTitle from '../../PageTitle/PageTitle';
import StripeSubscription from '../StripePaymentForm/StripeSubscription';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { loadStripe } from '@stripe/stripe-js';
import StripePaymentMemoryCard from '../StripePaymentForm/StripePaymentMemoryCard';
import { Elements } from '@stripe/react-stripe-js';
import CONSTANTS from '../../../constants';
import img_deposit from '../../../images/deposit.jpg';

import YesOrNoSwitcher from '../ProductForm/YesOrNoSwitcher';
const inputStyle = {
  paddingLeft: '20px',
  height: '72px',
  borderRadius: '100px',
 borderColor:'rgba(67, 97, 238, 0.2)'
};
const errorStyle = {
  color: 'red',
  marginTop: '5px',
};
const cubeStyle = {
  width: '54px',
  height: '54px',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...fonts.fontInter24,
  borderRadius: '4px',
  cursor:'pointer'
};
const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
};
const switchStyle = {
  width: '25%',
  transition: '0.8s',
  cursor: 'pointer',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const switchStyle2 = {
  width: '200px',
  height: '50px',
  ...fonts.fontInter16_600,
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  borderRadius: '100px',
  border: `1px solid rgba(67, 97, 238, 0.5)`,
};
const CreatingOrderFormHD = ({
  cart,
  user,
  step,
  setStep,
  Steps,
  verification,
  subscription,
  payment,
  intl,
  isResident,
  setIsResident,
  totalPriceDeposit
}) => {
  const stripePromise = loadStripe(CONSTANTS.PAYMENT_KEY);
  const { memory_card_in_cart, isLicensePilot } = useSelector(
    (state) => state.products
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const totalPriceCard = memory_card_in_cart.reduce(
    (sum, item) => sum + item.price,
    0
  );
 
  useEffect(() => {
    if (subscription && verification) {
      setStep(Steps.fourthStep);
    }
  }, [subscription]);

  useEffect(() => {
    if (
      payment &&
      verification &&
      !isResident &&
      memory_card_in_cart.length > 0
    ) {
      setStep(Steps.depositStep);
    } else if (
      subscription &&
      verification &&
      !isResident &&
      memory_card_in_cart.length === 0
    ) {
      setStep(Steps.depositStep);
    }
  }, [payment, subscription, isResident, memory_card_in_cart]);
  const handleSubmit = (
    values,
    { setFieldValue, resetForm, setSubmitting }
  ) => {
    const items = cart.map((item) => {
      let rentalDuration;
      switch (item.rental_duration) {
        case 'oneMonths':
          rentalDuration = '1M';
          break;
        case 'threeMonths':
          rentalDuration = '3M';
          break;
        case 'sixMonths':
          rentalDuration = '6M';
          break;
        case 'twelveMonths':
          rentalDuration = '12M';
          break;
        default:
          rentalDuration = '1M';
      }
      return {
        product: item.id,
        quantity: item.quantity,
        rental_duration: rentalDuration,
      };
    });

    const memory_cards = memory_card_in_cart.map((card) => ({
      memory_card: card.id,
      quantity: card.quantity,
    }));

    let formData = {};
    formData = {
      name: values.name,
      surname: values.surname,
      phone: values.phone,
      city: values.city,
      delivery_address: values.delivery_address,
      items: items,
      license: isLicensePilot,
      memory_cards: memory_cards,
      isResident: isResident,
    };
    dispatch(orderCreate(formData));
    setSubmitting(false);
    resetForm();
  };

  const changeStep = async (step, validateForm, setFieldTouched) => {
    const errors = await validateForm();
    Object.keys(errors).forEach((field) => setFieldTouched(field, true, false));
    console.log('errors', errors);
    if (Object.keys(errors).length === 0) {
      setStep(step);
    }
  };

  return (
    <Stack gap="30px" sx={{ width: '100%', maxWidth: '695px' }}>
      <PageTitle
        text={<FormattedMessage id={'order_processing'} />}
        optionStyle={{ fontSize: '72px' }}
      />
      <Formik
        initialValues={{ ...user, email: user.user_email, password: '' }}
        validationSchema={orderProcessingValidationSchemas[step]}
        onSubmit={handleSubmit}
      >
        {({
          values,
          setFieldValue,
          validateForm,
          isSubmitting,
          setFieldTouched,
        }) => (
          <Form style={{ ...formStyle }}>
            {/* Step 1 */}
            <Stack gap="30px">
              <Stack direction="row" gap="10px" sx={{ alignItems: 'center' }}>
                <Box
                  sx={{
                    ...cubeStyle,
                    backgroundColor:
                      step === Steps.firstStep ? primary.blue : '#a1b0f7',
                  }}
                  onClick={() =>
                    changeStep(Steps.firstStep, validateForm, setFieldTouched)
                  }
                >
                  1
                </Box>
                <span style={{ ...fonts.fontInter24 }}>
                  <FormattedMessage id={'your_contact_details'} />
                </span>
              </Stack>
              {step === Steps.firstStep ? (
                <Stack gap="32px">
                  <Stack gap="15px">
                    <Field
                      name="name"
                      type="text"
                      placeholder={intl.formatMessage({ id: 'name' })}
                      style={{ ...inputStyle }}
                    />
                    <ErrorMessage name="name">
                      {(msg) => (
                        <div style={errorStyle}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                    <Field
                      name="surname"
                      id="surname"
                      type="text"
                      placeholder={intl.formatMessage({ id: 'surname' })}
                      style={{ ...inputStyle }}
                    />
                    <ErrorMessage name="surname">
                      {(msg) => (
                        <div style={errorStyle}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                    <Field
                      name="phone"
                      type="tel"
                      placeholder={intl.formatMessage({ id: 'phone' })}
                      style={{ ...inputStyle }}
                    />
                    <ErrorMessage name="phone">
                      {(msg) => (
                        <div style={errorStyle}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                  </Stack>
                  <PinkButton
                    text={<FormattedMessage id={'next'} />}
                    width={'237px'}
                    height={'72px'}
                    action={() =>
                      changeStep(
                        Steps.secondStep,
                        validateForm,
                        setFieldTouched
                      )
                    }
                  />
                </Stack>
              ) : (
                <div>
                  {values.name} {values.surname}, {values.phone}
                </div>
              )}
            </Stack>
            {/* Step 2 */}
            <Stack direction="row" gap="10px" sx={{ alignItems: 'center' }}>
              <Box
                sx={{
                  ...cubeStyle,
                  backgroundColor:
                    step === Steps.secondStep ? primary.blue : '#a1b0f7',
                }}
                onClick={() =>
                  changeStep(Steps.secondStep, validateForm, setFieldTouched)
                }
              >
                2
              </Box>
              <span style={{ ...fonts.fontInter24 }}>
                <FormattedMessage id={'delivery'} />
              </span>
            </Stack>
            {step === Steps.secondStep ? (
              <Stack gap="32px">
                <Stack gap="15px">
                  <Field
                    name="city"
                    type="text"
                    placeholder={intl.formatMessage({ id: 'city' })}
                    style={{ ...inputStyle }}
                  />
                  <ErrorMessage name="city">
                    {(msg) => (
                      <div style={errorStyle}>
                        <FormattedMessage id={msg} />
                      </div>
                    )}
                  </ErrorMessage>
                  <Field
                    name="delivery_address"
                    type="text"
                    placeholder={intl.formatMessage({ id: 'address' })}
                    style={{ ...inputStyle }}
                  />
                  <ErrorMessage name="delivery_address">
                    {(msg) => (
                      <div style={errorStyle}>
                        <FormattedMessage id={msg} />
                      </div>
                    )}
                  </ErrorMessage>
                  <YesOrNoSwitcher
                    switchStyle={switchStyle}
                    trigger={isResident}
                    setTrigger={setIsResident}
                    switchStyle2={switchStyle2}
                    text={<FormattedMessage id={'are_you_dutch_citizen'} />}
                  />
                </Stack>
                <PinkButton
                  text={'Order'}
                  type="submit"
                  action={() => {
                    changeStep(Steps.thirdStep, validateForm, setFieldTouched);
                    document
                      .querySelector('form')
                      .dispatchEvent(
                        new Event('submit', { cancelable: true, bubbles: true })
                      );
                  }}
                  width={'237px'}
                  height={'72px'}
                />
              </Stack>
            ) : (
              <div>
                {values.city}, {values.delivery_address}, {values.email}
              </div>
            )}
            {/* Step 3 */}
            <Stack direction="row" gap="10px" sx={{ alignItems: 'center' }}>
              <Box
                sx={{
                  ...cubeStyle,
                  backgroundColor:
                    step === Steps.thirdStep ? primary.blue : '#a1b0f7',
                }}
                onClick={() =>
                  changeStep(Steps.thirdStep, validateForm, setFieldTouched)
                }
              >
                3
              </Box>
              {/* )} */}

              {verification.isVerification === false && subscription && (
                <Stack gap="30px ">
                  <span style={{ ...fonts.fontInter24 }}>
                    <FormattedMessage id={'verification'} />
                  </span>
                </Stack>
              )}
              {verification.isVerification === true && !subscription && (
                <Stack gap="30px ">
                  <span style={{ ...fonts.fontInter24 }}>
                    <FormattedMessage id={'subscription'} />
                  </span>
                </Stack>
              )}
              {verification.isVerification === true && subscription && (
                <Stack gap="30px ">
                  <span style={{ ...fonts.fontInter24 }}>
                    <FormattedMessage id={'subscription'} />
                  </span>
                </Stack>
              )}
              {verification.isVerification === false && !subscription && (
                <Stack gap="30px ">
                  <span style={{ ...fonts.fontInter24 }}>
                    <FormattedMessage id={'subscription'} />
                  </span>
                </Stack>
              )}
            </Stack>

            {step === Steps.thirdStep &&
              verification.isVerification === false &&
              subscription && (
                <PinkButton
                  text={<FormattedMessage id={'verification'} />}
                  action={() => {
                    navigate('/verification');
                  }}
                  width={'237px'}
                  height={'72px'}
                />
              )}
          </Form>
        )}
      </Formik>
      {step === Steps.thirdStep && !subscription && (
        <Stack gap="30px ">
          <Elements stripe={stripePromise}>
            <StripeSubscription />
          </Elements>
        </Stack>
      )}

      {/* Step 4 */}
      {memory_card_in_cart.length > 0 && (
        <Stack direction="row" gap="10px" sx={{ alignItems: 'center' }}>
          <Box
            sx={{
              ...cubeStyle,
              backgroundColor:
                step === Steps.fourthStep ? primary.blue : '#a1b0f7',
            }}
            onClick={() => setStep(Steps.fourthStep)}
          >
            4
          </Box>
          <span style={{ ...fonts.fontInter24 }}>
            <FormattedMessage id={'buying_card'} />
          </span>
        </Stack>
      )}

      {memory_card_in_cart.length > 0 && step === Steps.fourthStep && (
        <Elements stripe={stripePromise}>
          {' '}
          <StripePaymentMemoryCard
            totalPrice={totalPriceCard}
            payment_type={'MC'}
          />
        </Elements>
      )}

      {/* Step 5 */}
      {!isResident && (
       
          <Stack direction="row" gap="10px" sx={{ alignItems: 'center' }}>
            <Box
              sx={{
                ...cubeStyle,
                backgroundColor:
                  step === Steps.depositStep ? primary.blue : '#a1b0f7',
          
              }}
              onClick={() => setStep(Steps.depositStep)}
            >
              {memory_card_in_cart.length > 0 ? 5 : 4}
            </Box>
            <span style={{ ...fonts.fontInter24 }}>
              <FormattedMessage id={'drone_deposit'} />
            </span>
          </Stack>
            
    
        
      )}

      {!isResident && step === Steps.depositStep && (<>
        <div>
        <Stack
          direction="row"
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          {' '}
          <img
            src={img_deposit}
            alt=""
            style={{ width: '100%', maxWidth: '80px' }}
          />{' '}
          <Box
            sx={{
              ...fonts.fontInter24,
              display: 'inline',
              textAlign: 'end',
              lineHeight: '40px',
            }}
          >
            <FormattedMessage id={'drone_deposit'} />:<br /> €
            {totalPriceDeposit}
            <FormattedMessage id={'tax_included'} />
          </Box>
        </Stack>
        <Stack sx={{ borderBottom: '1px solid gray' }}></Stack>
      </div>
        <Elements stripe={stripePromise}>
          {' '}
          <StripePaymentMemoryCard
            totalPrice={totalPriceDeposit}
            payment_type={'DEP'}
          />
        </Elements>
        </>
      )}
    </Stack>
  );
};

export default injectIntl(CreatingOrderFormHD);
