export const getTodayDate = (params= new Date()) => {
  const today = params;
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // Январь это 0!
  const dd = String(today.getDate()).padStart(2, '0');
  const date = {
    year: yyyy,
    month: mm,
    day: dd,
  };
  return date;
};
