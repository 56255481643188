import React, { useEffect } from 'react';
import AccordionComponent from '../Accordion/Accordion';
import { Stack, Box } from '@mui/material';
import { sectionsStyle } from '../../styles';
import PageTitle from '../PageTitle/PageTitle';
import useWindowWidth from '../../utils/screenSize.js';
import { fonts } from '../../fonts';
import { FormattedMessage } from 'react-intl';
import { getFAQList } from '../../store/slices/reviews_faqs';
import { useDispatch, useSelector } from 'react-redux';
const FAQ = () => {
  const dispatch = useDispatch();
  const { faqs } = useSelector((state) => state.reviews);
  const { locale } = useSelector((state) => state.languages);


  useEffect(() => {
   dispatch(getFAQList(locale)) ;
  }, []);

  return (
    <>
      {useWindowWidth() >= 1200 ? (
        <Stack sx={{ alignItems: 'center' }} mt="40px" mb="40px" width="100%">
          <Stack
            direction="row"
            gap="82px"
            sx={{ width: 'calc(100% - 40px)', maxWidth: '1600px' }}
          >
            <Stack gap="35px">
              {' '}
              <PageTitle text={'FAQ'} optionStyle={{ fontSize: '70px' }} />
              <Box
                component="p"
                sx={{ maxWidth: '326px', ...fonts.fontInter18_400 }}
              >
                <FormattedMessage id={'faq'} />
              </Box>
            </Stack>
            <AccordionComponent faq={faqs}/>
          </Stack>
        </Stack>
      ) : (
        <Stack sx={{ ...sectionsStyle }}>
          <PageTitle text={'FAQ'} />
          <AccordionComponent faq={faqs}/>
        </Stack>
      )}
    </>
  );
};

export default FAQ;
