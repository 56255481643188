import React from 'react';
import { Stack, Box } from '@mui/material';
import { fonts } from '../../../fonts';
import { primary } from '../../../colors';
import { FormattedMessage } from 'react-intl';
import useWindowWidth from '../../../utils/screenSize';

const MonthsSwitcher = ({
  setSelectedTerm,
  setRentPrice,
  Term,
  characteristics,
  switchStyle,
  selectedTerm
}) => {
  let font=useWindowWidth()>1200?fonts.fontInter16_600:fonts.fontInter13_600
  return (
    <Stack
      direction="row"
      sx={{
        ...font,
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        borderRadius: '100px',
        border: `2px solid rgba(67, 97, 238, 0.5)`,
        height: '50px',
      }}
    >
      <Box
        onClick={() => {
          setSelectedTerm(Term.oneMonths);
          setRentPrice(characteristics.price_month);
        }}
        sx={{
          ...switchStyle,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor:
            selectedTerm === Term.oneMonths ? primary.blue : 'white',
          color: selectedTerm === Term.oneMonths ? 'white' : 'black',
          borderRadius: '100px 0 0 100px',
          transform:
            selectedTerm === Term.oneMonths ? 'scale(1.2)' : 'scale(1)',
          zIndex: selectedTerm === Term.oneMonths && 10,
        }}
      >
        1 <FormattedMessage id={"months"}/>
      </Box>
      <Box
        onClick={() => {
          setSelectedTerm(Term.threeMonths);
          setRentPrice(characteristics.price_3_months);
        }}
        sx={{
          ...switchStyle,
          backgroundColor:
            selectedTerm === Term.threeMonths ? primary.blue : 'white',
          color: selectedTerm === Term.threeMonths ? 'white' : 'black',
          transform:
            selectedTerm === Term.threeMonths ? 'scale(1.2)' : 'scale(1)',
          zIndex: selectedTerm === Term.threeMonths && 10,
        }}
      >
        3 <FormattedMessage id={"months"}/>
      </Box>
      <Box
        onClick={() => {
          setSelectedTerm(Term.sixMonths);
          setRentPrice(characteristics.price_6_months);
        }}
        sx={{
          ...switchStyle,
          backgroundColor:
            selectedTerm === Term.sixMonths ? primary.blue : 'white',
          color: selectedTerm === Term.sixMonths ? 'white' : 'black',
          transform:
            selectedTerm === Term.sixMonths ? 'scale(1.2)' : 'scale(1)',
          zIndex: selectedTerm === Term.sixMonths && 10,
        }}
      >
        6 <FormattedMessage id={"months"}/>
      </Box>
      <Box
        onClick={() => {
          setSelectedTerm(Term.twelveMonths);
          setRentPrice(characteristics.price_year);
        }}
        sx={{
          ...switchStyle,
          backgroundColor:
            selectedTerm === Term.twelveMonths ? primary.blue : 'white',
          color: selectedTerm === Term.twelveMonths ? 'white' : 'black',
          transform:
            selectedTerm === Term.twelveMonths ? 'scale(1.2)' : 'scale(1)',
          borderRadius: '0px 100px 100px 0px',
          zIndex: selectedTerm === Term.twelveMonths && 10,
        }}
      >
        12 <FormattedMessage id={"months"}/>
      </Box>
    </Stack>
  );
};

export default MonthsSwitcher;
