import React from 'react';
import { Stack, Button, Box } from '@mui/material';
import PageTitle from '../PageTitle/PageTitle';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { primary } from '../../colors';
import { fonts } from '../../fonts';
import { setIsRegister } from '../../store/slices/usersSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BreadcrumbsCustom from '../Breadcrumbs/Breadcrumbs';
import { FormattedMessage } from 'react-intl';
const linkStyle = {
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  width: '100%',
  maxWidth: '341px',
  height: '68px',
  borderRadius: '10px',
  color: '#000',
  ...fonts.fontInter18_400,
  transition: '0.5s',
  padding: '0px 20px',
  ':hover': {
    color: '#fff',
    backgroundColor: primary.blue,
    transition: '0.5s',
  },
};
// linkStyle[':visited'] = {
//   color: 'inherit',
// };
const ProfileHD = ({ user, setIsChange }) => {
  const { isRegister } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const exitProfile = () => {
    localStorage.clear();
    dispatch(setIsRegister(false));
    navigate('/');
  };
  return (
    <Stack sx={{ alignItems: 'center' }}>
      <Stack sx={{ width: '100%', maxWidth: '1600px', gap: '50px' }}>
        <BreadcrumbsCustom pageName={<FormattedMessage id="profile" />} />
        <PageTitle
          text={<FormattedMessage id="personal_office"/>}
          optionStyle={{ fontSize: '40px' }}
        />
        <Stack direction="row" gap="200px" sx={{ flexWrap: 'wrap-reverse' }}>
          <Stack>
            <Link to={isRegister?"/profile":"/login"} style={{ ...linkStyle }}>
              <PersonOutlineOutlinedIcon /><FormattedMessage id="my_profile" />
            </Link>
            <Link to={isRegister?"/my-order-list":"/login"} style={{ ...linkStyle }}>
              <GradingOutlinedIcon />
              <FormattedMessage id="my_orders"/>
            </Link>
            <Stack direction="row" onClick={exitProfile} sx={{ ...linkStyle }}>
              {' '}
              <ExitToAppIcon />
              <span><FormattedMessage id="exit"/></span>
            </Stack>
          </Stack>
          <Stack sx={{ width: '100%', maxWidth: '1100px', gap: '30px' }}>
            <span style={{ ...fonts.fontPoppins80 }}><FormattedMessage id="my_profile" /></span>
            <Stack
              direction="row"
              sx={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Stack gap="20px" direction="row" sx={{alignItems:'center'}}>
                <PersonOutlineOutlinedIcon sx={{ fontSize: '34px' }} />
                <span style={{ ...fonts.fontInter24 }}><FormattedMessage id="personal_data"/></span>
              </Stack>
              <Button
                style={{ ...fonts.fontInter18_600, color: primary.blue }}
                onClick={() => {
                  setIsChange(true);
                }}
              >
               <FormattedMessage id="change"/>
              </Button>
            </Stack>
            <Stack
              direction="row"
              gap="48px"
              sx={{ flexWrap: 'wrap', justifyContent: 'space-between' }}
            >
              <Stack gap="15px">
                <Box sx={{ ...fonts.fontInter14_300 }}><FormattedMessage id="name"/></Box>
                <Box sx={{ ...fonts.fontInter18_600 }}>{user?.name}</Box>
              </Stack>
              <Stack gap="15px">
                <Box sx={{ ...fonts.fontInter14_300 }}><FormattedMessage id="surname"/></Box>
                <Box sx={{ ...fonts.fontInter18_600 }}>{user?.surname}</Box>
              </Stack>
              <Stack gap="15px">
                <Box sx={{ ...fonts.fontInter14_300 }}><FormattedMessage id="birthday"/></Box>
                <Box sx={{ ...fonts.fontInter18_600 }}>
                  {user?.date_of_birth}
                </Box>
              </Stack>
              <Stack gap="15px">
                <Box sx={{ ...fonts.fontInter14_300 }}><FormattedMessage id="sex"/></Box>
                <Box sx={{ ...fonts.fontInter18_600 }}>{user?.sex}</Box>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              gap="20px"
              sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
            >
              <MailOutlineIcon sx={{ fontSize: '34px' }} />
              <Box style={{ ...fonts.fontInter24 }}><FormattedMessage id="contacts"/></Box>
            </Stack>
            <Stack
              direction="row"
             
              sx={{ flexWrap: 'wrap',justifyContent:'space-between' }}
            >
              
              <Stack  direction="row"  gap="100px" sx={{ flexWrap: 'wrap' }}>
                <Stack gap="15px">
                  <Box sx={{ ...fonts.fontInter14_300 }}><FormattedMessage id="phone"/></Box>
                  <Box sx={{ ...fonts.fontInter18_600 }}>{user?.phone}</Box>
                </Stack>
                <Stack gap="15px">
                  <Box sx={{ ...fonts.fontInter14_300 }}>Email</Box>
                  <Box sx={{ ...fonts.fontInter18_600 }}>{user?.user_email}</Box>
                </Stack>
              </Stack>
              <Link
              to='/change-password'
                style={{ ...fonts.fontInter18_600, color: primary.blue,textDecoration:'none' }}
              >
               <FormattedMessage id="change_password"/>
              </Link>
            </Stack>

            <Stack
              direction="row"
              gap="20px"
              sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
            >
              <LocalShippingOutlinedIcon sx={{ fontSize: '34px' }} />
              <Box style={{ ...fonts.fontInter24 }}><FormattedMessage id="delivery"/></Box>
            </Stack>
            <Box sx={{ ...fonts.fontInter18_600 }}>
              {user?.delivery_address}
            </Box>
            <Stack
              direction="row"
              gap="20px"
              sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
            >
            <ApartmentIcon />
            <Box style={{ ...fonts.fontInter24 }}><FormattedMessage id="city"/></Box>
            </Stack>
            <Box sx={{ ...fonts.fontInter18_600 }}>{user?.city}</Box>
           
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProfileHD;
