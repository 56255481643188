import React from 'react';
import { Stack, Box } from '@mui/material';
import PageTitle from '../PageTitle/PageTitle';
import { sectionsStyle } from '../../styles';
import { fonts } from '../../fonts';
import { primary } from '../../colors';
import useWindowWidth from '../../utils/screenSize';
import PinkButton from '../Buttons/PinkButton';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
const Cubes = () => {
  const navigate=useNavigate();
  const cubeLittleStyle = {
    flexBasis: '126px',
    maxHeight: '162',
    maxWidth: '129px',
    borderRadius: '7px',
    padding: '18px',
  };
  const cube01StyleHD = {
    height: '203px',
    maxWidth: '207px',
    borderRadius: '7px',
    padding: '18px',
    color: '#fff',
  };
  const cube02StyleHD = {
    height: '203px',
    width:'100%',
    maxWidth: '482px',
    borderRadius: '7px',
    padding: '18px',
  };

  const cube05StyleHD = {
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '20px',
    backgroundColor: '#4361EE33',
    padding: '20px 2px',
    maxWidth: 243,
    height: 454,
    borderRadius: '7px',
  };
  const cube1Style = {
    flexBasis: '226px',
    maxWidth: '129px',
    borderRadius: '7px',
    padding: '18px',
  };
  const cube2Style = {
    flexBasis: '204px',
    maxWidth: '129px',
    borderRadius: '7px',
    padding: '18px',
  };
  const redirectLink = () => {
    navigate(`/order/${encodeURIComponent('General repairs')}`);
  };
  return (
    <>
      {useWindowWidth() >= 1200 ? (
        <Stack sx={{ ...sectionsStyle, alignItems: 'center' }}>
          <Stack direction="row" sx={{ width: '100%', maxWidth: '1600px' }}>
            <Stack gap="32px">
              <PageTitle
                text={<FormattedMessage id={"main_causes_falls"}/>}
                optionStyle={{ fontSize: '70px', maxWidth: '532px' }}
              />
              <Box
                component="p"
                sx={{
                  ...fonts.fontInter18_400,
                  lineHeight: '27px',
                  maxWidth: '378px',
                }}
              >
               <FormattedMessage id={"cubes_text"}/>
              </Box>
              <PinkButton
              action={redirectLink}
                width={'381px'}
                height={'72px'}
                text={<FormattedMessage id={"get_free_quote"}/>}
              />
            </Stack>
            <Stack gap="16px" direction="row">
              <Stack gap="16px" direction="column">
                <Stack direction="row" gap="16px">
                  <Stack
                    sx={{
                      ...cube01StyleHD,
                      backgroundColor: primary.blue,
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '20px',
                    }}
                  >
                    <Box sx={{ ...fonts.fontPoppins80 }}>01</Box>
                    <Box
                      sx={{
                        ...fonts.fontInter18_600,
                        textAlign: 'center',
                        lineHeight: '24px',
                      }}
                    >
                      <FormattedMessage id={"REP"}/>
                    </Box>
                  </Stack>
                  <Stack
                    sx={{
                      ...cube02StyleHD,
                      backgroundColor: '#4361EE33',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '20px',
                    }}
                  >
                    <Box sx={{ ...fonts.fontPoppins80 }}>02</Box>
                    <Box
                      sx={{
                        ...fonts.fontInter18_600,
                        textAlign: 'center',
                        lineHeight: '24px',
                      }}
                    >
                      <FormattedMessage id={"operator_errors_co_pilot"}/>
                    </Box>
                  </Stack>
                </Stack>

                <Stack gap="16px" direction="row">
                  <Stack
                    sx={{
                      ...cube02StyleHD,
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '20px',
                      backgroundColor: '#4361EE33',
                    }}
                  >
                    <Box sx={{ ...fonts.fontPoppins80 }}>03</Box>
                    <Box
                      sx={{
                        ...fonts.fontInter18_600,
                        textAlign: 'center',
                        lineHeight: '24px',
                      }}
                    >
                      <FormattedMessage id={"inadequate_control"}/>
                    </Box>
                  </Stack>
                  <Stack
                    sx={{
                      ...cube01StyleHD,
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '20px',
                      backgroundColor: primary.blue,
                    }}
                  >
                    <Box sx={{ ...fonts.fontPoppins80 }}>04</Box>
                    <Box
                      sx={{
                        ...fonts.fontInter18_600,
                        textAlign: 'center',
                        lineHeight: '24px',
                      }}
                    >
                      <FormattedMessage id={"flights_in_extreme"}/>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  ...cube05StyleHD,
                }}
              >
                <Box sx={{ ...fonts.fontPoppins80 }}>05</Box>
                <Box
                  sx={{
                    ...fonts.fontInter18_600,
                    textAlign: 'center',
                    lineHeight: '24px',
                  }}
                >
                  <FormattedMessage id={"control_connection"}/>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack sx={{ ...sectionsStyle }}>
          <PageTitle text={<FormattedMessage id={"main_causes_falls"}/>} />
          <Box
            component="p"
            sx={{ ...fonts.fontInter16_400, lineHeight: '24px' }}
          >
            <FormattedMessage id={"cubes_text"}/>
          </Box>
          <Stack direction="row" gap="16px">
            <Stack gap="16px" direction="column">
              <Stack
                sx={{
                  ...cubeLittleStyle,
                  backgroundColor: '#4361EE33',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '20px',
                }}
              >
                <Box sx={{ ...fonts.fontInter40 }}>01</Box>
                <Box
                  sx={{
                    ...fonts.fontInter16_600,
                    textAlign: 'center',
                    lineHeight: '24px',
                  }}
                >
                  <FormattedMessage id={"REP"}/>
                </Box>
              </Stack>
              <Stack
                sx={{
                  ...cubeLittleStyle,
                  backgroundColor: '#4361EE33',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '20px',
                }}
              >
                <Box sx={{ ...fonts.fontInter40 }}>03</Box>
                <Box
                  sx={{
                    ...fonts.fontInter16_600,
                    textAlign: 'center',
                    lineHeight: '24px',
                  }}
                >
                  <FormattedMessage id={"operator_errors_co_pilot"}/>
                </Box>
              </Stack>
              <Stack
                sx={{
                  color: '#fff',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '20px',
                  backgroundColor: primary.blue,
                  padding: '0px 2px',
                  width: 161,
                  height: 162,
                  borderRadius: '7px',
                }}
              >
                <Box sx={{ ...fonts.fontInter40 }}>04</Box>
                <Box
                  sx={{
                    ...fonts.fontInter16_600,
                    textAlign: 'center',
                    lineHeight: '24px',
                  }}
                >
                  <FormattedMessage id={"control_connection"}/>
                </Box>
              </Stack>
            </Stack>
            <Stack gap="16px" direction="column">
              <Stack
                sx={{
                  color: '#fff',
                  ...cube1Style,
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '20px',
                  backgroundColor: primary.blue,
                }}
              >
                <Box sx={{ ...fonts.fontInter40 }}>02</Box>
                <Box
                  sx={{
                    ...fonts.fontInter16_600,
                    textAlign: 'center',
                    lineHeight: '24px',
                  }}
                >
                  <FormattedMessage id={"inadequate_control"}/>
                </Box>
              </Stack>
              <Stack
                sx={{
                  ...cube2Style,
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '20px',
                  backgroundColor: '#4361EE33',
                }}
              >
                <Box sx={{ ...fonts.fontInter40 }}>05</Box>
                <Box
                  sx={{
                    ...fonts.fontInter16_600,
                    textAlign: 'center',
                    lineHeight: '24px',
                  }}
                >
                  <FormattedMessage id={"flights_in_extreme"}/>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default Cubes;
