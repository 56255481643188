import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
  userVerificationSendPhone,
  setIsErrorSendCode,
  userVerificationSendCode,
  changePhoneVerificationStatus,
  userVerification,
  changeCodeVerificationStatus,
  repeatSendCode,
} from '../../../store/slices/usersSlice.js';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import PinkButton from '../../Buttons/PinkButton';
import { Stack, Box } from '@mui/material';
import { fonts } from '../../../fonts';
import VerificationCodeInputs from './VerificationCodeInputs';
import styles from './VerificationFormOrder.module.scss';
import { primary } from '../../../colors';
import StripeVerificationPayment from '../StripePaymentForm/StripeVerificationPayment';
import { verificationValidationSchemas } from '../../../utils/validationSchems.js';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import stylesSweetAlert from '../../../custom-sweetalert.module.scss';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CONSTANTS from '../../../constants';

const customStyleSweetAlert = {
  container: stylesSweetAlert.custom_swal_container,
  title: stylesSweetAlert.custom_swal_title,
  content: stylesSweetAlert.custom_swal_content,
  confirmButton: stylesSweetAlert.custom_swal_confirm_button,
  popup: stylesSweetAlert.swal2_popup,
  icon: stylesSweetAlert.custom_swal_icon,
};
const inputStyle = {
  paddingLeft: '20px',
  width: 'calc(100% - 20px)',
  height: '46px',
  borderRadius: '100px',
  borderColor:'rgba(67, 97, 238, 0.2)'
};
const switchStyle = {
  width: '25%',
  transition: '0.8s',
  cursor: 'pointer',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const errorStyle = {
  color: 'red',
};

const VerificationForm = ({ isSelect, setSelect, steps, intl }) => {
  const stripePromise = loadStripe(CONSTANTS.PAYMENT_KEY);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { verification } = useSelector((state) => state.users);
  const { isVerifyPayment } = useSelector((state) => state.payment);
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [codeRepeat, setCodeRepeat] = useState(false);
  const [selectedFileCount, setSelectedFileCount] = useState('');
  const [selectedFileCount2, setSelectedFileCount2] = useState('');
  const [selectedFileCount3, setSelectedFileCount3] = useState('');
  const [selectedFileCount4, setSelectedFileCount4] = useState('');
  const [marker, setMarker] = useState(false);

  const [isDebt, setIsDebt] = useState(false);
  useEffect(() => {
    const storedIsSendPhone = localStorage.getItem('isSendPhone');
    if (storedIsSendPhone) {
      dispatch(changePhoneVerificationStatus());
    }
  }, []);

  useEffect(() => {
    if (marker) {
      window.location.reload();
    }
  }, [marker]);

  useEffect(() => {
    const storedIsSendCode = localStorage.getItem('isProofCode');
    if (storedIsSendCode) {
      dispatch(changeCodeVerificationStatus());
    }
  }, []);

  useEffect(() => {
    if (verification.codeErrorMessage) {
      Swal.fire({
        title: intl.formatMessage({ id: 'warning' }),
        text: `${verification.codeErrorMessage} ${intl.formatMessage({
          id: 'contact_administration',
        })}`,
        icon: 'warning',
        confirmButtonText: 'Ok',
        customClass: customStyleSweetAlert,
      });
      dispatch(setIsErrorSendCode());
    }
  }, [verification.codeErrorMessage, dispatch]);

  useEffect(() => {
    if (isVerifyPayment) {
      Swal.fire({
        title: 'Info!',
        text: intl.formatMessage({ id: 'data_accepted' }),
        icon: 'info',
        confirmButtonText: 'Ok',
        customClass: customStyleSweetAlert,
      });
      navigate('/');
    }
  }, [isVerifyPayment, dispatch]);
  const formData = {
    street: '',
    city: '',
    country: '',
    postcode: '',
    address_files1: [],
    address_files2: [],
    id_card_files1: [],
    id_card_files2: [],
    income: '',
    isDebt: false,
    debt_sum: '',
  };
  const addFileToArr = (event, setFieldValue, existingFiles = [], arrName) => {
    const filesArray = Array.from(event.currentTarget.files);
    const updatedFiles = existingFiles.concat(filesArray);
    setFieldValue(arrName, updatedFiles);
  };

  const handleFileChange = (event) => {
    const { files } = event.target;
    setSelectedFileCount(`${files.length} file(s) selected`);
  };
  const handleFileChange2 = (event) => {
    const { files } = event.target;
    setSelectedFileCount2(`${files.length} file(s) selected`);
  };
  const handleFileChange3 = (event) => {
    const { files } = event.target;
    setSelectedFileCount3(`${files.length} file(s) selected`);
  };
  const handleFileChange4 = (event) => {
    const { files } = event.target;
    setSelectedFileCount4(`${files.length} file(s) selected`);
  };

  const handleSubmitCode = async (values, { resetForm, setSubmitting }) => {
    if (verification.isSendPhone === false && !codeRepeat) {
      dispatch(userVerificationSendPhone(values));
    }
    if (verification.isSendPhone && !codeRepeat) {
      values.verification_code = code.join('');
      await dispatch(userVerificationSendCode(values));
      setMarker(true);
    }
    if (codeRepeat) {
      dispatch(repeatSendCode(values));
      setCodeRepeat(false);
    }
  };
  const handleNextStep = (nextStep, validateForm, setFieldTouched, values) => {
    changeStep(nextStep, validateForm, setFieldTouched);
    document.querySelector('button[type="submit"]').click();
  };
  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    console.log('handleSubmit');
    const formData = new FormData();
    let combinedFiles = [];
    let combinedFiles2 = [];

    formData.append('street', values.street);
    formData.append('country', values.country);
    formData.append('city', values.city);
    formData.append('post_code', values.postcode);

    if (values.address_files1 && values.address_files1.length > 0) {
      combinedFiles = combinedFiles.concat(values.address_files1);
    }
    if (values.address_files2 && values.address_files2.length > 0) {
      combinedFiles = combinedFiles.concat(values.address_files2);
    }
    for (let i = 0; i < combinedFiles.length; i++) {
      formData.append(`address_files[${i}]`, combinedFiles[i]);
    }

    if (values.id_card_files1 && values.id_card_files1.length > 0) {
      combinedFiles2 = combinedFiles2.concat(values.id_card_files1);
    }
    if (values.id_card_files2 && values.id_card_files2.length > 0) {
      combinedFiles2 = combinedFiles2.concat(values.id_card_files2);
    }
    for (let i = 0; i < combinedFiles2.length; i++) {
      formData.append(`id_card_files[${i}]`, combinedFiles2[i]);
    }

    formData.append('debts', values.debt_sum);
    formData.append('loans', isDebt);
    formData.append('monthly_income', values.income);
    formData.append('agree', values.agree);

    formData.forEach((value, key) => {
      console.log(`FormData[${key}]:`, value);
    });

    dispatch(userVerification(formData));
  };

  const changeStep = async (isSelect, validateForm, setFieldTouched) => {
    const errors = await validateForm();
    Object.keys(errors).forEach((field) => setFieldTouched(field, true, false));
    console.log('errors', errors);
    if (Object.keys(errors).length === 0) {
      setSelect(isSelect);
    }
  };

  return (
    <div>
      {!verification.isSendPhone || !verification.isProofCode ? (
        <Formik
          initialValues={{
            phone_number: '',
            verification_code: '',
          }}
          validationSchema={verificationValidationSchemas[isSelect]}
          onSubmit={handleSubmitCode}
        >
          {({
            values,
            setFieldValue,
            validateForm,
            isSubmitting,
            setFieldTouched,
          }) => (
            <Form style={{ maxWidth: '790px' }}>
              <Stack gap="32px">
                <Stack gap="15px">
                  <Field
                    id="phone_number"
                    name="phone_number"
                    type="text"
                    placeholder={intl.formatMessage({ id: 'phone' })}
                    style={{ ...inputStyle }}
                  />
                  {verification.isSendPhone && (
                    <span style={{ color: 'green' }}>
                      <FormattedMessage id={'ver_message_1'} />
                    </span>
                  )}
                  <ErrorMessage name="phone_number">
                    {(msg) => (
                      <div style={errorStyle}>
                        <FormattedMessage id={msg} />
                      </div>
                    )}
                  </ErrorMessage>
                </Stack>
                <span style={{ ...fonts.fontInter16_400 }}>
                  <FormattedMessage id={'ver_message_2'} />
                </span>
                <VerificationCodeInputs
                  code={code}
                  setCode={setCode}
                  verification={verification}
                />
                {verification.isProofCode && (
                  <span style={{ color: 'green' }}>
                    <FormattedMessage id={'ver_message_2'} />
                  </span>
                )}

                <PinkButton
                  type="submit"
                  text={
                    !verification.isSendPhone ? (
                      <FormattedMessage id={'send_phone'} />
                    ) : (
                      <FormattedMessage id={'send_code'} />
                    )
                  }
                  width={'168px'}
                  optionStyle={{ margin: 'auto' }}
                />
                {verification.isSendPhone && (
                  <PinkButton
                    text={<FormattedMessage id={'resent_code'} />}
                    width={'168px'}
                    action={() => {
                      setCodeRepeat(true);
                      document.querySelector('form').dispatchEvent(
                        new Event('submit', {
                          cancelable: true,
                          bubbles: true,
                        })
                      );
                    }}
                    optionStyle={{ margin: 'auto' }}
                  />
                )}
                {verification.isProofCode && (
                  <PinkButton
                    text={'Next'}
                    width={'168px'}
                    action={() => {
                      changeStep(steps.step2);
                    }}
                    optionStyle={{ margin: 'auto' }}
                  />
                )}
              </Stack>
            </Form>
          )}
        </Formik>
      ) : (
        verification.waitingVerification===false&&
        (<Formik
          initialValues={{
            ...formData,
            isDebt,
          }}
          onSubmit={handleSubmit}
          validationSchema={verificationValidationSchemas[isSelect]}
        >
          {({
            values,
            setFieldValue,
            validateForm,
            setFieldTouched,
            isSubmitting,
          }) => (
            <Form>
              {/* Step 2 */}
              {isSelect === steps.step2 && (
                <Stack gap="32px">
                  <Stack gap="15px">
                    <Field
                      name="street"
                      type="text"
                      placeholder={intl.formatMessage({ id: 'street' })}
                      style={{ ...inputStyle }}
                    />
                    <ErrorMessage name="street">
                      {(msg) => (
                        <div style={errorStyle}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                    <Field
                      name="postcode"
                      type="text"
                      placeholder={intl.formatMessage({ id: 'post_code' })}
                      style={{ ...inputStyle }}
                    />
                    <ErrorMessage
                      name="postcode"
                      component="div"
                      style={errorStyle}
                    />

                    <Field
                      name="city"
                      type="text"
                      placeholder={intl.formatMessage({ id: 'city' })}
                      style={{ ...inputStyle }}
                    />
                    <ErrorMessage name="city">
                      {(msg) => (
                        <div style={errorStyle}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                    <Field
                      name="country"
                      type="text"
                      placeholder={intl.formatMessage({ id: 'country' })}
                      style={{ ...inputStyle }}
                    />
                    <ErrorMessage name="country">
                      {(msg) => (
                        <div style={errorStyle}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                  </Stack>
                  <span style={{ ...fonts.fontInter16_400 }}>
                    <FormattedMessage id={'ver_message_3'} />
                  </span>
                  <div className={styles.fileInputContainer}>
                    <label htmlFor="files" className={styles.fileInputLabel}>
                      <span className={styles.icon}>+</span>
                      <span className="selected-file-count">
                        {selectedFileCount3}
                      </span>
                    </label>
                    <input
                      className={styles.fileInput}
                      type="file"
                      id="address_files1"
                      name="address_files1"
                      multiple
                      accept="image/*"
                      onChange={(event) => {
                        handleFileChange3(event);
                        addFileToArr(
                          event,
                          setFieldValue,
                          values.address_files1,
                          'address_files1'
                        );
                      }}
                    />
                    <ErrorMessage name="address_files1">
                      {(msg) => (
                        <div style={errorStyle}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className={styles.fileInputContainer}>
                    <label htmlFor="files" className={styles.fileInputLabel}>
                      <span className={styles.icon}>+</span>
                      <span className="selected-file-count">
                        {selectedFileCount4}
                      </span>
                    </label>
                    <input
                      className={styles.fileInput}
                      type="file"
                      id="address_files2"
                      name="address_files2"
                      multiple
                      accept="image/*"
                      onChange={(event) => {
                        handleFileChange4(event);
                        addFileToArr(
                          event,
                          setFieldValue,
                          values.address_files2,
                          'address_files2'
                        );
                      }}
                    />
                    <ErrorMessage name="address_files2">
                      {(msg) => (
                        <div style={errorStyle}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <PinkButton
                    text={<FormattedMessage id={'next'} />}
                    action={() =>
                      changeStep(steps.step3, validateForm, setFieldTouched)
                    }
                    width={'168px'}
                    optionStyle={{ margin: 'auto' }}
                  />
                </Stack>
              )}

              {/* Step 3 */}
              {isSelect === steps.step3 && (
                <Stack gap="20px">
                  <span style={{ ...fonts.fontInter16_400 }}>
                    <FormattedMessage id={'upload_ID_card'} />
                  </span>
                  <div className={styles.fileInputContainer}>
                    <label htmlFor="files" className={styles.fileInputLabel}>
                      <span className={styles.icon}>+</span>
                      <span className="selected-file-count">
                        {selectedFileCount}
                      </span>
                    </label>
                    <input
                      className={styles.fileInput}
                      type="file"
                      id="id_card_files1"
                      name="id_card_files1"
                      multiple
                      accept="image/*"
                      onChange={(event) => {
                        handleFileChange(event);
                        addFileToArr(
                          event,
                          setFieldValue,
                          values.id_card_files1,
                          'id_card_files1'
                        );
                      }}
                    />
                    <ErrorMessage name="id_card_files1">
                      {(msg) => (
                        <div style={errorStyle}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <span style={{ ...fonts.fontInter16_400 }}>
                    <FormattedMessage id={'upload_ID_card_2'} />
                  </span>
                  <div className={styles.fileInputContainer}>
                    <label htmlFor="files" className={styles.fileInputLabel}>
                      <span className={styles.icon}>+</span>
                      <span className="selected-file-count">
                        {selectedFileCount2}
                      </span>
                    </label>
                    <input
                      className={styles.fileInput}
                      type="file"
                      id="id_card_files2"
                      name="id_card_files2"
                      multiple
                      accept="image/*"
                      onChange={(event) => {
                        handleFileChange2(event);
                        addFileToArr(
                          event,
                          setFieldValue,
                          values.id_card_files2,
                          'id_card_files2'
                        );
                      }}
                    />
                    <ErrorMessage name="id_card_files2">
                      {(msg) => (
                        <div style={errorStyle}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <PinkButton
                    text={<FormattedMessage id={'next'} />}
                    action={() =>
                      changeStep(steps.step4, validateForm, setFieldTouched)
                    }
                    width={'168px'}
                    optionStyle={{ margin: 'auto' }}
                  />
                </Stack>
              )}

              {/* Step 4 */}
              {isSelect === steps.step4 && (
                <Stack>
                  <Stack gap="30px">
                    <Box sx={{ ...fonts.fontInter16_400 }}>
                      <FormattedMessage id={'availability_loans'} />
                    </Box>
                    <Stack
                      direction="row"
                      sx={{
                        margin: 'auto',
                        width: '200px',
                        height: '72px',
                        ...fonts.fontInter16_600,
                        alignItems: 'center',
                        textAlign: 'center',
                        justifyContent: 'center',
                        borderRadius: '100px',
                        border: `1px solid rgba(67, 97, 238, 0.5)`,
                      }}
                    >
                      <Box
                        onClick={() => {
                          setIsDebt(true);
                          setFieldValue('isDebt', true);
                        }}
                        sx={{
                          ...switchStyle,
                          backgroundColor: isDebt ? primary.blue : 'white',
                          color: isDebt ? 'white' : 'black',
                          borderRadius: '100px 0 0 100px',
                          width: '50%',
                          transform: isDebt ? 'scale(1.2)' : 'scale(1)',
                          zIndex: isDebt && '10',
                        }}
                      >
                        <FormattedMessage id={'yes'} />
                      </Box>

                      <Box
                        onClick={() => {
                          setIsDebt(false);
                          setFieldValue('isDebt', false);
                        }}
                        sx={{
                          ...switchStyle,

                          backgroundColor: !isDebt ? primary.blue : 'white',
                          color: !isDebt ? 'white' : 'black',
                          transform: !isDebt ? 'scale(1.2)' : 'scale(1)',
                          borderRadius: '0 100px 100px 0',
                          width: '50%',
                          zIndex: !isDebt && '10',
                        }}
                      >
                        <FormattedMessage id={'no'} />
                      </Box>
                    </Stack>
                    <Box sx={{ ...fonts.fontInter16_400 }}>
                      <FormattedMessage id={'your_monthly_income'} />
                    </Box>
                    <Field
                      name="income"
                      type="number"
                      placeholder={intl.formatMessage({ id: 'enter_income' })}
                      style={{ ...inputStyle }}
                    />
                    <ErrorMessage name="income">
                      {(msg) => (
                        <div style={errorStyle}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                    {isDebt && (
                      <Stack sx={{ ...fonts.fontInter16_400, gap: '30px' }}>
                        <span>
                          <FormattedMessage id={'your_debt'} />
                        </span>
                        <Field
                          name="debt_sum"
                          type="number"
                          placeholder={intl.formatMessage({ id: 'enter_debt' })}
                          style={{ ...inputStyle }}
                        />
                        <ErrorMessage name="debt_sum">
                          {(msg) => (
                            <div style={errorStyle}>
                              <FormattedMessage id={msg} />
                            </div>
                          )}
                        </ErrorMessage>
                      </Stack>
                    )}

                    <div className={styles.radioContainer}>
                      <Field type="checkbox" name="agree" />
                      <label>
                      <FormattedMessage id={'i_agree_to_verify_my_card'} />
                      </label>
                    </div>
                    <ErrorMessage
                      name="agree"
                      component="div"
                      style={errorStyle}
                    />
                  </Stack>
                  <PinkButton
                    // type="submit"
                    text={'Send'}
                    width={'168px'}
                    optionStyle={{ margin: '20px auto' }}
                    action={() =>
                      handleNextStep(steps.step5, validateForm, setFieldTouched,values)
                     }
                  />
                </Stack>
              )}
               <button type="submit" style={{ display: 'none' }} />
            </Form>
          )}
        </Formik>)
      )}

      {isSelect === steps.step5 && verification.waitingVerification && (
        <Elements stripe={stripePromise}>
          <StripeVerificationPayment payment_type={'CV'} />
        </Elements>
      )}
    </div>
  );
};

export default injectIntl(VerificationForm);
