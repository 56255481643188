import React from 'react';
import PinkButton from '../Buttons/PinkButton';
import { FormattedMessage } from 'react-intl';

const CatalogScale = ({ currentPage, productsPerPage, totalProducts, onPageChange }) => {
  const shownProducts = Math.min(currentPage * productsPerPage, totalProducts);
  const text = `${shownProducts} of ${totalProducts} products`;

  const handleNextPage = () => {
    onPageChange(currentPage + 1);
  };

  const handlePrevPage = () => {
    onPageChange(currentPage - 1);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '20px' }}>
      <div style={{ width: '240px', margin: 'auto', display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <p>{shownProducts} <FormattedMessage id={"of"}/> {totalProducts} <FormattedMessage id={"products"}/></p>
        <div
          style={{
            width: '100%',
            backgroundColor: '#ccc',
            height: '5px',
            borderRadius: '10px',
            marginTop: '10px',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              width: `${(currentPage / Math.ceil(totalProducts / productsPerPage)) * 100}%`,
              height: '100%',
              backgroundColor: 'green',
            }}
          ></div>
        </div>
      </div>

      {/* Показать кнопку для перехода на предыдущую страницу, если не первая страница */}
      {currentPage !== 1 && (
        <PinkButton
          text={<FormattedMessage id={"previous_page"}/>}
          width="240px"
          optionStyle={{ margin: 'auto' }}
          action={handlePrevPage}
        />
      )}

      {/* Показать кнопку для перехода на следующую страницу, если не последняя страница */}
      {currentPage !== Math.ceil(totalProducts / productsPerPage) && (
        <PinkButton
          text={<FormattedMessage id={"next_page"}/>}
          width="240px"
          optionStyle={{ margin: 'auto' }}
          action={handleNextPage}
        />
      )}
    </div>
  );
};

export default CatalogScale;
