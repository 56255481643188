import React from 'react';
import {
  Radio,
  Button,
  FormControlLabel,
} from '@mui/material';
import { fonts } from '../../fonts';
import { primary } from '../../colors';

const termStyle = {
  width: '48px',
  height: '48px',
  border: '1px solid rgba(67, 97, 238, 0.3)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...fonts.fontInter18_400,
  textTransform: 'none',
};

const CustomRadioBtn = ({ value, label, selectedValue,borderRadius}) => {
  const isSelected = selectedValue === value;

  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          icon={ <Button
            sx={{
              ...termStyle,
              color: '#000',
              padding:'0px',
              minWidth:'48px',
              borderRadius:`${borderRadius}`
            }}
          ></Button>}
     
          checkedIcon={
            <Button
              sx={{
                ...termStyle,
                minWidth:'48px',
                backgroundColor: `${primary.blue}`,
                color: '#fff',
                padding:'0px',
                borderRadius:`${borderRadius}`
              }}
            ></Button>
          }
          checked={isSelected}
         
          
        />
      }
      label={label}
    />
  );
};

export default CustomRadioBtn;
