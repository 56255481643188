import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as restController from '../../api/rest/restController';
import { pendingReducer, rejectedReducer } from '../../utils/store';

const PAYMENT_SLICE_NAME = 'payment';
const initialState = {
  clientSecret: null,
  subscription: [],
  isSubscription: false,
  isPayment: false,
  isDeposit:false,
  isVerifyPayment:false,
  error: null,
  isFetching: false,
  plan_ID: '',
};

export const makePayment = createAsyncThunk(
  `${PAYMENT_SLICE_NAME}/makePayment`,
  async (payload, thunkAPI) => {
    try {
      const {data} = await restController.makePayment(payload);
      console.log('makePaymentSlice',data);
      return data;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);

export const getSubscription = createAsyncThunk(
  `${PAYMENT_SLICE_NAME}/getSubscription`,
  async (payload, thunkAPI) => {
    try {
      const { data } = await restController.getSubscription(payload);
      return data;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);
export const getVerify = createAsyncThunk(
  `${PAYMENT_SLICE_NAME}/getVerify`,
  async (payload, thunkAPI) => {
    try {
      const { data,status } = await restController.makePayment(payload);
      const result= {data,status }
      return result;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);

const extraReducers = (builder) => {
  builder.addCase(makePayment.pending, pendingReducer);
  builder.addCase(makePayment.fulfilled, (state, action) => {
    if(action.payload.payment_type==='MC'){
      state.isPayment = true;
    }if(action.payload.payment_type==='DEP'){
      state.isDeposit = true;
    }
    state.isFetching = false;
  });
  builder.addCase(makePayment.rejected, rejectedReducer);

  builder.addCase(getSubscription.pending, pendingReducer);
  builder.addCase(getSubscription.fulfilled, (state, action) => {
    
    state.subscription = action.payload;
    state.isSubscription = true;
    state.isFetching = false;
  });
  builder.addCase(getSubscription.rejected, rejectedReducer);

  builder.addCase(getVerify.pending, pendingReducer);
  builder.addCase(getVerify.fulfilled, (state, action) => {
    if(action.payload.status===200||action.payload.status===201){state.isVerifyPayment = true;}
    
    state.isFetching = false;
    console.log('action.payload', action);
    
  });
  builder.addCase(getVerify.rejected, rejectedReducer);
};



const paymentSlice = createSlice({
  name: PAYMENT_SLICE_NAME,
  initialState,
  reducers: {
    setIsPayment: (state, action) => {
      state.isPayment = action.payload;
    },
  },
  extraReducers,
});
export const { setIsPayment } = paymentSlice.actions;
export default paymentSlice.reducer;
