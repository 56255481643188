import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';
import { Box, Stack } from '@mui/material';
import { SwiperNavButtons3 } from './SliderBtns3';
import PageTitle from '../PageTitle/PageTitle';
import { sectionsStyle } from '../../styles';
import { fonts } from '../../fonts';
import styles from './SliderBtns3.module.scss';
import bg from '../../images/slider3Bg.png';
import { FormattedMessage } from 'react-intl';
import  LazyLoad  from 'react-lazyload';
const Slider3 = () => {
  return (
    
      <Stack
        sx={{
          ...sectionsStyle,
          alignItems: 'center',
          backgroundSize: '65%',
          height: '80vh',
          backgroundImage: `url(${bg})`,
          backgroundPosition: 'center center',
          backgroundRepeat:'no-repeat'
        }}
      >
        <Stack
          sx={{
            ...sectionsStyle,
            width: '100%',
            maxWidth: '1600px',
            alignItems: 'center',
            
          }}
        >
          <PageTitle text={<FormattedMessage id={"customer_testimonials"}/>} optionStyle={{fontSize:'70px'}}/>
          <Box>
            <Swiper
              style={{
                width: '1050px',
                height: '400px',
                paddingTop: '50px',
                paddingBottom: '50px',
               
              }}
              loop={true}
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={2}
              coverflowEffect={{
                rotate: 10,
                stretch: 5,
                depth: 137,
                modifier: 1,
                slideShadows: false,
              }}
              modules={[EffectCoverflow, Navigation]}
            >
              <SwiperSlide className={styles.swiperSlide}>
                <Stack mt={'50px'} mr={'40px'} ml={'40px'}>
                  <Box sx={{ textAlign: 'center', ...fonts.fontInter24 }}>
                  E.J.
                  </Box>
                  <Box
                    component="p"
                    mt={'40px'}
                    sx={{ textAlign: 'center', ...fonts.fontInter18_400 }}
                  >
                   <FormattedMessage id={"testimonial_1"}/>
                  </Box>
      
                </Stack>
      
              </SwiperSlide>
              <SwiperSlide className={styles.swiperSlide}>
                <Stack mt={'50px'} mr={'40px'} ml={'40px'}>
                  <Box sx={{ textAlign: 'center', ...fonts.fontInter24 }}>
                  S.W.
                  </Box>
                  <Box
                    component="p"
                    mt={'40px'}
                    sx={{ textAlign: 'center', ...fonts.fontInter18_400 }}
                  >
                   <FormattedMessage id={"testimonial_2"}/>
                  </Box>
      
                </Stack>
              </SwiperSlide>
              <SwiperSlide className={styles.swiperSlide}>
                <Stack mt={'50px'} mr={'40px'} ml={'40px'}>
                  <Box sx={{ textAlign: 'center', ...fonts.fontInter24 }}>
                  R.H.
                  </Box>
                  <Box
                    component="p"
                    mt={'40px'}
                    sx={{ textAlign: 'center', ...fonts.fontInter18_400 }}
                  >
                    <FormattedMessage id={"testimonial_3"}/>
                  </Box>
                </Stack>
              </SwiperSlide>
              <SwiperSlide className={styles.swiperSlide}>
                <Stack mt={'50px'} mr={'40px'} ml={'40px'}>
                  <Box sx={{ textAlign: 'center', ...fonts.fontInter24 }}>
                  L.K.
                  </Box>
                  <Box
                    component="p"
                    mt={'40px'}
                    sx={{ textAlign: 'center', ...fonts.fontInter18_400 }}
                  >
                   <FormattedMessage id={"testimonial_4"}/>
                  </Box>
                </Stack>
              </SwiperSlide>
              <SwiperSlide className={styles.swiperSlide}>
                <Stack mt={'50px'} mr={'40px'} ml={'40px'}>
                  <Box sx={{ textAlign: 'center', ...fonts.fontInter24 }}>
                  E.F.
                  </Box>
                  <Box
                    component="p"
                    mt={'40px'}
                    sx={{ textAlign: 'center', ...fonts.fontInter18_400 }}
                  >
                  <FormattedMessage id={"testimonial_5"}/>
                  </Box>
                </Stack>
              </SwiperSlide>
              <SwiperSlide className={styles.swiperSlide}>
                <Stack mt={'50px'} mr={'40px'} ml={'40px'}>
                  <Box sx={{ textAlign: 'center', ...fonts.fontInter24 }}>
                  E.C.
                  </Box>
                  <Box
                    component="p"
                    mt={'40px'}
                    sx={{ textAlign: 'center', ...fonts.fontInter18_400 }}
                  >
                    <FormattedMessage id={"testimonial_6"}/>
                  </Box>
                </Stack>
              </SwiperSlide>
              <SwiperSlide className={styles.swiperSlide}>
                <Stack mt={'50px'} mr={'40px'} ml={'40px'}>
                  <Box sx={{ textAlign: 'center', ...fonts.fontInter24 }}>
                  V.S.
                  </Box>
                  <Box
                    component="p"
                    mt={'40px'}
                    sx={{ textAlign: 'center', ...fonts.fontInter18_400 }}
                  >
               <FormattedMessage id={"testimonial_7"}/>
                  </Box>
                </Stack>
              </SwiperSlide>
              <SwiperSlide className={styles.swiperSlide}>
                <Stack mt={'50px'} mr={'40px'} ml={'40px'}>
                  <Box sx={{ textAlign: 'center', ...fonts.fontInter24 }}>
                  V.K.
                  </Box>
                  <Box
                    component="p"
                    mt={'40px'}
                    sx={{ textAlign: 'center', ...fonts.fontInter18_400 }}
                  >
               <FormattedMessage id={"testimonial_8"}/>
                  </Box>
                </Stack>
              </SwiperSlide>
              <SwiperNavButtons3 />
            </Swiper>
          </Box>
        </Stack>
      </Stack>

  );
};

export default Slider3;
