import React, { useState, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import {
  removeCardFromCart,
  addMemoryCard,
  removeTotalSumCard
} from '../../store/slices/productsSlice.js';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import card_picture from '../../images/memory_card.png'
import { fonts } from '../../fonts';
import useWindowWidth from '../../utils/screenSize';
import { FormattedMessage } from 'react-intl';
const counterStyle = {
  backgroundColor: '#EFF0F6',
  width: '32px',
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  cursor:'pointer'
};
const MemoryCardInCart = ({ product }) => {
  const {memory_card_in_cart} = useSelector((state) => state.products);
  const [counter, setCounter] = useState(product?.quantity);
  const [price, setPrice] = useState(product?.price);
  const dispatch = useDispatch();

  const handleRemoveFromCart = () => {
    dispatch(removeCardFromCart(product.id));
  };
  
  useEffect(() => {
    const updatedProduct = memory_card_in_cart.find((item) => item.id === product.id);
    if (updatedProduct) {
      setCounter(updatedProduct.quantity);
      setPrice(updatedProduct.price* updatedProduct.quantity);
    }
  }, [memory_card_in_cart, product.id]);
  
  const handleIncrementQuantity = () => {
    setCounter(counter + 1);
    setPrice(price + product.price);
    dispatch(addMemoryCard({ ...product, quantity: 1 }));
   
  };
  const handleDecrementQuantity = () => {
    if (counter > 1) {
      setCounter(counter - 1);
      setPrice(price - product.price);
       dispatch(removeTotalSumCard({price:product.price,productID: product.id}));
    }
  };

  return (
    <>
    {useWindowWidth()>=1200?(<Stack>
      <Stack
        direction="row"
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <img
          src={card_picture}
          alt=""
          width="203px"
          // height="89px"
          style={{ border: '1px solid #4361EE4D', borderRadius: '5px' }}
        />
        <Box sx={{ textAlign: 'center', ...fonts.fontInter16_400,display:'flex',flexDirection:'column',gap:'15px' }}>
        <div><span style={{...fonts.fontInter16_600}}><FormattedMessage id={'manufacturer'}/>: </span> {product?.manufacturer}</div>
          <div><span style={{...fonts.fontInter16_600}}><FormattedMessage id={'type'}/>: </span> {product?.type}</div>
          <div><span style={{...fonts.fontInter16_600}}><FormattedMessage id={'speed_in_characteristics'}/>: </span> {product?.speed}</div>
          <div><span style={{...fonts.fontInter16_600}}><FormattedMessage id={'capacity'}/>: </span>{product?.capacity}{parseInt(product?.capacity)<=5?"Tb":"Gb"}</div>
        </Box>{' '}
        <CloseIcon
          onClick={handleRemoveFromCart}
          fontSize="large"
          sx={{ padding: '0px', width: '58px', height: '60px' }}
        />
      </Stack>
      <Stack direction="row" gap="66px" sx={{ justifyContent: 'flex-end' }}>
        <Stack direction="row" sx={{ alignItems: 'center', gap: '14px' }}>
          <Box sx={{ ...counterStyle }} onClick={handleDecrementQuantity}>
            <RemoveIcon />
          </Box>
          <Box sx={{ ...fonts.fontInter24 }}>{counter}</Box>
          <Box sx={{ ...counterStyle }} onClick={handleIncrementQuantity}>
            <AddIcon />
          </Box>
        </Stack>
        <Stack
          direction="row"
          sx={{ alignItems: 'center', ...fonts.fontInter24 }}
        >
          <EuroSymbolIcon /> {price} <FormattedMessage id={'tax_included'} />
        </Stack>
      </Stack>
    </Stack>):(<Stack gap="20px">
      <Stack
        direction="row"
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <img
          src={card_picture}
          alt=""
          width="89px"
          height="89px"
          style={{ border: '1px solid #4361EE4D', borderRadius: '5px' }}
        />
        <Box sx={{ ...fonts.fontInter16_400,display:'flex',flexDirection:'column',gap:'5px',marginLeft:"5px" }}>
          <div><span style={{...fonts.fontInter16_600}}><FormattedMessage id={'manufacturer'}/>: </span> {product?.manufacturer}</div>
          <div><span style={{...fonts.fontInter16_600}}><FormattedMessage id={'type'}/>: </span> {product?.type}</div>
          <div><span style={{...fonts.fontInter16_600}}><FormattedMessage id={'speed_in_characteristics'}/>: </span> {product?.speed}</div>
          <div><span style={{...fonts.fontInter16_600}}><FormattedMessage id={'capacity'}/>: </span>{product?.capacity}{parseInt(product?.capacity)<=5?"Tb":"Gb"}</div>
        </Box>{' '}
        <CloseIcon
          onClick={handleRemoveFromCart}
          fontSize="large"
          sx={{ padding: '0px', width: '58px', height: '60px',cursor:'pointer' }}
        />
      </Stack>
      <Stack direction="row" gap="86px" sx={{ justifyContent: 'space-between' }}>
        <Stack direction="row" sx={{ alignItems: 'center', gap: '14px' }}>
          <Box sx={{ ...counterStyle }} onClick={handleDecrementQuantity}>
            <RemoveIcon />
          </Box>
          <Box sx={{ ...fonts.fontInter18_600 }}>{counter}</Box>
          <Box sx={{ ...counterStyle }} onClick={handleIncrementQuantity}>
            <AddIcon />
          </Box>
        </Stack>
        <Stack
          direction="row"
          sx={{ alignItems: 'center', ...fonts.fontInter18_600 }}
        >
          <EuroSymbolIcon /> {price} <FormattedMessage id={'tax_included'} />
        </Stack>
      </Stack>
    </Stack>)}
    </>
  );
};

export default MemoryCardInCart;
