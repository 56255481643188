import React from 'react';
import {
  unstable_HistoryRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';

import history from './browserHistory';
import Home from './pages/Home/Home.jsx';
import Catalog from './pages/Catalog/Catalog.jsx';
import Repair from './pages/Repair/Repair';
import RepairOrder from './pages/RepairOrder/RepairOrder';
import Login from './pages/Login/Login';
import Registration from './pages/Registration/Registration';
import Product from './pages/Product/Product';
import Cart from './pages/Cart/Cart';
import CreatingOrder from './pages/CreatingOrder/CreatingOrder';
import CustomCookieConsent from './components/CookieConsent/CustomCookieConsent';
import Profile from './pages/Profile/Profile';
import CustomerOrders from './pages/CustomerOrders/CustomerOrders';
import Verification from './pages/Verification/Verification';
import { useSelector } from 'react-redux';
import { ScrollProvider } from './components/ScrollContext/ScrollContext';
import Consultation from './pages/Consultation/Consultation';
import { IntlProvider } from 'react-intl';
import ScrollToTop from './components/ScrollToTop/ScrollToTop.jsx';
import messages from './locales/messages';
import RequestLink from './pages/ResetPassword/RequestLink';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import useClearLocalStorageOnInactivity from './utils/useClearLocalStorageOnInactivity';
import PaymentsPage from './pages/PaymentsPage/PaymentsPage';
import ChatwootWidget from './components/Chatwoot/Chatwoot';
function App() {
  const keysToClear = [
    'sorted_drones',
    'memoryCardInCart',
    'productsInCart',
    'totalSumCart',
    'totalSumMemoryCard',
    'totalDiscount',
    'order_id',
    'refreshToken',
    'isLicense',
    'isProofCode',
    'accessToken',
    'isSendPhone'
  ];
  const locale = useSelector((state) => state.languages.locale);
  useClearLocalStorageOnInactivity(keysToClear);
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
        <ChatwootWidget />
      <CustomCookieConsent />
      <Router history={history}>
        <ScrollProvider>
          <ScrollToTop />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/forgot-password" element={<RequestLink />} />
            <Route path="/password-reset" element={<ResetPassword />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/catalog" element={<Catalog />} />
            <Route path="/catalog/:page_name" element={<Product />} />
            <Route path="/payments/:order_id" element={<PaymentsPage/>} />
            <Route path="/repair" element={<Repair />} />
            <Route path="/creating-order" element={<CreatingOrder />} />
            <Route path="/order/:title" element={<RepairOrder />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/my-order-list" element={<CustomerOrders />} />
            <Route path="/verification" element={<Verification />} />
            <Route path="/consultation" element={<Consultation />} />
          </Routes>
        </ScrollProvider>
      </Router>
    </IntlProvider>
  );
}

export default App;
