import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Stack, Box } from '@mui/material';
import PageTitle from '../../components/PageTitle/PageTitle';
import ProductListInCart from '../../components/ProductListInCart/ProductListInCart';
import { useSelector, useDispatch } from 'react-redux';
import { sectionsStyle } from '../../styles';
import OrderStages from '../../components/OrderStages/OrderStages';
import CreatingOrderForm from '../../components/Forms/CreatingOrderForm/CreatingOrderForm';
import CreatingOrderFormHD from '../../components/Forms/CreatingOrderForm/CreatingOrderFormHD';
import useWindowWidth from '../../utils/screenSize';
import BreadcrumbsCustom from '../../components/Breadcrumbs/Breadcrumbs';
import { fonts } from '../../fonts';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {
  resetStatus,
  resetError,
} from '../../store/slices/customerOrdersSlice.js';
import MemoryCardListInCart from '../../components/MemoryCardListInCart/MemoryCardListInCart';
import { FormattedMessage, injectIntl } from 'react-intl';
import RegistrationForm from '../../components/Forms/RegistrationForm/RegistrationForm';
import {
  reWriteTotalSumCartMemoryCard,
  reWriteCardInCart,
  reWriteTotalSumCart,
  reWriteTotalDiscount,
  reWriteCart,
} from '../../store/slices/productsSlice.js';
import stylesSweetAlert from '../../custom-sweetalert.module.scss';
import { getOrderList } from '../../store/slices/customerOrdersSlice';
import { primary } from '../../colors';
const customStyleSweetAlert = {
  container: stylesSweetAlert.custom_swal_container,
  title: stylesSweetAlert.custom_swal_title,
  content: stylesSweetAlert.custom_swal_content,
  confirmButton: stylesSweetAlert.custom_swal_confirm_button,
  popup: stylesSweetAlert.swal2_popup,
  icon: stylesSweetAlert.custom_swal_icon,
};

const CreatingOrder = ({ intl }) => {
  const Steps = {
    registration: 'reg',
    firstStep: 'firstStep',
    secondStep: 'secondStep',
    thirdStep: 'thirdStep',
    fourthStep: 'fourthStep',
    depositStep: 'depositStep',
  };
  const { isPayment, isSubscription, isDeposit } = useSelector(
    (state) => state.payment
  );
  const { verification, user, isRegister } = useSelector(
    (state) => state.users
  );
  const { orderError, status, order, orders } = useSelector(
    (state) => state.orders
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(Steps.firstStep);
  const {
    totalSumCart,
    totalDiscount,
    cart,
    memory_card_in_cart,
    totalSumMemoryCard,
  } = useSelector((state) => state.products);

  const [isResident, setIsResident] = useState();
  const [productsInCart, setProductsInCart] = useState(() => {
    const savedCart = localStorage.getItem('productsInCart');

    return savedCart ? JSON.parse(savedCart) : [];
  });

  const [cardInCart, setCardInCart] = useState(() => {
    const savedCart = localStorage.getItem('memoryCardInCart');

    return savedCart ? JSON.parse(savedCart) : [];
  });
  const totalFullPrice = cart.reduce((sum, item) => sum + item.full_price, 0);
  const isLicensePilot = localStorage.getItem('isLicense');
  let totalPriceDeposit = 0;
  if (isLicensePilot) {
    totalPriceDeposit =
      totalFullPrice - totalFullPrice * 0.05 - totalFullPrice * 0.5;
  } else if (!isLicensePilot) {
    totalPriceDeposit = totalFullPrice * 0.5;
  }
  useEffect(() => {
    dispatch(getOrderList());
  }, []);

  useEffect(() => {
    const hasNotPaidOrders = orders.some((order) =>
      order.payment_statuses.some((status) => status.status === 'Not paid'&&status.type!=='Verification fee of 1 EUR')
    );
    const hasNotPaidOrdersSubscription = orders.some((order) =>
      order.items.some((item) => item.subscription_status === 'Not paid')
    );
    if (hasNotPaidOrders || hasNotPaidOrdersSubscription) {
      Swal.fire({
        title: intl.formatMessage({ id: 'warning' }),
        html: `
          <div>
            <p>${intl.formatMessage({ id: 'you_have_unpaid_orders' })}</p>
            <div id="navigate-to-orders" style="cursor: pointer; color:${
              primary.blue
            }; text-decoration:none;">${intl.formatMessage({
          id: 'go_to_orders',
        })}</div>
          </div>
        `,
        icon: 'warning',
        confirmButtonText: 'Ok',
        customClass: customStyleSweetAlert,
        scrollbarPadding: false,
        didOpen: () => {
          const navigateToOrders =
            document.getElementById('navigate-to-orders');
          navigateToOrders.addEventListener('click', handleNavigation);
        },
        willClose: () => {
          const navigateToOrders =
            document.getElementById('navigate-to-orders');
          navigateToOrders.removeEventListener('click', handleNavigation);
        },
      });
      function handleNavigation() {
        navigate('/my-order-list');
        Swal.close();
      }
    }
  }, []);

  useEffect(() => {
    const totalSum = localStorage.getItem('totalSumCart');
    const totalDiscount = localStorage.getItem('totalDiscount');
    const totalSumMemoryCard = localStorage.getItem('totalSumMemoryCard');
    if (totalSum) {
      dispatch(reWriteTotalSumCart(parseInt(totalSum)));
    }
    if (totalDiscount) {
      dispatch(reWriteTotalDiscount(parseInt(totalDiscount)));
    }
    if (totalSumMemoryCard) {
      dispatch(reWriteTotalSumCartMemoryCard(parseInt(totalSumMemoryCard)));
    }

    const savedCart = localStorage.getItem('productsInCart');
    if (savedCart) {
      setProductsInCart(JSON.parse(savedCart));
    }
    dispatch(reWriteCart(productsInCart));

    const savedCardInCart = localStorage.getItem('memoryCardInCart');
    if (savedCardInCart) {
      setCardInCart(JSON.parse(savedCardInCart));
    }
    dispatch(reWriteCardInCart(cardInCart));
  }, [dispatch]);

  useEffect(() => {
    if (cart && cart.length > 0) {
      localStorage.setItem('productsInCart', JSON.stringify(cart));
      setProductsInCart(cart);
    } else if (cart.length === 0) {
      setProductsInCart([]);
      setCardInCart([]);
    }
  }, [cart]);

  useEffect(() => {
    if (memory_card_in_cart && memory_card_in_cart.length > 0) {
      localStorage.setItem(
        'memoryCardInCart',
        JSON.stringify(memory_card_in_cart)
      );
      setCardInCart(memory_card_in_cart);
    } else if (memory_card_in_cart.length === 0) {
      setCardInCart([]);
    }
  }, [memory_card_in_cart]);

  useEffect(() => {
    if (orderError.items) {
      const sentences = cart.map((item) => {
        return `${intl.formatMessage({ id: 'product' })} ${
          item.title
        }: ${intl.formatMessage({ id: 'out_of_stock' })} ${
          item.quantity_in_store
        }, ${intl.formatMessage({ id: 'you_need' })} ${
          item.quantity
        }. ${intl.formatMessage({ id: 'please_change_quantity' })}.`;
      });
      const errorMessage = sentences.join('\n');
      Swal.fire({
        title: intl.formatMessage({ id: 'warning' }),
        text: errorMessage,
        icon: 'warning',
        confirmButtonText: 'Ok',
        customClass: customStyleSweetAlert,
        scrollbarPadding: false,
      });
      dispatch(resetError([]));
    }
  }, [orderError]);

  useEffect(() => {
    if (
      status === 201 &&
      order.length > 0 &&
      verification.isVerification &&
      memory_card_in_cart.length === 0 &&
      isSubscription &&
      isDeposit
    ) {
      navigate('/my-order-list');
      dispatch(resetStatus());
    }
  }, [
    status,
    navigate,
    dispatch,
    order,
    isSubscription,
    verification.isVerification,
    memory_card_in_cart.length,
    isDeposit,
  ]);
  useEffect(() => {
    if (isPayment && verification.isVerification === false && isResident) {
      navigate('/verification');
      dispatch(resetStatus());
    } else if (
      verification.isVerification === true &&
      isPayment &&
      isResident
    ) {
      navigate('/my-order-list');
    }
  }, [isPayment, verification.isVerification, isResident]);

  useEffect(() => {
    if (isDeposit && verification.isVerification === false && !isResident) {
      navigate('/verification');
      dispatch(resetStatus());
    } else if (
      verification.isVerification === true &&
      isDeposit &&
      !isResident
    ) {
      navigate('/my-order-list');
    }
  }, [isDeposit, verification.isVerification, isResident]);

  useEffect(() => {
    if (isSubscription) {
      Swal.fire({
        title: intl.formatMessage({ id: 'success' }),
        text: intl.formatMessage({ id: 'you_created_subscribe' }),
        icon: 'success',
        confirmButtonText: 'Ok',
        customClass: customStyleSweetAlert,
        scrollbarPadding: false,
      });
    }
  }, [isSubscription]);

  useEffect(() => {
    if (isPayment) {
      Swal.fire({
        title: intl.formatMessage({ id: 'success' }),
        text: intl.formatMessage({ id: 'you_paid_for_the_card' }),
        icon: 'success',
        confirmButtonText: 'Ok',
        customClass: customStyleSweetAlert,
        scrollbarPadding: false,
      });
    }
  }, [isPayment]);

  return (
    <div>
      <Header />
      {useWindowWidth() >= 1200 ? (
        <Stack sx={{ alignItems: 'center' }}>
          <Stack
            gap="32px"
            mt="86px"
            sx={{ width: 'calc(100% - 40px)', maxWidth: '1600px' }}
          >
            <BreadcrumbsCustom
              pageName={<FormattedMessage id={'order_processing'} />}
            />
            <Stack direction="row" gap="32px">
              {isRegister ? (
                <CreatingOrderFormHD
                  payment={isPayment}
                  subscription={isSubscription}
                  user={user}
                  step={step}
                  setStep={setStep}
                  Steps={Steps}
                  verification={verification}
                  cart={productsInCart}
                  isResident={isResident}
                  setIsResident={setIsResident}
                  totalPriceDeposit={totalPriceDeposit}
                />
              ) : (
                <Stack sx={{ width: '100%' }}>
                  <PageTitle
                    text={<FormattedMessage id={'registration'} />}
                    optionStyle={{ fontSize: '72px', marginBottom: '20px' }}
                  />
                  <RegistrationForm />
                </Stack>
              )}
              <Stack
                gap="20px"
                sx={{
                  width: '100%',
                  maxWidth: '794px',
                  border: '1px solid #4361EE33',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  padding: '44px',
                }}
              >
                <span style={{ ...fonts.fontInter40 }}>
                  <FormattedMessage id={'my_order'} />
                </span>
                <ProductListInCart productsInCart={productsInCart} />
                <Stack
                  direction="row"
                  sx={{ width: '100%', justifyContent: 'space-between' }}
                >
                  <Box>
                    <FormattedMessage id={'price'} />
                  </Box>
                  <Box>€ {totalSumCart + totalDiscount}</Box>
                </Stack>
                <Stack
                  direction="row"
                  sx={{ width: '100%', justifyContent: 'space-between' }}
                >
                  <Box>
                    <FormattedMessage id={'discount'} />
                  </Box>
                  <Box>€ {totalDiscount}</Box>
                </Stack>
                <Stack
                  direction="row"
                  sx={{ width: '100%', justifyContent: 'space-between' }}
                >
                  <Box>
                    <FormattedMessage id={'total'} />
                  </Box>
                  <Box>
                    € {totalSumCart} <FormattedMessage id={'tax_included'} />
                  </Box>
                </Stack>

                <Stack sx={{ borderBottom: '1px solid gray' }}></Stack>
                {memory_card_in_cart.length > 0 && (
                  <>
                    <MemoryCardListInCart memoryCards={cardInCart} />
                    <Stack
                      direction="row"
                      sx={{ width: '100%', justifyContent: 'space-between' }}
                    >
                      <Box>
                        <FormattedMessage id={'total'} />
                      </Box>
                      <Box>
                        € {totalSumMemoryCard}{' '}
                        <FormattedMessage id={'tax_included'} />
                      </Box>
                    </Stack>
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack gap="32px" mt="86px">
          <Stack sx={{ ...sectionsStyle }}>
            <PageTitle text={<FormattedMessage id={'order_processing'} />} />
            {isRegister && (
              <OrderStages
                step={step}
                setStep={setStep}
                Steps={Steps}
                memory_card_in_cart={memory_card_in_cart}
                isResident={isResident}
              />
            )}
            {isRegister ? (
              <CreatingOrderForm
                payment={isPayment}
                user={user}
                cart={productsInCart}
                step={step}
                setStep={setStep}
                Steps={Steps}
                verification={verification}
                subscription={isSubscription}
                isResident={isResident}
                setIsResident={setIsResident}
                totalPriceDeposit={totalPriceDeposit}
              />
            ) : (
              <Stack sx={{ width: '100%' }}>
                <PageTitle
                  text={<FormattedMessage id={'registration'} />}
                  optionStyle={{ marginBottom: '20px' }}
                />
                <RegistrationForm />
              </Stack>
            )}
          </Stack>
          <Stack sx={{ ...sectionsStyle }}>
            <PageTitle text={<FormattedMessage id={'my_order'} />} />
            <ProductListInCart productsInCart={productsInCart} />
            <Stack
              direction="row"
              sx={{ width: '100%', justifyContent: 'space-between' }}
            >
              <Box>
                <FormattedMessage id={'price'} />
              </Box>
              <Box>€ {totalSumCart + totalDiscount}</Box>
            </Stack>
            <Stack
              direction="row"
              sx={{ width: '100%', justifyContent: 'space-between' }}
            >
              <Box>
                <FormattedMessage id={'discount'} />
              </Box>
              <Box>€ {totalDiscount}</Box>
            </Stack>
            <Stack
              direction="row"
              sx={{ width: '100%', justifyContent: 'space-between' }}
            >
              <Box>
                <FormattedMessage id={'total'} />
              </Box>
              <Box>
                € {totalSumCart} <FormattedMessage id={'tax_included'} />
              </Box>
            </Stack>
            <Stack sx={{ borderBottom: '1px solid gray' }}></Stack>
            {memory_card_in_cart.length > 0 && (
              <>
                <MemoryCardListInCart memoryCards={cardInCart} />
                <Stack
                  direction="row"
                  sx={{ width: '100%', justifyContent: 'space-between' }}
                >
                  <Box>
                    <FormattedMessage id={'total'} />
                  </Box>
                  <Box>
                    € {totalSumMemoryCard}{' '}
                    <FormattedMessage id={'tax_included'} />
                  </Box>
                </Stack>
                <Stack sx={{ borderBottom: '1px solid gray' }}></Stack>
              </>
            )}
            {/* 
            {!isResident && cart.length > 0 && (
              
            )} */}
          </Stack>
        </Stack>
      )}
      <Footer />
    </div>
  );
};

export default injectIntl(CreatingOrder);
