import React from 'react';
import { Stack, Box } from '@mui/material';
import PageTitle from '../PageTitle/PageTitle';
import bg from '../../images/bg_image_repair_section_hd.png';
import LazyLoad from 'react-lazyload';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { fonts } from '../../fonts';
import { FormattedMessage } from 'react-intl';
import useWindowWidth from '../../utils/screenSize';
import img4 from '../../images/how_it_works_section/img4.png';
import img3 from '../../images/how_it_works_section/img3.png';
import img2 from '../../images/how_it_works_section/img2.png';
import img1 from '../../images/how_it_works_section/img1.png';

const RepairDronesSectionHD = () => {
  const screenSize=useWindowWidth();
  const heightSize=()=>{
    if(screenSize>=1366&&screenSize<=1400){
      return '100%';
    }
    if(screenSize>=1200&&screenSize<=1300){
      return '80%';
    }
    if(screenSize>=1920&&screenSize<=2000){
      return '305px';
    }
    if(screenSize>=1600&&screenSize<=1700){
      return '120%';
    }
    return '305px';
    }
    const heightSizeBg=()=>{
      if(screenSize>=1366&&screenSize<=1400){
        return '100%';
      }
      if(screenSize>=1200&&screenSize<=1300){
        return '80%';
      }
     
      if(screenSize>=1600&&screenSize<=1700){
        return '120%';
      }
      if(screenSize>=1920&&screenSize<=2000){
        return '200%';
      }
      return 'auto';
      }
    const bias=()=>{
      if(screenSize>=1366&&screenSize<=1400){
        return '32px';
      }
      if(screenSize>=1200&&screenSize<=1300){
        return '32px';
      }
      if(screenSize>=1920&&screenSize<=2000){
        return '52px';
      }
      if(screenSize>=1600&&screenSize<=1700){
        return '42px';
      }
      return '52px'
    }
    const boxTitleStyle = {
      display: 'flex',
      flexDirection: 'column',
      ...fonts.fontInter18_400,
      lineHeight: '27px',
      textAlign: 'center',
    };
    const halfCircleStyle = {
      position: 'absolute',
      width: '100%',
      height:'50%'
      
    };
    const littleCircleStyle = {
      position: 'absolute',
      width: '59px',
      height: '59px',
      borderRadius: '50%',
      right: '-29.5px',
      zIndex: '10',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
    const imageStyle = {
      position: 'absolute',
      width: '74.57%',
      //305
      height:heightSize(),
      borderRadius: '50%',
      left: bias(),
      
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    };
    const imageStyle1 = {
      backgroundImage: `url(${img4})`
    }
    const imageStyle2 = {
      backgroundImage: `url(${img3})`
    }
    const imageStyle3 = {
      backgroundImage: `url(${img2})`
    }
    const imageStyle4 = {
      backgroundImage: `url(${img1})`
    }
  return (
    <Stack
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: '40px 150px',
        height: heightSizeBg(),
        backgroundColor: 'transparent',
        alignItems:'center'
      }}
    >
      <Stack gap="40px" sx={{width:'100%',maxWidth:'1600px'}}>
        <PageTitle
          text={<FormattedMessage id="how_it_work" />}
          optionStyle={{ fontSize: '70px' }}
        />
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <Stack sx={{ width: '25%', height: '100%', gap: '25px' }}>
            <Box sx={{ ...boxTitleStyle }}>
              <Box component="span" sx={{ ...fonts.fontInter24 }}>
              <FormattedMessage id={"choose_your_drone"}/>
              </Box>
              <FormattedMessage id={"how_it_works_1"}/>
            </Box>
            <Box sx={{ height: '409px', position: 'relative' }}>
              <Box
                sx={{
                  ...halfCircleStyle,
                  borderBottomLeftRadius: '204.5px',
                  borderBottomRightRadius: '204.5px',
                  border: '2px solid #7484D2',
                  borderTop: '0px',
                  bottom: '0px',
                }}
              >
                <Box
                  sx={{
                    ...littleCircleStyle,
                    top: '-29.5px',
                    backgroundColor: '#7484D2',
                  }}
                >
                  <ChevronRightIcon
                    sx={{ color: '#fff', transform: 'rotate(-87deg)' }}
                  />
                </Box>
                <LazyLoad offset={100}>
                  <Box
                    sx={{
                      bottom: '52px',
                      ...imageStyle,...imageStyle1
                    }}
                  ></Box>
                </LazyLoad>
              </Box>
            </Box>
            <Box
              sx={{
                ...fonts.fontPoppins80,
                textAlign: 'center',
                height: '108px',
              }}
            >
              1.
            </Box>
          </Stack>
          <Stack sx={{ width: '25%', height: '100%', gap: '25px' }}>
            <Box
              sx={{
                ...fonts.fontPoppins80,
                textAlign: 'center',
                height: '108px',
              }}
            >
              2.
            </Box>

            <Box sx={{ height: '409px', position: 'relative' }}>
              <Box
                sx={{
                  ...halfCircleStyle,
                  borderTopLeftRadius: '204.5px',
                  borderTopRightRadius: '204.5px',
                  border: '2px solid #BB90F4',
                  borderBottom: '0px',
                  top: '0px',
                }}
              >
                <Box
                  sx={{
                    ...littleCircleStyle,
                    bottom: '-29.5px',
                    backgroundColor: '#BB90F4',
                  }}
                >
                  <ChevronRightIcon
                    sx={{ color: '#fff', transform: 'rotate(87deg)' }}
                  />
                </Box>
                <LazyLoad offset={100}>
                  <Box
                    sx={{
                      top: '52px',
                      ...imageStyle,...imageStyle2
                    }}
                  ></Box>
                </LazyLoad>
              </Box>
            </Box>
            <Box sx={{ ...boxTitleStyle }}>
              <Box component="span" sx={{ ...fonts.fontInter24 }}>
              <FormattedMessage id={"verification_and_payment"}/>
              </Box>
              <FormattedMessage id={"how_it_works_2"}/>
            </Box>
          </Stack>
          <Stack sx={{ width: '25%', height: '100%', gap: '25px' }}>
            <Box sx={{ ...boxTitleStyle }}>
              <Box component="span" sx={{ ...fonts.fontInter24 }}>
              <FormattedMessage id={"use_your_drone"}/>
              </Box>
              <FormattedMessage id={"how_it_works_3"}/>
            </Box>
            <Box sx={{ height: '409px', position: 'relative' }}>
              <Box
                sx={{
                  ...halfCircleStyle,
                  borderBottomLeftRadius: '204.5px',
                  borderBottomRightRadius: '204.5px',
                  border: '2px solid #E89EC0',
                  borderTop: '0px',
                  bottom: '0px',
                }}
              >
                <Box
                  sx={{
                    ...littleCircleStyle,
                    top: '-29.5px',
                    backgroundColor: '#E89EC0',
                  }}
                >
                  <ChevronRightIcon
                    sx={{ color: '#fff', transform: 'rotate(-87deg)' }}
                  />
                </Box>
                <LazyLoad offset={100}>
                  <Box
                    sx={{
                      bottom: '52px',
                      ...imageStyle,...imageStyle3
                    }}
                  ></Box>
                </LazyLoad>
              </Box>
            </Box>
            <Box
              sx={{
                ...fonts.fontPoppins80,
                textAlign: 'center',
                height: '108px',
              }}
            >
              3.
            </Box>
          </Stack>
          <Stack sx={{ width: '25%', height: '100%', gap: '25px' }}>
          <Box
              sx={{
                ...fonts.fontPoppins80,
                textAlign: 'center',
                height: '108px',
              }}
            >
              4.
            </Box>
            
            <Box sx={{ height: '409px', position: 'relative' }}>
              <Box
                sx={{
                  ...halfCircleStyle,
                  borderTopLeftRadius: '204.5px',
                  borderTopRightRadius: '204.5px',
                  border: '2px solid #DCC05D',
                  borderBottom: '0px',
                  top: '0px',
                }}
              >
                <Box
                  sx={{
                    ...littleCircleStyle,
                    bottom: '-29.5px',
                    backgroundColor: '#DCC05D',
                  }}
                >
                  <ChevronRightIcon
                    sx={{ color: '#fff', transform: 'rotate(87deg)' }}
                  />
                </Box>
                <LazyLoad offset={100}>
                  <Box
                    sx={{
                      top: '52px',
                      ...imageStyle,...imageStyle4
                    }}
                  ></Box>
                </LazyLoad>
              </Box>
            </Box>
            <Box sx={{ ...boxTitleStyle }}>
              <Box component="span" sx={{ ...fonts.fontInter24 }}>
              <FormattedMessage id={"return_the_drone"}/>
              </Box>
              <FormattedMessage id={"how_it_works_4"}/>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RepairDronesSectionHD;
