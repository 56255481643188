import React, { useEffect } from 'react';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PinkButton from '../../components/Buttons/PinkButton';
import { FormattedMessage, injectIntl } from 'react-intl';
import useWindowWidth from '../../utils/screenSize';
import { forgotPasswordSchema } from '../../utils/validationSchems.js';
import { userForgotPassword } from '../../store/slices/usersSlice.js';
import styles from '../../components/Forms/LoginForm/LoginForm.module.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import stylesSweetAlert from '../../custom-sweetalert.module.scss';
const customStyleSweetAlert = {
  container: stylesSweetAlert.custom_swal_container,
  title: stylesSweetAlert.custom_swal_title,
  content: stylesSweetAlert.custom_swal_content,
  confirmButton: stylesSweetAlert.custom_swal_confirm_button,
  popup: stylesSweetAlert.swal2_popup,
  icon: stylesSweetAlert.custom_swal_icon,
};
const RequestLink = ({ intl }) => {
  const navigate = useNavigate();
  const { request_update_link,error } = useSelector((state) => state.users);
  useEffect(() => {
    if (request_update_link) {
      navigate('/password-reset');
    }
  }, [request_update_link,navigate]);

  useEffect(() => {
    if (error===true) {
      Swal.fire({
        title: intl.formatMessage({ id: 'warning' }),
        text:intl.formatMessage({ id: 'enter_your_email' }) ,
        icon: 'warning',
        confirmButtonText: 'Ok',
        customClass: customStyleSweetAlert,
      });
    }
  }, [error]);
  const widthButton = useWindowWidth() >= 1200 ? '472px' : '168px';
  const heightButton = useWindowWidth() >= 1200 ? '72px' : '46px';
  const dispatch = useDispatch();
  const initialValues = {
    email: '',
  };
  const onSubmit = (values, { resetForm, setSubmitting }) => {
    dispatch(userForgotPassword(values));
    resetForm();
    setSubmitting(false);
  };
  return (
    <div
      style={{
        marginTop: '80px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Header />
      <div
        className={styles.form}
        style={{
          display: 'flex',
          minHeight: '60vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={forgotPasswordSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form style={{ width: '100%' }}>
              <div>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder={intl.formatMessage({ id: 'email' })}
                  style={{borderColor:'rgba(67, 97, 238, 0.2)'}}
                />
                <ErrorMessage name="email">
                  {(msg) => (
                    <div className={styles.error}>
                      <FormattedMessage id={msg} />
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <PinkButton
                type="submit"
                text={intl.formatMessage({ id: 'send' })}
                width={widthButton}
                height={heightButton}
                optionStyle={{ margin: '10px' }}
              />
            </Form>
          )}
        </Formik>
      </div>
      <Footer />
    </div>
  );
};

export default injectIntl(RequestLink);
