import React from 'react';
import { Box } from '@mui/material';
import {fonts} from '../../fonts.js';
const PageTitle = (props) => {
 
  return (
    <Box component="h1" sx={{ ...fonts.fontPoppins32, ...props.optionStyle }}>
      {props.text}
    </Box>
  );
};

export default PageTitle;
