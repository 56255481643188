import React from 'react';

const TextTruncate = ( text, maxLength ) => {
  if (text.length <= maxLength) {
    return <span>{text}</span>;
  }

  const truncatedText = text.substring(0, maxLength).trim() + '...';

  return <span>{truncatedText}</span>;
};

export default TextTruncate;