import React, { useEffect } from 'react';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PinkButton from '../../components/Buttons/PinkButton';
import { FormattedMessage, injectIntl } from 'react-intl';
import useWindowWidth from '../../utils/screenSize';
import { changePasswordSchema } from '../../utils/validationSchems.js';
import { userChangePassword,resetChangePassState } from '../../store/slices/usersSlice.js';
import styles from '../../components/Forms/LoginForm/LoginForm.module.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import stylesSweetAlert from '../../custom-sweetalert.module.scss';
const customStyleSweetAlert = {
  container: stylesSweetAlert.custom_swal_container,
  title: stylesSweetAlert.custom_swal_title,
  content: stylesSweetAlert.custom_swal_content,
  confirmButton: stylesSweetAlert.custom_swal_confirm_button,
  popup: stylesSweetAlert.swal2_popup,
  icon: stylesSweetAlert.custom_swal_icon,
};

const ChangePassword = ({ intl }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const widthButton = useWindowWidth() >= 1200 ? '472px' : '168px';
  const heightButton = useWindowWidth() >= 1200 ? '72px' : '46px';
  const { change_password,error } = useSelector((state) => state.users);
useEffect(()=>{
  if(change_password){
    Swal.fire({
      title: intl.formatMessage({ id: 'Success' }),
      text:intl.formatMessage({ id: 'password_change_was_successful' }) ,
      icon: 'success',
      confirmButtonText: 'Ok',
      customClass: customStyleSweetAlert,
    });
    dispatch(resetChangePassState());
    navigate('/profile')
  }
},[change_password])

useEffect(() => {
  if (error===true) {
    Swal.fire({
      title: intl.formatMessage({ id: 'warning' }),
      text:intl.formatMessage({ id: 'enter_old_password_correctly_try_again' }) ,
      icon: 'warning',
      confirmButtonText: 'Ok',
      customClass: customStyleSweetAlert,
    });
  }
}, [error]);
  const initialValues={
    old_password:'',
    new_password:'',
    confirm_new_password:''
  }
  const onSubmit = (values, { resetForm, setSubmitting }) => {
    console.log('onSubmit');
    dispatch(userChangePassword(values));
    resetForm();
    setSubmitting(false);
  };
  return (
    <div
      style={{
        marginTop: '80px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
   <Header/>
   <div
        className={styles.form}
        style={{
          display: 'flex',
          minHeight: '60vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={changePasswordSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form style={{ width: '100%' }}>
              <div>
                <Field
                  type="password"
                  id="old_password"
                  name="old_password"
                  placeholder={intl.formatMessage({ id: 'old_password' })}
                  style={{borderColor:'rgba(67, 97, 238, 0.2)'}}
                />
                <ErrorMessage name="old_password">
                  {(msg) => (
                    <div className={styles.error}>
                      <FormattedMessage id={msg} />
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <div>
                <Field
                  type="password"
                  id="new_password"
                  name="new_password"
                  placeholder={intl.formatMessage({ id: 'new_password' })}
                  style={{borderColor:'rgba(67, 97, 238, 0.2)'}}
                />
                <ErrorMessage name="new_password">
                  {(msg) => (
                    <div className={styles.error}>
                      <FormattedMessage id={msg} />
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <div>
                <Field
                  type="password"
                  id="confirm_new_password"
                  name="confirm_new_password"
                  placeholder={intl.formatMessage({ id: 'confirm_new_password' })}
                  style={{borderColor:'rgba(67, 97, 238, 0.2)'}}
                />
                <ErrorMessage name="confirm_new_password">
                  {(msg) => (
                    <div className={styles.error}>
                      <FormattedMessage id={msg} />
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <PinkButton
                type="submit"
                text={intl.formatMessage({ id: 'change_password_btn' })}
                width={widthButton}
                height={heightButton}
                optionStyle={{ margin: '10px' }}
              />
            </Form>
          )}
        </Formik>
      </div>
   <Footer/>
   </div>
  );
}

export default injectIntl(ChangePassword);
