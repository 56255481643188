import React, { useEffect} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PinkButton from '../../Buttons/PinkButton';
import { useNavigate } from 'react-router-dom';
import { Stack, Box } from '@mui/material';
import { fonts } from '../../../fonts';
import { orderProcessingValidationSchemas } from '../../../utils/validationSchems.js';
import StripeSubscription from '../StripePaymentForm/StripeSubscription';
import { orderCreate } from '../../../store/slices/customerOrdersSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import StripePaymentMemoryCard from '../StripePaymentForm/StripePaymentMemoryCard';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CONSTANTS from '../../../constants';
import YesOrNoSwitcher from '../ProductForm/YesOrNoSwitcher';
import { primary } from '../../../colors';
import img_deposit from '../../../images/deposit.jpg';

const inputStyle = {
  paddingLeft: '20px',
  width: 'calc(100% - 20px)',
  height: '46px',
  borderRadius: '100px',
  borderColor:'rgba(67, 97, 238, 0.2)'
};
const errorStyle = {
  color: 'red',
  marginTop: '5px',
};
const switchStyle = {
  width: '25%',
  transition: '0.8s',
  cursor: 'pointer',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const switchStyle2 = {
  width: '200px',
  height: '50px',
  ...fonts.fontInter16_600,
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  borderRadius: '100px',
  border: `1px solid rgba(67, 97, 238, 0.5)`,
};
const CreatingOrderForm = ({
  payment,
  cart,
  user,
  step,
  setStep,
  Steps,
  verification,
  subscription,
  intl,
  isResident,
  setIsResident,
  totalPriceDeposit
}) => {
  const stripePromise = loadStripe(CONSTANTS.PAYMENT_KEY);
  const { memory_card_in_cart, isLicensePilot } = useSelector(
    (state) => state.products
  );
  useEffect(() => {
    if (subscription && verification) {
      setStep(Steps.fourthStep);
    }
  }, [subscription]);
  useEffect(() => {
    if (
      payment &&
      verification &&
      !isResident &&
      memory_card_in_cart.length > 0
    ) {
      setStep(Steps.depositStep);
    } else if (
      subscription &&
      verification &&
      !isResident &&
      memory_card_in_cart.length === 0
    ) {
      setStep(Steps.depositStep);
    }
  }, [payment, subscription, isResident, memory_card_in_cart]);


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const totalPrice = memory_card_in_cart.reduce(
    (sum, item) => sum + item.price,
    0
  );
  

  const handleSubmit = (
    values,
    { setFieldValue, resetForm, setSubmitting }
  ) => {
    const items = cart.map((item) => {
      let rentalDuration;
      switch (item.rental_duration) {
        case 'oneMonths':
          rentalDuration = '1M';
          break;
        case 'threeMonths':
          rentalDuration = '3M';
          break;
        case 'sixMonths':
          rentalDuration = '6M';
          break;
        case 'twelveMonths':
          rentalDuration = '12M';
          break;
        default:
          rentalDuration = '1M';
      }
      return {
        product: item.id,
        quantity: item.quantity,
        rental_duration: rentalDuration,
      };
    });

    values.items = items;
    console.log('values', values);
    let formData = {};
    const memory_cards = memory_card_in_cart.map((card) => ({
      memory_card: card.id,
      quantity: card.quantity,
    }));
    formData = {
      name: values.name,
      surname: values.surname,
      phone: values.phone,
      city: values.city,
      delivery_address: values.delivery_address,
      license: isLicensePilot,
      isResident: isResident,
      items: values.items,
      memory_cards: memory_cards,
    };
    dispatch(orderCreate(formData));
    setSubmitting(false);
    resetForm();
  };
  const changeStep = async (step, validateForm, setFieldTouched) => {
    const errors = await validateForm();
    Object.keys(errors).forEach((field) => setFieldTouched(field, true, false));
    console.log('errors', errors);
    if (Object.keys(errors).length === 0) {
      setStep(step);
    }
  };
  if (!user.name) {
    return <div>Loading...</div>;
  }
  return (
    <>
      {' '}
      <Formik
        initialValues={{ ...user, email: user.user_email, password: '' }}
        validationSchema={orderProcessingValidationSchemas[step]}
        onSubmit={handleSubmit}
      >
        {({
          values,
          setFieldValue,
          validateForm,
          isSubmitting,
          setFieldTouched,
        }) => (
          <Form>
            {/* Step 1 */}
            {step === Steps.firstStep && (
              <Stack gap="32px">
                <Stack gap="15px">
                  <span style={{ ...fonts.fontInter18_600 }}>
                    <FormattedMessage id={'your_contact_details'} />
                  </span>
                  <div>
                    <Field
                      name="name"
                      id="name"
                      type="text"
                      placeholder={intl.formatMessage({ id: 'name' })}
                      style={{ ...inputStyle }}
                    />
                    <ErrorMessage name="name">
                      {(msg) => (
                        <div style={errorStyle}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div>
                    <Field
                      name="surname"
                      id="surname"
                      type="text"
                      placeholder={intl.formatMessage({ id: 'surname' })}
                      style={{ ...inputStyle }}
                    />
                    <ErrorMessage name="surname">
                      {(msg) => (
                        <div style={errorStyle}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div>
                    <Field
                      name="phone"
                      id="phone"
                      type="tel"
                      placeholder={intl.formatMessage({ id: 'phone' })}
                      style={{ ...inputStyle }}
                    />
                    <ErrorMessage name="phone">
                      {(msg) => (
                        <div style={errorStyle}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </Stack>
                <PinkButton
                  text={<FormattedMessage id={'next'} />}
                  width={'168px'}
                  action={() =>
                    changeStep(Steps.secondStep, validateForm, setFieldTouched)
                  }
                  optionStyle={{ margin: 'auto' }}
                />
              </Stack>
            )}

            {/* Step 2 */}
            {step === Steps.secondStep && (
              <Stack gap="32px">
                <Stack gap="15px">
                  <span style={{ ...fonts.fontInter18_600 }}>
                    <FormattedMessage id={'delivery'} />
                  </span>
                  <div>
                    <Field
                      name="city"
                      type="text"
                      placeholder={intl.formatMessage({ id: 'city' })}
                      style={{ ...inputStyle }}
                    />
                    <ErrorMessage name="city">
                      {(msg) => (
                        <div style={errorStyle}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div>
                    <Field
                      name="delivery_address"
                      type="text"
                      placeholder={intl.formatMessage({ id: 'address' })}
                      style={{ ...inputStyle }}
                    />
                    <ErrorMessage name="delivery_address">
                      {(msg) => (
                        <div style={errorStyle}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <YesOrNoSwitcher
                    switchStyle={switchStyle}
                    trigger={isResident}
                    setTrigger={setIsResident}
                    switchStyle2={switchStyle2}
                    text={<FormattedMessage id={'are_you_dutch_citizen'} />}
                  />
                </Stack>
                <PinkButton
                  text={<FormattedMessage id={'order'} />}
                  type="submit"
                  action={() => {
                    changeStep(Steps.thirdStep, validateForm, setFieldTouched);
                    document
                      .querySelector('form')
                      .dispatchEvent(
                        new Event('submit', { cancelable: true, bubbles: true })
                      );
                  }}
                  width={'168px'}
                  optionStyle={{ margin: 'auto' }}
                />
              </Stack>
            )}

            {/* Step 3 */}
            {step === Steps.thirdStep && (
              <Stack>
                {verification.isVerification === false && subscription && (
                  <Stack gap="30px ">
                    <span style={{ ...fonts.fontInter18_600 }}>
                      <FormattedMessage id={'verification'} />
                    </span>
                  </Stack>
                )}
                {verification.isVerification === false && !subscription && (
                  <Stack gap="30px ">
                    <span style={{ ...fonts.fontInter18_600 }}>
                      <FormattedMessage id={'subscription'} />
                    </span>
                  </Stack>
                )}

                {step === Steps.thirdStep &&
                  verification.isVerification === false &&
                  subscription && (
                    <PinkButton
                      text={<FormattedMessage id={'verification'} />}
                      action={() => {
                        navigate('/verification');
                      }}
                      width={'168px'}
                      optionStyle={{ margin: '20px auto 0px' }}
                    />
                  )}
              </Stack>
            )}
          </Form>
        )}
      </Formik>
      {step === Steps.thirdStep && !subscription && (
        <Elements stripe={stripePromise}>
          <StripeSubscription />
        </Elements>
      )}
      {/* Step 4 */}
      {step === Steps.fourthStep && memory_card_in_cart.length > 0 && (
        <Elements stripe={stripePromise}>
          {' '}
          <p style={{ ...fonts.fontInter20, color: primary.pink }}><FormattedMessage id={'buying_card'}/></p>
          
          <StripePaymentMemoryCard
            // totalPrice={totalPrice}
            payment_type={'MC'}
          />
        </Elements>
      )}
      {/* Step 5 */}
      {!isResident&&step===Steps.depositStep&& (<>
        <div>
        <Stack
          direction="row"
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {' '}
          <img
            src={img_deposit}
            alt=""
            style={{
              width: '100%',
              maxWidth: '60px',
              marginBottom: '20px',
            }}
          />{' '}
          <Box
            sx={{
              ...fonts.fontInter16_600,
              display: 'inline',
              textAlign: 'end',
              lineHeight: '40px',
            }}
          >
            <FormattedMessage id={'drone_deposit'} />:<br /> €
            {totalPriceDeposit}
            <FormattedMessage id={'tax_included'} />
          </Box>
        </Stack>
        <Stack sx={{ borderBottom: '1px solid gray' }}></Stack>
      </div>
        <Elements stripe={stripePromise}>
          <p style={{ ...fonts.fontInter20, color: primary.pink }}>
            <FormattedMessage id={'drone_deposit'}/>
          </p>
          <StripePaymentMemoryCard
            // totalPrice={totalPriceDeposit}
            payment_type={'DEP'}
          />
        </Elements>
      </>)}
    </>
  );
};

export default injectIntl(CreatingOrderForm);
