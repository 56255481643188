import React from 'react';
import { useSwiper } from 'swiper/react';
import styles from './SliderBtns2.module.scss';
import { Stack } from '@mui/material';
import { ArrowRight } from '../Icons/Icons';

export const SwiperNavButtons2 = () => {
  const swiper = useSwiper();

  return (
    <Stack direction='row' component="button"  className={styles.swiper_nav_btns_button2} onClick={() => swiper.slideNext()}>
      View more
       <ArrowRight/>
    </Stack>
  );
};
