import React from 'react';
import { Stack, Button, Box } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { fonts } from '../../fonts';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { primary } from '../../colors';
import PageTitle from '../PageTitle/PageTitle';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
const ProfileMob = ({ user, setIsChange }) => {
  return (
    <>
      <PageTitle text={<FormattedMessage id="personal_office" />} />
      <Stack
        direction="row"
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <PersonOutlineOutlinedIcon sx={{ fontSize: '34px' }} />
        <span style={{ ...fonts.fontInter18_600 }}>
          <FormattedMessage id="personal_data" />
        </span>
        <Button
          style={{ ...fonts.fontInter18_600, color: primary.blue }}
          onClick={() => {
            setIsChange(true);
          }}
        >
          <FormattedMessage id="change" />
        </Button>
      </Stack>
      <Stack direction="row" gap="48px" sx={{ flexWrap: 'wrap' }}>
        <Stack>
          <Box sx={{ ...fonts.fontInter14_300 }}>
            <FormattedMessage id="name" />
          </Box>
          <Box sx={{ ...fonts.fontInter18_600 }}>{user?.name}</Box>
        </Stack>
        <Stack>
          <Box sx={{ ...fonts.fontInter14_300 }}>
            <FormattedMessage id="surname" />
          </Box>
          <Box sx={{ ...fonts.fontInter18_600 }}>{user?.surname}</Box>
        </Stack>
        <Stack>
          <Box sx={{ ...fonts.fontInter14_300 }}>
            <FormattedMessage id="birthday" />
          </Box>
          <Box sx={{ ...fonts.fontInter18_600 }}>{user?.date_of_birth}</Box>
        </Stack>
        <Stack>
          <Box sx={{ ...fonts.fontInter14_300 }}>
            <FormattedMessage id="sex" />
          </Box>
          <Box sx={{ ...fonts.fontInter18_600 }}>{user?.sex}</Box>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Stack  direction="row"
        gap="20px"  sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
          <MailOutlineIcon sx={{ fontSize: '34px' }} />
          <Box style={{ ...fonts.fontInter18_600 }}>
            <FormattedMessage id="contacts" />
          </Box>
        </Stack>
        <Link
          to="/change-password"
          style={{
            ...fonts.fontInter18_600,
            color: primary.blue,
            textDecoration: 'none',
          }}
        >
          <FormattedMessage id="change_password" />
        </Link>
      </Stack>
      <Stack direction="row" gap="20px" sx={{ flexWrap: 'wrap' }}>
        <Stack>
          <Box sx={{ ...fonts.fontInter14_300 }}>
            <FormattedMessage id="phone" />
          </Box>
          <Box sx={{ ...fonts.fontInter18_600 }}>{user?.phone}</Box>
        </Stack>
        <Stack>
          <Box sx={{ ...fonts.fontInter14_300 }}>email</Box>
          <Box sx={{ ...fonts.fontInter18_600 }}>{user?.user_email}</Box>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        gap="20px"
        sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
      >
        <LocalShippingOutlinedIcon sx={{ fontSize: '34px' }} />
        <Box style={{ ...fonts.fontInter18_600 }}>
          <FormattedMessage id="delivery" />
        </Box>
      </Stack>
      <Box sx={{ ...fonts.fontInter18_600 }}>{user?.delivery_address}</Box>

      <Stack
        direction="row"
        gap="20px"
        sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
      >
        <ApartmentIcon />
        <Box style={{ ...fonts.fontInter18_600 }}>
          <FormattedMessage id="city" />
        </Box>
      </Stack>
      <Box sx={{ ...fonts.fontInter18_600 }}>{user?.city}</Box>
    </>
  );
};

export default ProfileMob;
