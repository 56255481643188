import React from 'react';
import { Stack, Box } from '@mui/material';
import { sectionsStyle } from '../../styles';
import PageTitle from '../PageTitle/PageTitle';
import PinkButton from '../Buttons/PinkButton';
import { fonts } from '../../fonts.js';
import { primary } from '../../colors.js';
import { Link } from 'react-router-dom';

import useWindowWidth from '../../utils/screenSize';
import LinkToBlogArticle from '../LinkToBlogArticle/LinkToBlogArticle';
import { FormattedMessage } from 'react-intl';
import CONSTANTS from '../../constants';
const paragraphStyle = {
  backgroundColor: primary.blue,
  width: '100%',
  height: '93px',
  borderRadius: '10px',
  color: '#fff',
  ...fonts.fontInter18_500,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  lineHeight: '1.5',
  textAlign: 'center',
};

const paragraphStyleHD = {
  backgroundColor: primary.blue,
  width: '243px',
  height: '188px',
  borderRadius: '10px',
  color: '#fff',
  ...fonts.fontInter24_500,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  lineHeight: '1.5',
  textAlign: 'center',
};
const linkStyleHD = {
  width: '100%',
  maxWidth: '654px',
  height: '413px',
  textDecoration: 'none',
  ...fonts.fontInter24_500,
  border: '1px solid rgba(67, 97, 238, 0.3)',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  paddingBottom: '15px',
  gap: '6px',
  color: 'inherit',
};
const OurBlog = () => {
  return (
    <>
      {useWindowWidth() >= 1200 ? (
        <Stack sx={{ alignItems: 'center' }}>
          <Stack
            gap="90px"
            sx={{ width: 'calc(100% - 20px)', maxWidth: '1600px' }}
          >
            <PageTitle
              text={<FormattedMessage id={'our_blog'} />}
              optionStyle={{ fontSize: '70px' }}
            />
            <Stack direction="row" gap="12px" sx={{ flexWrap: 'wrap' }}>
              <Stack direction="row" gap="12px">
                <LinkToBlogArticle
                  styleLink={linkStyleHD}
                  post={CONSTANTS.WP_POST_2}
                />
                <LinkToBlogArticle
                  styleLink={linkStyleHD}
                  post={CONSTANTS.WP_POST_1}
                />
              </Stack>
              <Stack sx={{ justifyContent: 'space-between' }}>
                <Box component="p" sx={{ ...paragraphStyleHD }}>
                  Unlocking Aerial Possibilities:
                  <br />
                  The Rise of Drone Rentals
                </Box>
                <Box component="p" sx={{ ...paragraphStyleHD }}>
                  Unlocking Aerial Possibilities:
                  <br />
                  The Rise of Drone Rentals
                </Box>
              </Stack>
            </Stack>{' '}
            <Link to={CONSTANTS.BLOGS_URL} style={{ margin: 'auto',display:'flex',justifyContent:'center',textDecoration:'none' }}>
              <PinkButton
                width={'311px'}
                height={'72px'}
                text={<FormattedMessage id={'view_all'} />}
                optionStyle={{ ...fonts.fontInter16_600, margin: 'auto' }}
              />
            </Link>
          </Stack>
        </Stack>
      ) : (
        <Stack sx={{ ...sectionsStyle }}>
          <PageTitle text={<FormattedMessage id={'our_blog'} />} />
          <Stack gap="6px">
            <LinkToBlogArticle
              styleLink={linkStyleHD}
              post={CONSTANTS.WP_POST_2}
            />
            <LinkToBlogArticle
              styleLink={linkStyleHD}
              post={CONSTANTS.WP_POST_1}
            />
          </Stack>
          <Box component="p" sx={{ ...paragraphStyle }}>
            Unlocking Aerial Possibilities:
            <br />
            The Rise of Drone Rentals
          </Box>
          <Link to={CONSTANTS.BLOGS_URL} style={{ margin: 'auto',display:'flex',justifyContent:'center' }}>
            <PinkButton
              width={'190px'}
              height={'46px'}
              text={<FormattedMessage id={'view_all'} />}
              optionStyle={{ ...fonts.fontInter16_600 ,margin:'auto'}}
            />
          </Link>
        </Stack>
      )}
    </>
  );
};

export default OurBlog;
