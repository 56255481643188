import React from 'react';
import { Box, Stack } from '@mui/material';
import { fonts } from '../../../fonts';
import { primary } from '../../../colors';
import { FormattedMessage } from 'react-intl';

const YesOrNoSwitcher = ({ text,switchStyle2,switchStyle, trigger, setTrigger }) => {
  return (
    <>
      <Box sx={{ ...fonts.fontInter16_400 }}>
        {text}
      </Box>
      <Stack
        direction="row"
        sx={{
          ...switchStyle2,
        }}
      >
        <Box
          onClick={() => setTrigger(true)}
          sx={{
            ...switchStyle,
            backgroundColor: trigger ? primary.blue : 'white',
            color: trigger ? 'white' : 'black',
            borderRadius: '100px 0 0 100px',
            width: '50%',
            transform: trigger ? 'scale(1.2)' : 'scale(1)',
            zIndex: trigger && '10',
          }}
        >
          <FormattedMessage id={"yes"}/>
        </Box>

        <Box
          onClick={() => setTrigger(false)}
          sx={{
            ...switchStyle,

            backgroundColor: !trigger ? primary.blue : 'white',
            color: !trigger ? 'white' : 'black',
            transform: !trigger ? 'scale(1.2)' : 'scale(1)',
            borderRadius: '0 100px 100px 0',
            width: '50%',
            zIndex: !trigger && '10',
          }}
        >
          <FormattedMessage id={"no"}/>
        </Box>
      </Stack>
    </>
  );
};

export default YesOrNoSwitcher;
