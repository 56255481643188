import React from 'react';
import { Stack, Box } from '@mui/material';
import PinkButton from '../Buttons/PinkButton';
import { fonts } from '../../fonts.js';
import { useNavigate } from 'react-router-dom';
import useWindowWidth from '../../utils/screenSize';
import { FormattedMessage } from 'react-intl';
import  LazyLoad  from 'react-lazyload';

const ProductCard = ({ product }) => {
  const isOutOfStock = product.quantity_in_store === 0;
  const blurStyle = isOutOfStock ? 'blur(0px)' : 'none';
  const grayScale = isOutOfStock ? 'grayscale(100%)' : 'none';
  const widthBth = useWindowWidth() >= 1200 ? '311px' : '163px';
  const heightBth = useWindowWidth() >= 1200 ? '72px' : '46px';

  const navigate = useNavigate();
  const fontsStyle =
    useWindowWidth() >= 1200 ? fonts.fontInter24 : fonts.fontInter18_600;
  const bottomCoordinate = useWindowWidth() >= 1200 ? '190px' : '90px';
  const redirectLink = () => {
    navigate(`/catalog/${product.page_name}`);
  };

  return (
    <>
      <Stack
        sx={{
          width: useWindowWidth() >= 1200 ? '311px' : '165px',
          height: useWindowWidth() >= 1200 ? '649px' : '365px',
          overflow: 'auto',
          border: 'solid 1px rgba(67, 97, 238, 0.3)',
          borderRadius: '5px',
          padding: useWindowWidth() >= 1200 ? '0px 35px 0 35px' : '0px',
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={redirectLink}
      >
        <LazyLoad offset={100}>
          <img
            src={product.files_specifications[0]}
            alt="oops"
            width={useWindowWidth() >= 1200 ? '311px' : '150px'}
            height={useWindowWidth() >= 1200 ? '348px' : '165px'}
            style={{
              filter: `${blurStyle} ${grayScale}`,
              opacity: isOutOfStock ? '0.3' : '1',
            }}
          />
        </LazyLoad>
        <Box component="h3" sx={{ fontFamily: fontsStyle }}>
          {product.title}
        </Box>
        {isOutOfStock && (
          <Box
            sx={{
              position: 'absolute',
              top: '-50%',
              left: 0,
              right: 0,
              bottom: 0,
              margin: 'auto',
              width: '80%',
              height: '20px',
              backgroundColor: '#f7f7f7',
              fontFamily: fontsStyle,
              textAlign: 'center',
              padding: '12px 0px',
              zIndex: 50,
            }}
          ><FormattedMessage id={'all_rented_out'} />
          </Box>
        )}

        {isOutOfStock && (
          <Box
            sx={{
              position: 'absolute',
              top: '20px',
              left: '20px',
              backgroundColor: 'black',
              color: '#fff',
              borderRadius: '20px',
              padding: '5px',
              zIndex: 50,
            }}
          >
            <FormattedMessage id={'out_of_stock'} />
          </Box>
        )}
        <Box
          sx={{
            fontFamily: fontsStyle,
            position: 'absolute',
            bottom: bottomCoordinate,
          }}
        >
          € {product.price_month}/<FormattedMessage id={'month'} />{' '}
          <FormattedMessage id={'tax_included'} />
        </Box>
        <Box
          sx={{
            ...fonts.fontInter18_400,
            position: 'absolute',
            bottom: '120px',
            lineHeight: '22px',
          }}
        >
          {useWindowWidth() >= 1200 &&
            `${product.video}|${product.camera}|${product.weight}gram| ${product.flight_time}minutes|${product.flight_height}meters`}
        </Box>
        {!isOutOfStock && (
          <PinkButton
            text={<FormattedMessage id={'rent'} />}
            width={widthBth}
            height={heightBth}
            optionStyle={{
              position: 'absolute',
              bottom: '20px',
              margin: 'auto',
            }}
          />
        )}
      </Stack>
    </>
  );
};

export default ProductCard;
