import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getOrderList } from '../../store/slices/customerOrdersSlice';
import useWindowWidth from '../../utils/screenSize';
import CustomerOrderMob from '../../components/CustomerOrderViews/CustomerOrderMob';
import CustomerOrderHD from '../../components/CustomerOrderViews/CustomerOrderHD';
import { getUser } from '../../store/slices/usersSlice';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const selectStatus = {
  all: 'all',
  atWork: 'atWork',
  finished: 'finished',
  cancelled: 'cancelled',
  unpaid:'unpaid'
};
const CustomerOrders = () => {
  const navigate = useNavigate();
  const [isSelect, setSelect] = useState(selectStatus.all);
  const { orders } = useSelector((state) => state.orders);
  const [isLoading, setIsLoading] = useState(true);
  const { isRegister } = useSelector((state) => state.users);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderList());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);
  
  useEffect(() => {
    setTimeout(() => {
      if (isRegister) {
        setIsLoading(false);
      } 
      else{navigate('/login')}
    }, 1000);
  }, [dispatch, isRegister,navigate]);

  const filteredOrders = orders.filter((order) => {
    switch (isSelect) {
      case selectStatus.atWork:
        return (
          order.status === 'New' ||
          order.status === 'In process' ||
          order.status === 'Delivered' ||
          order.status === 'Shipped'
        );
      case selectStatus.finished:
        return order.status === 'Rental period ended';
      case selectStatus.cancelled:
        return order.status === 'Cancelled';
        case selectStatus.unpaid:
          return order.status === 'Not paid';
      default:
        return true;
    }
  });
  const thisWidth = useWindowWidth();

  if (isLoading) {
    return (
      <Stack
        gap="10px"
        sx={{ alignItems: 'center', justifyContent: 'center', height: '100vh' }}
      >
        <CircularProgress />
        Loading...
      </Stack>
    );
  }

  return (
    <>
      <Header />
      <Stack mt="86px">
        {thisWidth >= 1200 ? (
          <CustomerOrderHD
            filteredOrders={filteredOrders}
            isSelect={isSelect}
            setSelect={setSelect}
            selectStatus={selectStatus}
          />
        ) : (
          <CustomerOrderMob
            filteredOrders={filteredOrders}
            isSelect={isSelect}
            setSelect={setSelect}
            selectStatus={selectStatus}
          />
        )}
      </Stack>
      <Footer />
    </>
  );
};

export default CustomerOrders;
