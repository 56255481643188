import React from 'react';
import { Stack, Box } from '@mui/material';
import ProductListInCart from '../ProductListInCart/ProductListInCart';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import PinkButton from '../Buttons/PinkButton';
import PageTitle from '../PageTitle/PageTitle';
import ProductList from '../ProductList/ProductList';
import { fonts } from '../../fonts';
import useWindowWidth from '../../utils/screenSize';
import { FormattedMessage } from 'react-intl';
import MemoryCardListInCart from '../MemoryCardListInCart/MemoryCardListInCart';

const CartHD = ({
  productsInCart,
  totalSumCart,
  isPopularProducts,
  goToCreateOrder,
  memory_card_in_cart,
  totalSumMemoryCard,
}) => {
  const font = useWindowWidth() >= 1200 && '70px';
  return (
    <Stack sx={{ alignItems: 'center' }}>
      <Stack mt="86px" gap="20px" sx={{ width: '100%', maxWidth: '1600px' }}>
        <Stack gap="30px">
          {' '}
         {productsInCart.length > 0&& <Box component="p" sx={{ ...fonts.fontPoppins70 }}>
         <FormattedMessage id="drones_in_cart" />
          </Box>}
          <ProductListInCart productsInCart={productsInCart} />
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              ...fonts.fontInter24,
              justifyContent: 'flex-end',
            }}
          >
            <FormattedMessage id="total" /> <EuroSymbolIcon /> {totalSumCart}{' '}
            <FormattedMessage id={'tax_included'} />
          </Stack>
        </Stack>
        <Stack sx={{ borderBottom: '1px solid gray' }}></Stack>
        {memory_card_in_cart.length > 0 && (
          <>
            <Stack gap="30px">
              <Box component="p" sx={{ ...fonts.fontPoppins70 }}>
              <FormattedMessage id={'memory_cards_in_cart'} />
              </Box>
              <MemoryCardListInCart memoryCards={memory_card_in_cart} />
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  ...fonts.fontInter24,
                  justifyContent: 'flex-end',
                }}
              >
                <FormattedMessage id="total" /> <EuroSymbolIcon />{' '}
                {totalSumMemoryCard} <FormattedMessage id={'tax_included'} />
              </Stack>
            </Stack>
            <Stack sx={{ borderBottom: '1px solid gray' }}></Stack>
          </>
        )}
        {productsInCart.length !== 0 && (
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <PinkButton
              text={<FormattedMessage id="order" />}
              height={'72px'}
              optionStyle={{ width: '100%', maxWidth: '232px' }}
              action={goToCreateOrder}
            />
          </Stack>
        )}

        <PageTitle
          text={<FormattedMessage id="most_popular" />}
          optionStyle={{ fontSize: font }}
        />
        <ProductList propsProducts={isPopularProducts} />
      </Stack>
    </Stack>
  );
};

export default CartHD;
