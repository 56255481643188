import React from 'react';
import { Typography, Grid, Stack, styled, Box } from '@mui/material';
import bg from '../../images/workingWithUsSection.png';
import bg2 from '../../images/workingWithUsSection2.png';
import bg3 from '../../images/workingWithUsSection3.png';
import bg4 from '../../images/workingWithUsSection4.png';
import { FormattedMessage } from 'react-intl';
import { sectionsStyle } from '../../styles';
import { fonts } from '../../fonts';
import PinkButton from '../Buttons/PinkButton';
import { primary } from '../../colors';
import useWindowWidth from '../../utils/screenSize';
import { Link } from 'react-router-dom';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';


const StyledImage = styled('img')({
  maxWidth: '100%',
  height: 'auto',
});
const WorkingWithUs = () => {
  const styleBox = {
    backgroundImage: useWindowWidth() >= 1200 ? `url(${bg4})` : `url(${bg})`,
    backgroundSize: '65%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: useWindowWidth() >= 1200 ? '70% 40%' : 'right',
  };
  return (
    <>
      {useWindowWidth() >= 1200 ? (
        <Stack sx={{ alignItems: 'center' }}>
          <Stack
            sx={{
              width: 'calc(100% - 20px)',
              maxWidth: '1600px',
              ...styleBox,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Stack>
              <Grid
                container
                spacing={2}
                alignItems="flex-start"
                sx={{
                  width: '650px',
                  flexDirection: 'row-revers',
                  alignItems: 'flex-start',
                }}
              >
                {/* Первая строка */}
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ ...fonts.fontPoppins80,textAlign:'start' }}
                  >
                    <FormattedMessage id={"working_with"}/>
                  </Typography>
                </Grid>
                {/* Вторая строка */}
                <Grid item container spacing={2} alignItems="center">
                  <Grid item>
                    <Typography variant="body1" sx={{ ...fonts.fontPoppins80 }}>
                    <FormattedMessage id={"us"}/>,
                    </Typography>
                  </Grid>
                  <Grid item>
                    <StyledImage src={bg3} alt="Description" />
                  </Grid>
                </Grid>
                {/* Третья строка */}
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ ...fonts.fontPoppins80 ,textAlign:'start'}}
                  >
                   <FormattedMessage id={"you_always"}/> 
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ ...fonts.fontPoppins80,textAlign:'start' }}
                  >
                    <FormattedMessage id={"receive_the"}/>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{
                      color: primary.blue,
                      border: `2px solid ${primary.blue}`,
                      borderRadius: '100px',
                      textAlign: 'center',
                      padding: '0px 20px',
                      ...fonts.fontPoppins80,
                      width:'600px'
                    }}
                  >
                    <FormattedMessage id={"best_service"}/>
                  </Typography>
                </Grid>
              </Grid>
              <Link to='/catalog'>
                <PinkButton
                  text={<FormattedMessage id={"get_started"}/>}
                  width={'311px'}
                  height={'72px'}
                  optionStyle={{ marginTop: '40px' }}
                />
              </Link>
            </Stack>
            <Stack
              gap="154px"
              sx={{ ...fonts.fontPoppins80, justifyContent: 'center' }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                100+
                <span style={{ ...fonts.fontInter18_400 }}><FormattedMessage id={"available_drones"}/></span>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              1000+
                <span style={{ ...fonts.fontInter18_400 }}><FormattedMessage id={"active_users"}/></span>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <AllInclusiveIcon sx={{fontSize:'120px'}}/>
                <span style={{ ...fonts.fontInter18_400 }}><FormattedMessage id={"love_from_our_customers"}/></span>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack sx={{ ...styleBox, ...sectionsStyle }}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{
              width: '269px',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            {/* Первая строка */}
            <Grid item xs={12}>
              <Typography
                variant="body1"
                align="center"
                sx={{ ...fonts.fontPoppins32 }}
              >
                <FormattedMessage id={"working_with"}/>
              </Typography>
            </Grid>
            {/* Вторая строка */}
            <Grid item container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="body1" sx={{ ...fonts.fontPoppins32 }}>
                <FormattedMessage id={"us"}/>,
                </Typography>
              </Grid>
              <Grid item>
                <StyledImage src={bg2} alt="Description" />
              </Grid>
            </Grid>
            {/* Третья строка */}
            <Grid item xs={12}>
              <Typography
                variant="body1"
                align="center"
                sx={{ ...fonts.fontPoppins32 }}
              >
                <FormattedMessage id={"you_always"}/>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                align="center"
                sx={{ ...fonts.fontPoppins32 }}
              >
                <FormattedMessage id={"receive_the"}/>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                align="center"
                sx={{
                  color: primary.blue,
                  border: `1px solid ${primary.blue}`,
                  borderRadius: '40px',
                  textAlign: 'center',
                  padding: '0px 10px',
                  ...fonts.fontPoppins32,
                  width:'237px'
                }}
              >
                <FormattedMessage id={"best_service"}/>
              </Typography>
            </Grid>
          </Grid>
          <Link to='/catalog'>
            <PinkButton
              text={'Get started'}
              width={'240px'}
              optionStyle={{ margin: 'auto' }}
            />
          </Link>
        </Stack>
      )}
    </>
  );
};

export default WorkingWithUs;
