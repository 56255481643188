import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { Stack, Box } from '@mui/material';
import PinkButton from '../../Buttons/PinkButton';
import { fonts } from '../../../fonts';
import useWindowWidth from '../../../utils/screenSize';
import { DeliveryIcon } from '../../Icons/Icons.js';
import SellIcon from '@mui/icons-material/Sell';
import ShieldIcon from '@mui/icons-material/Shield';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCart,
  addMemoryCard,
  changeIsLicensePilot
} from '../../../store/slices/productsSlice.js';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';
import MonthsSwitcher from './MonthsSwitcher';
import YesOrNoSwitcher from './YesOrNoSwitcher';
import MemoryCardSwitcher from './MemoryCardSwitcher';
import { FormattedMessage, injectIntl } from 'react-intl';
import { primary } from '../../../colors';
import styles from '../../../custom-sweetalert.module.scss';
const Term = {
  oneMonths: 'oneMonths',
  threeMonths: 'threeMonths',
  sixMonths: 'sixMonths',
  twelveMonths: 'twelveMonths',
};

const Cards = {
  card128: '128',
  card256: '256',
  card512: '512',
  card1024: '1024',
};
const switchStyle = {
  width: '25%',
  transition: '0.8s',
  cursor: 'pointer',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const switchStyle2 = {
  width: '200px',
  height: '50px',
  ...fonts.fontInter16_600,
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  borderRadius: '100px',
  border: `1px solid rgba(67, 97, 238, 0.5)`,
};
const ProductForm = ({ characteristics, memoryCard, intl }) => {
  console.log('characteristics', characteristics);
  const [isOutOfStock, setOutOfStock] = useState();
  const [rentPrice, setRentPrice] = useState();
  const [discount, setDiscount] = useState();
  const dispatch = useDispatch();
  const [selectedTerm, setSelectedTerm] = useState(Term.oneMonths);
  const [selectedCard, setSelectedCard] = useState('');
  const [selectedCardForBuy, setSelectedCardForBuy] = useState(null);
  const [isLicense, setIsLicense] = useState(false);
  const [isRentMemoryCard, setRentMemoryCard] = useState(false);
  const [priceMemoryCard, setPriceCard] = useState(0);
  const widthBtn = useWindowWidth() >= 1200 ? '519px' : '163px';
  const heightBtn = useWindowWidth() >= 1200 ? '72px' : '46px';
  const navigate = useNavigate();
  const customStyleSweetAlert = {
    container: styles.custom_swal_container,
    title: styles.custom_swal_title,
    content: styles.custom_swal_content,
    confirmButton: styles.custom_swal_confirm_button,
    popup: styles.swal2_popup,
    icon: styles.custom_swal_icon,
  };
  const openAvailableMessage = () => {
    Swal.fire({
      title: intl.formatMessage({ id: 'available_to_own' }),
      text: intl.formatMessage({ id: 'available_to_own_text' }),
      icon: 'info',
      confirmButtonText: intl.formatMessage({ id: 'thanks' }),
      customClass: customStyleSweetAlert,
    });
  };
  const openFREEGroverMessage = () => {
    Swal.fire({
      title: intl.formatMessage({ id: 'grover_care_alert' }),
      text: intl.formatMessage({ id: 'grover_care_alert_text' }),
      icon: 'info',
      confirmButtonText: intl.formatMessage({ id: 'thanks' }),
      customClass: customStyleSweetAlert,
    });
  };
  const handleClick = () => {
    Swal.fire({
      title: intl.formatMessage({ id: 'success' }),
      text: intl.formatMessage({ id: 'add_to_cart' }),
      icon: 'success',
      confirmButtonText: intl.formatMessage({ id: 'cool' }),
      customClass: customStyleSweetAlert,
    });
    handleAddToCart();
  };

  useEffect(() => {
    setOutOfStock(characteristics?.quantity_in_store === 0);
  }, [characteristics]);
  useEffect(() => {
    setRentPrice(characteristics?.price_month);
  }, [characteristics]);

  useEffect(() => {
    if (isRentMemoryCard === false) {
      setPriceCard(0);
      setSelectedCard('');
    }
  }, [isRentMemoryCard]);

  useEffect(() => {
    dispatch(changeIsLicensePilot(isLicense))
    localStorage.setItem('isLicense', JSON.stringify(isLicense));
  }, [isLicense,dispatch]);

  useEffect(() => {
    let price;
    let discount;
    if (isLicense && selectedTerm === Term.oneMonths && characteristics) {
      price = characteristics.prices.price_month;
      discount =
        characteristics?.price_month - characteristics.prices.price_month;
    } else if (!isLicense && selectedTerm === Term.oneMonths) {
      price = characteristics?.price_month;
      discount = 0;
    }
    if (isLicense && selectedTerm === Term.threeMonths && characteristics) {
      price = characteristics.prices.price_3_months;
      discount =
        characteristics.price_3_months - characteristics.prices.price_3_months;
    } else if (!isLicense && selectedTerm === Term.threeMonths) {
      price = characteristics.price_3_months;
      discount = 0;
    }
    if (isLicense && selectedTerm === Term.sixMonths && characteristics) {
      price = characteristics.prices.price_6_months;
      discount =
        characteristics.price_6_months - characteristics.prices.price_6_months;
    } else if (!isLicense && selectedTerm === Term.sixMonths) {
      price = characteristics.price_6_months;
      discount = 0;
    }
    if (isLicense && selectedTerm === Term.twelveMonths && characteristics) {
      price = characteristics.prices?.price_year;
      discount =
        characteristics.price_year - characteristics.prices?.price_year;
    } else if (!isLicense && selectedTerm === Term.twelveMonths) {
      price = characteristics.price_year;
      discount = 0;
    }
    setRentPrice(price);
    setDiscount(discount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTerm, isLicense]);

  const handleAddToCart = () => {
    characteristics = {
      ...characteristics,
      rentPrice: rentPrice,
      discount: discount,
      rental_duration: selectedTerm,
      quantity: 1,
    };
   
    let memoryCard = {
      ...selectedCardForBuy,
      quantity: 1,
    };
    if (selectedCardForBuy !== null) {
      dispatch(addMemoryCard(memoryCard));
    }
    setSelectedCardForBuy(null);
    dispatch(addToCart(characteristics));
    navigate('/catalog');
  };

  return (
    <Stack gap="30px">
      <Box sx={{ ...fonts.fontInter16_400 }}>
        <FormattedMessage id={'rental_period'} />
      </Box>
      <MonthsSwitcher
        setSelectedTerm={setSelectedTerm}
        setRentPrice={setRentPrice}
        switchStyle={switchStyle}
        characteristics={characteristics}
        Term={Term}
        selectedTerm={selectedTerm}
      />
      <YesOrNoSwitcher
        switchStyle={switchStyle}
        trigger={isLicense}
        setTrigger={setIsLicense}
        switchStyle2={switchStyle2}
        text={<FormattedMessage id={'pilot_license'} />}
      />
      <YesOrNoSwitcher
        switchStyle={switchStyle}
        trigger={isRentMemoryCard}
        setTrigger={setRentMemoryCard}
        switchStyle2={switchStyle2}
        text={<FormattedMessage id={'memory_card'} />}
      />
      {isRentMemoryCard && (
        <MemoryCardSwitcher
          setSelectedCard={setSelectedCard}
          selectedCard={selectedCard}
          setPriceCard={setPriceCard}
          priceMemoryCard={priceMemoryCard}
          Cards={Cards}
          characteristics={characteristics}
          switchStyle={switchStyle}
          memoryCard={memoryCard}
          setSelectedCardForBuy={setSelectedCardForBuy}
        />
      )}
      <Stack
        sx={{
          direction: useWindowWidth() >= 1200 ? 'column' : 'row',
          gap: '30px',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            ...fonts.fontInter40,
            display: 'flex',
            gap: '20px',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ ...fonts.fontInter16_400, color: primary.pink }}>
            *<FormattedMessage id={'price_includes_taxes'} />
          </Box>
          <Box>
            € {rentPrice}/
            <span style={{ ...fonts.fontInter24 }}>
              <FormattedMessage id={'month'} />{' '}
              <FormattedMessage id={'tax_included'} />
            </span>{' '}
          </Box>
        </Box>

        {!isOutOfStock ? (
          <PinkButton
            text={<FormattedMessage id={'rent'} />}
            width={widthBtn}
            height={heightBtn}
            action={handleClick}
          />
        ) : (
          <Stack
            direction="row"
            gap="5px"
            sx={{
              width: widthBtn,
              height: heightBtn,
              alignItems: 'center',
              backgroundColor: '#fff9e5',
              justifyContent:'center',
              borderRadius:'20px',
              ...fonts.fontInter18_400
            }}
          >
            <WarningIcon />
            <FormattedMessage id={'this_product_currently_unavailable'} />
          </Stack>
        )}
        {useWindowWidth() >= 1200 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
            }}
          >
            <DeliveryIcon /> <FormattedMessage id={'delivery_days'} />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            cursor: 'pointer',
          }}
          onClick={openAvailableMessage}
        >
          <SellIcon sx={{ color: primary.pink }} />{' '}
          <FormattedMessage id={'available_own'} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            cursor: 'pointer',
          }}
          onClick={openFREEGroverMessage}
        >
          <ShieldIcon sx={{ color: primary.pink }} />{' '}
          {/* <span style={{ color: '#638600' }}>
            <FormattedMessage id={'free'} />
          </span> */}
          <FormattedMessage id={'grover_care'} />
        </Box>
      </Stack>
    </Stack>
  );
};

export default injectIntl(ProductForm);
