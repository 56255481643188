import React from 'react';
import { Stack } from '@mui/material';
import ProductInCart from '../ProductInCart/ProductInCart';
import emptyCart from '../../images/cart_bg.svg';
import useWindowWidth from '../../utils/screenSize';
import { FormattedMessage } from 'react-intl';
import { fonts } from '../../fonts';
const ProductListInCart = ({productsInCart}) => {
  const sizeBgImg=useWindowWidth()>=1200?'30%':'calc(100% - 40px)';
  return (
    <Stack gap="25px">
     {productsInCart.length>0?
     ( productsInCart.map((product,i)=>(
      <ProductInCart product={product} key={i}/>
     ))):(<Stack sx={{...fonts.fontInter24,alignItems:'center',gap:'40px'}}> <FormattedMessage id={'cart_is_empty'}/><img src={emptyCart} alt="" style={{width:sizeBgImg,margin:'auto'}}/></Stack>)
    
    
    }
    </Stack>
  );
}

export default ProductListInCart;
