import React, { useEffect,useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProductList } from '../../store/slices/productsSlice.js';
import ProductCard from '../ProductCard/ProductCard';
import { Stack } from '@mui/material';
import CatalogScale from '../CatalogScale/CatalogScale';

const ProductList = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 9; 
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentUrl = window.location.href;
  let isCatalogPath=false;

if (currentUrl.endsWith('/catalog')) {
  isCatalogPath=true
}


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const dispatch = useDispatch();
  const reduxProducts = useSelector((state) => state.products);
  const sortedArr = reduxProducts.products.slice().sort((a, b) => {
    if (a.quantity_in_store === 0 && b.quantity_in_store !== 0) {
      return 1;
    } else if (a.quantity_in_store !== 0 && b.quantity_in_store === 0) {
      return -1;
    } else {
      return 0;
    }
  });
  const { propsProducts } = props;
  useEffect(() => {
    if (!propsProducts) {
      dispatch(getProductList());
    }
  }, [dispatch, propsProducts]);
  const products = propsProducts || sortedArr;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);
  
  return (
    <>
      <Stack sx={{ flexDirection: 'row', flexWrap: 'wrap', gap: '10px' ,alignItems:'center',justifyContent:'center'}}>
        {currentProducts.length>0&& currentProducts.map((product, i) => {
          
          return (
            <ProductCard
              product={product}
              key={i}
            />
          );
        })}

        {isCatalogPath&&!propsProducts&&products.length>0&&<CatalogScale 
         currentPage={currentPage}
         productsPerPage={productsPerPage}
         totalProducts={products.length}
         onPageChange={handlePageChange}
        />}
      </Stack>
    </>
  );
};

export default ProductList;
