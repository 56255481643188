import React from 'react';
import { Stack, Box } from '@mui/material';
import { sectionsStyle } from '../../styles';

import { fonts } from '../../fonts';

const Rating = ({starArr,averageRating }) => {
  return (
    <Stack
      sx={{
        ...sectionsStyle,
        textAlign: 'center',
        backgroundColor: '#fff',
        borderRadius: '8px',
      }}
    >
      <Box component="h4" sx={{ ...fonts.fontManrope25_800 }}>
        Trustpilot
      </Box>
      <Stack
        direction="row"
        gap="5px"
        sx={{ alignItems: 'center', justifyContent: 'center' }}
      >
        {starArr.map((star, i) => {
          return <img key={i} src={star} alt="" />;
        })}
      </Stack>
      <Box
        component="span"
        sx={{ color: 'rgba(134, 134, 134, 1)', ...fonts.fontManrope18_700 }}
      >
        Rated {averageRating}/5.0
      </Box>
    </Stack>
  );
};

export default Rating;
