import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import HeaderMob from './HeaderMob';
import HeaderHD from './HeaderHD';
import { getUser } from '../../store/slices/usersSlice';
import useWindowWidth from '../../utils/screenSize.js'
const Header = ({scrollToSection}) => {
  const dispatch = useDispatch();
  const { isRegister } = useSelector((state) => state.users);
  const [indicator, setIndicator] = useState(false);
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);
  useEffect(() => {
    setIndicator(isRegister);
  }, [isRegister]);
  return <>{useWindowWidth() >= 1200 ? <HeaderHD indicator={indicator} scrollToSection={scrollToSection}/> : <HeaderMob indicator={indicator} scrollToSection={scrollToSection}/>}</>;
};

export default Header;
