export const fonts={
  fontManrope25_800:{
    fontFamily:"'Manrope', sans-serif",
    fontSize: '25px',
    fontWeight:800,  
  },
  fontManrope18_700:{
    fontFamily:"'Manrope', sans-serif",
    fontSize: '18px',
    fontWeight:700,  
  },
  fontPoppins80: {
    fontFamily:"'Poppins', sans-serif",
    fontSize: '80px',
    fontWeight:500,  
  },
  fontPoppins70: {
    fontFamily:"'Poppins', sans-serif",
    fontSize: '70px',
    fontWeight:500,  
  },
  fontPoppins50: {
    fontFamily:"'Poppins', sans-serif",
    fontSize: '50px',
    fontWeight:500,  
  },
  fontPoppins40: {
    fontFamily:"'Poppins', sans-serif",
    fontSize: '40px',
    fontWeight:500,  
  },
  fontPoppins32: {
    fontFamily:"'Poppins', sans-serif",
    fontSize: '32px',
    fontWeight:500,  
  },
  fontInter40: {
    fontFamily:"'Inter',sans-serif",
    fontSize: '40px',
    fontWeight:600,  
  },
  fontInter24: {
    fontFamily:"'Inter',sans-serif",
    fontSize: '24px',
    fontWeight:600,  
  },
  fontInter24_500: {
    fontFamily:"'Inter',sans-serif",
    fontSize: '24px',
    fontWeight:500,  
  },
  fontInter20: {
    fontFamily:"'Inter',sans-serif",
    fontSize: '20px',
    fontWeight:400,  
  },
  fontInter18_600: {
    fontFamily:"'Inter',sans-serif",
    fontSize: '18px',
    fontWeight:600,  
  },
  fontInter18_500: {
    fontFamily:"'Inter',sans-serif",
    fontSize: '18px',
    fontWeight:500,  
  },
  fontInter18_400: {
    fontFamily:"'Inter',sans-serif",
    fontSize: '18px',
    fontWeight:400,  
  },
  fontInter16_600: {
    fontFamily:"'Inter',sans-serif",
    fontSize: '16px',
    fontWeight:600,  
  },
  fontInter16_400: {
    fontFamily:"'Inter',sans-serif",
    fontSize: '16px',
    fontWeight:400,  
  },
  fontInter13_600: {
    fontFamily:"'Inter',sans-serif",
    fontSize: '13px',
    fontWeight:600,  
  },
  fontInter14_700: {
    fontFamily:"'Inter',sans-serif",
    fontSize: '14px',
    fontWeight:700,  
  },
  fontInter14_300: {
    fontFamily:"'Inter',sans-serif",
    fontSize: '14px',
    fontWeight:300,  
  },
  fontInter10_600: {
    fontFamily:"'Inter',sans-serif",
    fontSize: '10px',
    fontWeight:600,  
  },
} 

