import React from 'react';
import { Stack } from '@mui/material';
import MemoryCardInCart from '../MemoryCardInCart/MemoryCardInCart';
const MemoryCardListInCart = ({ memoryCards }) => {
  return (
    <Stack gap="25px">
      {memoryCards.length > 0 &&
        memoryCards.map((product, i) => (
          <MemoryCardInCart product={product} key={i} />
        ))}
    </Stack>
  );
};
export default MemoryCardListInCart;
