
import { Stack, Box } from '@mui/material';
import { primary } from '../../../colors.js';
import Rating from '../Rating';
import PinkButton from '../../Buttons/PinkButton';
import ReviewCard from '../../ReviewCard/ReviewCard';
import useWindowWidth from '../../../utils/screenSize';
import { FormattedMessage } from 'react-intl';



const RatingHD = ({reviews,starArr,averageRating}) => {
 
  const sortedReviews = [...reviews].sort((a, b) => new Date(b.time) - new Date(a.time));
  const newestReviews = sortedReviews.slice(0, 4);
 
  return (
    <Stack
      direction="row"
      gap="32px"
      sx={{
        width: '100%',
        minHeight: '452px',
        backgroundColor: primary.blue,
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Box
        sx={{
          padding:useWindowWidth()>=1200&&'0px 20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '252px',
          justifyContent: 'space-between',
          margin: '20px 0px',
        }}
      >
        <Rating starArr={starArr} averageRating={averageRating}/>
        <PinkButton text={<FormattedMessage id={"view_all"}/>} width={'277px'} height={'72px'} />
      </Box>
      {newestReviews.map((review,i)=>(<ReviewCard key={i} review={review}/>) )}
    </Stack>
  );
};

export default RatingHD;
