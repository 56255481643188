import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';
import { setLanguage } from '../../store/slices/language.js';
import { useDispatch } from 'react-redux';
import { getFAQList,getReviewsList } from '../../store/slices/reviews_faqs';


const LanguageSelector = ({handleClose1=null}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (language) => {
    dispatch(getReviewsList(language));
    dispatch(getFAQList(language));
    dispatch(setLanguage(language));
    handleCloseMenu();
    if(handleClose1){ handleClose1();}
   
    console.log(`Selected language: ${language}`);
  };

  return (
    <>
      <IconButton
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        <LanguageIcon />
      </IconButton>
      <Menu
      disableScrollLock={true}
        id="language-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleLanguageSelect('en')}>
          English
        </MenuItem>
        <MenuItem onClick={() => handleLanguageSelect('nl')}>
        Nederlands
        </MenuItem>
        
      </Menu>
    </>
  );
};

export default LanguageSelector;
