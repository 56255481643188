import * as yup from 'yup';
const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/;

export const forgotPasswordSchema=yup.object().shape({
  email: yup
  .string()
  .email('Please enter a valid email address')
  .required('Email is required'),
})
export const resetPasswordSchema=yup.object().shape({
  new_password1: yup
  .string()
  .matches(/[a-z]/, 'Password must contain at least 1 lowercase latin letter')
  // .matches(/[A-Z]/, 'Password must contain at least 1 uppercase letter')
  .matches(/\d{4,}/, 'Password must contain at least 4 digits')
  .min(8, 'Password must be at least 8 characters long')
  .required('Password is required'),

  new_password2: yup
  .string()
  .oneOf([yup.ref('new_password1'), null], 'Passwords must match')
  .required('Password confirmation is required'),
})
export const changePasswordSchema=yup.object().shape({
  old_password: yup
  .string()
  .matches(/[a-z]/, 'Password must contain at least 1 lowercase latin letter')
  .matches(/\d{4,}/, 'Password must contain at least 4 digits')
  .min(8, 'Password must be at least 8 characters long')
  .required('Password is required'),
new_password: yup
  .string()
  .matches(/[a-z]/, 'Password must contain at least 1 lowercase latin letter')
  .matches(/\d{4,}/, 'Password must contain at least 4 digits')
  .min(8, 'Password must be at least 8 characters long')
  .required('Password is required')
  .test('not-same-as-old', 'New password must be different from old password', function (value) {
    return value !== this.parent.old_password;
  }),
confirm_new_password: yup
  .string()
  .oneOf([yup.ref('new_password'), null], 'Passwords must match')
  .required('Password confirmation is required'),
})
export const OrderRepairSchema = yup.object().shape({
  phone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone is required'),
  name: yup
    .string()
    .test(
      'test-name',
      'Name is required',
      (value) => value && value.trim().length >= 1
    )
    .required('Name is required'),
  city: yup
    .string()
    .min(1, 'City must be at least 1 character')
    .max(20, 'City must not exceed 20 characters'),
  address: yup.string(),
  comment: yup.string(),
  files: yup.mixed().nullable(),
});

export const userRegistrationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  password1: yup
    .string()
    .matches(/[a-z]/, 'Password must contain at least 1 lowercase latin letter')
    // .matches(/[A-Z]/, 'Password must contain at least 1 uppercase letter')
    .matches(/\d{4,}/, 'Password must contain at least 4 digits')
    .min(8, 'Password must be at least 8 characters long')
    .required('Password is required'),

  password2: yup
    .string()
    .oneOf([yup.ref('password1'), null], 'Passwords must match')
    .required('Password confirmation is required'),
});

export const userLoginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  password: yup
    .string()
    .matches(/[a-z]/, 'Password must contain at least 1 lowercase latin letter')
    .matches(/\d{4,}/, 'Password must contain at least 4 digits')
    .min(8, 'Password must be at least 8 characters long')
    .required('Password is required'),
});

export const userProfileSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid name in Latin letters')
    .max(40)
    .required('Name is required'),
  surname: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid surname in Latin letters')
    .max(40)
    .required('Surname is required'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required'),

  email_confirm: yup
    .string()
    .oneOf([yup.ref('email_confirm'), null], 'Email must match')
    .required('Email confirmation is required'),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone is required'),
  delivery_address: yup.string().max(100, 'Maximum number of characters 100').required('Address is required'),
  city: yup
  .string()
  .required('City is required')
  .min(1, 'City must be at least 1 character')
  .max(20, 'City must not exceed 20 characters'),
});
export const RequestForConsultationSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid name in Latin letters')
    .max(40)
    .required('Name is required'),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone is required'),
  message: yup.string(),
});
export const orderProcessingValidationSchemas = {
  firstStep: yup.object().shape({
    name: yup
      .string()
      .matches(/^[A-Za-z ]*$/, 'Please enter valid name in Latin letters')
      .max(40)
      .required('Name is required'),
    surname: yup
      .string()
      .matches(/^[A-Za-z ]*$/, 'Please enter valid surname in Latin letters')
      .max(40)
      .required('Surname is required'),
    phone: yup
      .string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Phone is required'),
      // email: yup
      // .string()
      // .email('Please enter a valid email address')
      // .required('Email is required'),
      // password: yup
      // .string()
      // .matches(/[a-z]/, 'Password must contain at least 1 lowercase latin letter')
     
      // .matches(/\d{4,}/, 'Password must contain at least 4 digits')
      // .min(8, 'Password must be at least 8 characters long')
      // .required('Password is required'),
  }),
  secondStep: yup.object().shape({
    city: yup
      .string()
      .min(1, 'City must be at least 1 character')
      .max(20, 'City must not exceed 20 characters')
      .required('City is required'),
    delivery_address: yup.string().required('Address is required'),
   
  }),
};

export const verificationValidationSchemas = {
  step1: yup.object().shape({
    phone_number: yup
      .string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Phone is required'),
  }),
  step2: yup.object().shape({
    city: yup
      .string()
      .min(1, 'City must be at least 1 character')
      .matches(/^[A-Za-z ]*$/, 'Please enter valid name in Latin letters')
      .max(20, 'City must not exceed 20 characters')
      .required('City is required'),
    street: yup
      .string()
      .min(1, 'Street must be at least 1 character')
      .matches(/^[A-Za-z0-9 ]*$/, 'Please enter valid name in Latin letters')
      .max(40, 'Street must not exceed 40 characters')
      .required('Street is required'),
    country: yup
      .string()
      .min(1, 'Country must be at least 1 character')
      .matches(/^[A-Za-z ]*$/, 'Please enter valid name in Latin letters')
      .max(20, 'Country must not exceed 20 characters')
      .required('Country is required'),
    postcode: yup
      .string()
      .min(1, 'Postcode must be at least 1 character')
      .max(10, 'Postcode must not exceed 10 characters'),
    address_files1: yup
      .array()
      .min(1, 'You must add at least one file')
      .required('Files required'),
    address_files2: yup
      .array()
      .min(1, 'You must add at least one file')
      .required('Files required'),
  }),
  step3: yup.object().shape({
    id_card_files1: yup
      .array()
      .min(1, 'You must add at least one file')
      .required('Files required'),
    id_card_files2: yup
      .array()
      .min(1, 'You must add at least one file')
      .required('Files required'),
  }),

  step4: yup.object().shape({
    income: yup
      .number()
      .min(1, 'Income must be at least 1 euro')
      .required('Income is required')
      .typeError('Income must be a number'),
    isDebt: yup.boolean(),
    debt_sum: yup.number().when('isDebt', {
      is: true,
      then: (schema) =>
        schema
          .min(1, 'Debt must be at least 1 euro')
          .required('Debt sum is required when there is debt')
          .typeError('Debt sum must be a number'),
      otherwise: (schema) => schema.optional(),
    }),
    agree: yup.boolean()
    .oneOf([true], 'You must agree to the terms and conditions')
    .required('You must agree to the terms and conditions'),
  }),
  
};
