import React from 'react';
import { Box, Stack } from '@mui/material';
import PinkButton from '../Buttons/PinkButton';
import BlueButton from '../Buttons/BlueButton';
import { fonts } from '../../fonts.js';
import PageTitle from '../PageTitle/PageTitle';
import logo1 from '../../images/homePageDrone.png';
import { sectionsStyle } from '../../styles.js';
import bg from '../../images/drone_Img_HD.png';
import OurServicesAvatars from '../OurServicesAvatars/OurServicesAvatars';
import { ArrowRight } from '../Icons/Icons';
import { primary } from '../../colors';
import EU_FLAG from '../../images/EU_flag.png';
import FlagsArray from '../FlagsArray/FlagsArray';
import OurServices from '../OurServices/OurServices';
import useWindowWidth from '../../utils/screenSize';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import  LazyLoad  from 'react-lazyload';
const TitleSection = ({ optionStyle }) => {
  const navigate = useNavigate();
  const mainBlockStyle = {
    margin: 'auto',
    maxWidth: '1600px',
    width: 'calc(100% - 40px)',
    backgroundImage: `url('${bg}')`,
    backgroundSize: useWindowWidth() >= 1800 ? '100%' : '70%',
    backgroundPosition: useWindowWidth() >= 1800 ? '400px -30px' : 'right top',
    backgroundRepeat: 'no-repeat',
  };
  return (
    <>
      {useWindowWidth() >= 1200 ? (
        <LazyLoad offset={100}>
          <Stack
            gap="60px"
            sx={{
              ...mainBlockStyle,
            }}
          >
            {' '}
            <Stack>
              <BlueButton
                width={'638px'}
                height={'125px'}
                text={<FormattedMessage id="perspectives" />}
                optionStyle={{ ...fonts.fontPoppins80 }}
              />
              <PageTitle
                text={<FormattedMessage id="from_new_heights" />}
                optionStyle={{ ...fonts.fontPoppins80, margin: '30px 0px' }}
              />
              <Box
                component="p"
                sx={{
                  maxWidth: '550px',
                  lineHeight: '30px',
                  ...fonts.fontInter20,
                }}
              >
                <FormattedMessage id="home_title_description" />
              </Box>
          
                <PinkButton
                action={()=>navigate('/catalog')}
                  text={<FormattedMessage id="get_started" />}
                  width={'311px'}
                  height={'72px'}
                  optionStyle={{ marginTop: '30px', ...fonts.fontInter18_600 }}
                />
          
            </Stack>
            <Stack direction="row" gap="20px" onClick={()=>navigate('/repair')} sx={{cursor:'pointer'}}>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: 'rgba(67, 97, 238, 0.2)',
                  width: '100%',
                  maxWidth: '790px',
                  height: '247px',
                  alignItems: 'center',
                  borderRadius: '10px',
                  padding: '0px 24px 0px 22px',
                  justifyContent: 'center',
                  ...optionStyle,
                }}
              >
                <OurServicesAvatars />
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  alignItem: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  maxWidth: '790px',
                  height: '247px',
                  backgroundColor: primary.blue,
                  borderRadius: '10px',
                  zIndex: '-1',
                  cursor: 'pointer',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '8px',
                    color: '#fff',
                    position: 'relative',
                  }}
                >
                  <Box sx={{ ...fonts.fontPoppins50 }}>
                    <FormattedMessage id="choose_our" />{' '}
                    <FormattedMessage id="services" />
                  </Box>
                  <div
                    onClick={() => {
                      navigate('/repair');
                    }}
                    style={{
                      position: 'absolute',
                      zIndex: '1000',
                      cursor: 'pointer',
                      left:'100%'
                    }}
                  >
                    <ArrowRight width={'37px'} height={'21px'} />
                  </div>
                </Box>
              </Box>
            </Stack>
            <Stack gap="70px">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '160px',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', maxWidth: '331px' }}>
                  <Box sx={{ ...fonts.fontInter18_600, lineHeight: '27px' }}>
                    <FormattedMessage id="rent_is_possible" />
                  </Box>
                  <Box>
                    <img src={EU_FLAG} alt="" />
                  </Box>
                </Box>
                <FlagsArray />
              </Box>
              <OurServices />
            </Stack>
          </Stack>
        </LazyLoad>
      ) : (
        <LazyLoad offset={100}>
          <Stack
            component="section"
            sx={{
              ...sectionsStyle,
              alignItems: 'center',
            }}
          >
            <BlueButton
              width={'347px'}
              height={'75px'}
              text={'Perspectives'}
              optionStyle={{ ...fonts.fontPoppins40 }}
            />
            <PageTitle text={'from New Heights'} />
            <Box component="p" sx={{ ...fonts.fontInter16_400 }}>
              <FormattedMessage
                id="home_title_description"
                defaultMessage=" Rent drones and unlock endless possibilities of aerial adventures.
                Our services make drone rental easy and accessible for everyone
                who dreams of seeing the world from above!"
              />
            </Box>
            <Box>
              <img src={logo1} alt="" width="385px" height="250px" />
            </Box>
            <Link to="/catalog">
              <PinkButton text={'Get Started'} width={'240px'} height={'46px'} />
            </Link>
          </Stack>
        </LazyLoad>
      )}
    </>
  );
};

export default TitleSection;
