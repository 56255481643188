import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { Stack } from '@mui/material';
import { sectionsStyle } from '../../styles';
import { getProductList } from '../../store/slices/productsSlice';
import {
  reWriteTotalSumCart,
  reWriteTotalDiscount,
  reWriteCart,
  reWriteTotalSumCartMemoryCard,
  reWriteCardInCart,
} from '../../store/slices/productsSlice.js';
import useWindowWidth from '../../utils/screenSize';
import { useNavigate } from 'react-router-dom';
import CartHD from '../../components/CartComponents/CartHD';
import CartMob from '../../components/CartComponents/CartMob';

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { products, totalSumCart, cart, memory_card_in_cart,totalSumMemoryCard } = useSelector(
    (state) => state.products
  );

  const [productsInCart, setProductsInCart] = useState(() => {
    const savedCart = localStorage.getItem('productsInCart');

    return savedCart ? JSON.parse(savedCart) : [];
  });

  const [cardInCart, setCardInCart] = useState(() => {
    const savedCart = localStorage.getItem('memoryCardInCart');
 
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {
    const totalSum = localStorage.getItem('totalSumCart');
    const totalDiscount = localStorage.getItem('totalDiscount');
    const totalSumMemoryCard = localStorage.getItem('totalSumMemoryCard');
    if (totalSum) {
      dispatch(reWriteTotalSumCart(parseInt(totalSum)));
    }
    if (totalDiscount) {
      dispatch(reWriteTotalDiscount(parseInt(totalDiscount)));
    }
    if (totalSumMemoryCard) {
      dispatch(reWriteTotalSumCartMemoryCard(parseInt(totalSumMemoryCard)));
    }

    const savedCart = localStorage.getItem('productsInCart');
    if (savedCart) {
      setProductsInCart(JSON.parse(savedCart));
    }
    dispatch(reWriteCart(productsInCart));

    const savedCardInCart = localStorage.getItem('memoryCardInCart');
    if (savedCardInCart) {
      setCardInCart(JSON.parse(savedCardInCart));
    }
    dispatch(reWriteCardInCart(cardInCart));
  }, [dispatch]);

  useEffect(() => {
    if (cart && cart.length > 0) {
      localStorage.setItem('productsInCart', JSON.stringify(cart));
      setProductsInCart(cart);
    } else if (cart.length === 0) {
      setProductsInCart([]);
      setCardInCart([])
    }
  }, [cart]);

  useEffect(() => {
    if (memory_card_in_cart && memory_card_in_cart.length > 0) {
      localStorage.setItem(
        'memoryCardInCart',
        JSON.stringify(memory_card_in_cart)
      );
      setCardInCart(memory_card_in_cart);
    } else if (memory_card_in_cart.length === 0) {
      setCardInCart([]);
    }
  }, [memory_card_in_cart]);

  const goToCreateOrder = () => {
    navigate('/creating-order');
  };

  let isPopularProducts = [];
  if (products) {
    isPopularProducts = products.filter((product) => product.is_popular);
  }
  useEffect(() => {
    dispatch(getProductList());
  }, [dispatch]);

  return (
    <>
      <Header />
      {useWindowWidth() >= 1200 ? (
        <CartHD
          productsInCart={productsInCart}
          memory_card_in_cart={cardInCart}
          totalSumCart={totalSumCart}
          isPopularProducts={isPopularProducts}
          goToCreateOrder={goToCreateOrder}
          totalSumMemoryCard={totalSumMemoryCard}
        />
      ) : (
        <Stack mt="86px" sx={{ ...sectionsStyle }}>
          <CartMob
            productsInCart={productsInCart}
            totalSumCart={totalSumCart}
            isPopularProducts={isPopularProducts}
            goToCreateOrder={goToCreateOrder}
            memory_card_in_cart={cardInCart}
            totalSumMemoryCard={totalSumMemoryCard}
          />
        </Stack>
      )}
      <Footer />
    </>
  );
};

export default Cart;
