import { useEffect } from 'react';

const ChatwootWidget = () => {
  useEffect(() => {
    const initializeChatwoot = () => {
      if (window.chatwootSDK) {
        window.chatwootSDK.run({
          websiteToken: 'YOUR_WEBSITE_TOKEN',
          baseUrl: 'https://drone2rent.nl/',
        });
        console.log('window.chatwootSDK.run');
      } else {
        console.error('Chatwoot SDK is not available');
      }
    };
    setTimeout(initializeChatwoot, 1000);
  }, []);

  return null;
};

export default ChatwootWidget;