import React from 'react';
import { sectionsStyle } from '../../styles.js';
import { Box, Stack } from '@mui/material';
import OurServicesAvatars from '../OurServicesAvatars/OurServicesAvatars';
import { ArrowRight} from '../Icons/Icons';
import { fonts } from '../../fonts.js';
import OurServices from '../OurServices/OurServices';

const OurServicesSection = ({optionStyle}) => {
  return (
    <Stack component="section" sx={{ ...sectionsStyle }}>
      <Stack
        direction="row"
        sx={{
          backgroundColor: 'rgba(67, 97, 238, 0.2)',
          width: 'calc(100% - 46px)',
          height: '110px',
          alignItems: 'center',
          borderRadius: '10px',
          padding: '0px 24px 0px 22px',
          gap: '14px',
          ...optionStyle
        }}
      >
        <OurServicesAvatars />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Box sx={{ ...fonts.fontInter13_600 }}>
            Choose Our <br /> Service
          </Box>
          <ArrowRight />
        </Box>
      </Stack>
      <OurServices/>
    
    </Stack>
  );
};

export default OurServicesSection;
