import React from 'react';
import { Stack, Box } from '@mui/material';
import star from '../../images/TrustpilotStarBg.png';
import starHalf from '../../images/TrustpilotStarBgHalf.png';
import { fonts } from '../../fonts';
import { parseISO, format } from 'date-fns';

const ReviewCard = ({ review }) => {
  const formatTime = (timeString) => {
    const date = parseISO(`1970-01-01T${timeString}Z`);
    return format(date, 'h.mm a');
  };
  const getStars = (rated) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rated) {
        stars.push(star);
      } else if (i - 0.5 <= rated) {
        stars.push(starHalf);
      } else {
        stars.push(null);
      }
    }
    return stars;
  };
  const starArr = getStars(review.rated);
  return (
    <Stack
      gap="16px"
      sx={{
        width: '255px',
        height: '188px',
        borderRadius: '10px',
        padding: '32px',
        backgroundColor: '#fff',
        margin: '20px 0px',
      }}
    >
      <Stack
        direction="row"
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Stack
          direction="row"
          gap="5px"
          sx={{ alignItems: 'center', justifyContent: 'center' }}
        >
          {starArr.map((star, i) => {
            return <img key={i} src={star} alt="" width="22px" />;
          })}
        </Stack>
        <Box>{formatTime(review.time)}</Box>
      </Stack>
      <Box component="h6" sx={{ ...fonts.fontInter18_600 }}>
        {review.title}
      </Box>
      <Box component="p" sx={{ ...fonts.fontInter14_300 }}>
        {review.description}
      </Box>
      <Box component="p" sx={{ ...fonts.fontInter14_700 }}>
        {review.name} <span style={{ color: '#6A6A6A' }}>reviews</span>{' '}
        {review.product}
      </Box>
    </Stack>
  );
};

export default ReviewCard;
