import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as restController from '../../api/rest/restController';
import { pendingReducer, rejectedReducer } from '../../utils/store';

const USER_SLICE_NAME = 'user';
const initialState = {
  user: [],
  verification: {
    isSendPhoneMessage: '',
    isSendCodeMessage: '',
    codeErrorMessage: '',
    isSendPhone: false,
    isProofCode: false,
    isVerification: false,
    waitingVerification: false,
  },
  reset_url: '',
  reset_password: false,
  change_password:false,
  request_update_link: false,
  isUpdate: false,
  isRegister: null,
  error: false,
  errorRegistration: [],
  isFetching: false,
};

export const getUser = createAsyncThunk(
  `${USER_SLICE_NAME}/getUser`,
  async (params = {}, thunkAPI) => {
    try {
      const result = await restController.getUser();
      return result.data;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);
export const userForgotPassword = createAsyncThunk(
  `${USER_SLICE_NAME}/userForgotLogin`,
  async (payload, thunkAPI) => {
    try {
      const result = await restController.userForgotPassword(payload);
      return result;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);
export const userResetPassword = createAsyncThunk(
  `${USER_SLICE_NAME}/userResetPassword`,
  async (payload, thunkAPI) => {
    try {
      const result = await restController.userResetPassword(payload);
      return result;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);
export const userChangePassword = createAsyncThunk(
  `${USER_SLICE_NAME}/userChangePassword`,
  async (payload, thunkAPI) => {
    try {
      const result = await restController.userChangePassword(payload);
      return result;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);
export const updateProfile = createAsyncThunk(
  `${USER_SLICE_NAME}/updateProfile`,
  async (payload, thunkAPI) => {
    try {
      const result = await restController.updateProfile(payload);
      console.log('slice', result);
      return result.data;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);
export const userVerificationSendPhone = createAsyncThunk(
  `${USER_SLICE_NAME}/userVerificationSendPhone`,
  async (payload, thunkAPI) => {
    try {
      const result = await restController.userVerificationSendPhone(payload);
      const { data, status } = result;
      const formattedData = { data, status };
      return formattedData;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);

export const userVerificationSendCode = createAsyncThunk(
  `${USER_SLICE_NAME}/userVerificationSendCode`,
  async (payload, thunkAPI) => {
    try {
      const result = await restController.userVerificationSendCode(payload);
      const { data, status } = result;
      const formattedData = { data, status };
      return formattedData;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);

export const userVerification = createAsyncThunk(
  `${USER_SLICE_NAME}/userVerification`,
  async (payload, thunkAPI) => {
    try {
      const {data,status} = await restController.userVerification(payload);
      const result={data,status}
      return result;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);

export const userLogin = createAsyncThunk(
  `${USER_SLICE_NAME}/userLogin`,
  async (payload, thunkAPI) => {
    try {
      const result = await restController.userLogin(payload);

      const { data, status } = result;
      const formattedData = { data, status };
      return formattedData;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);
export const repeatSendCode = createAsyncThunk(
  `${USER_SLICE_NAME}/repeatSendCode`,
  async (payload, thunkAPI) => {
    try {
      const result = await restController.repeatSendCode(payload);
      console.log('result', result);
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);
export const userRegistration = createAsyncThunk(
  `${USER_SLICE_NAME}/userRegistration`,
  async (payload, thunkAPI) => {
    try {
      const result = await restController.userRegistration(payload);
      console.log('result', result);
      const { data, status } = result;
      const formattedData = { data, status };
      return formattedData;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);

const extraReducers = (builder) => {
  builder.addCase(getUser.pending, pendingReducer);
  builder.addCase(getUser.fulfilled, (state, action) => {
    state.user = action.payload;
    state.verification.isVerification = action.payload.is_verified;
    if (action.payload.is_verified) {
      state.verification.isSendPhone = true;
      state.verification.isProofCode = true;
    }
    state.verification.waitingVerification =
      action.payload.verification_request;
    state.isRegister = true;
    state.isFetching = false;
  });
  builder.addCase(getUser.rejected, rejectedReducer);


  builder.addCase(userChangePassword.pending, pendingReducer);
  builder.addCase(userChangePassword.fulfilled, (state, action) => {
    console.log('userChangePassword', action);
    if (action.payload&&action.payload.status==="success") {
      state.change_password = true;
      state.isFetching = false;
    } else {
      state.error = true;
    }
  });
  builder.addCase(userChangePassword.rejected, rejectedReducer);

  builder.addCase(userResetPassword.pending, pendingReducer);
  builder.addCase(userResetPassword.fulfilled, (state, action) => {
    console.log('userResetPassword', action);
    if (action.payload) {
      state.reset_password = true;
    } else {
      state.error = true;
    }
  });
  builder.addCase(userResetPassword.rejected, rejectedReducer);

  builder.addCase(userForgotPassword.pending, pendingReducer);
  builder.addCase(userForgotPassword.fulfilled, (state, action) => {
    console.log('userForgotPassword', action);
    if (action.payload.reset_url) {
      state.reset_url = action.payload.reset_url;
      state.request_update_link = true;
    } else {
      state.error = true;
    }
  });
  builder.addCase(userForgotPassword.rejected, rejectedReducer);

  builder.addCase(updateProfile.pending, pendingReducer);
  builder.addCase(updateProfile.fulfilled, (state, action) => {
    state.user = action.payload;
    state.isUpdate = true;
  });
  builder.addCase(updateProfile.rejected, rejectedReducer);

  builder.addCase(repeatSendCode.pending, pendingReducer);
  builder.addCase(repeatSendCode.fulfilled, (state, action) => {
    state.verification.isRepeatCode = true;
  });
  builder.addCase(repeatSendCode.rejected, rejectedReducer);

  builder.addCase(userRegistration.pending, pendingReducer);
  builder.addCase(userRegistration.fulfilled, (state, action) => {
    if (action.payload.status !== 201 && action.payload.status !== 200) {
      state.errorRegistration = action.payload.data;
      state.isRegister = false;
    }
    if (action.payload.status === 201 || action.payload.status === 200) {
      state.isFetching = false;
      state.user = action.payload;
      state.isRegister = true;
    }
  });

  builder.addCase(userVerification.rejected, rejectedReducer);
  builder.addCase(userVerification.pending, pendingReducer);
  builder.addCase(userVerification.fulfilled, (state, action) => {
    if (action.payload.status !== 201 && action.payload.status !== 200) {
      state.verification.isVerification = false;
    }
    if (action.payload.status === 201 || action.payload.status === 200) {
     
      state.verification.waitingVerification = true;
      state.isFetching = false;
    }
  });
  builder.addCase(userRegistration.rejected, rejectedReducer);

  builder.addCase(userVerificationSendPhone.pending, pendingReducer);
  builder.addCase(userVerificationSendPhone.fulfilled, (state, action) => {
    if (action.payload.status === 200 || action.payload.status === 201) {
      state.isFetching = false;
      state.verification.isSendPhone = true;
      localStorage.setItem('isSendPhone', 'true');
      state.verification.isSendPhoneMessage = action.payload.data.message;
    } else {
      state.verification.codeErrorMessage = action.payload.data.error;
    }
  });
  builder.addCase(userVerificationSendPhone.rejected, rejectedReducer);

  builder.addCase(userVerificationSendCode.pending, pendingReducer);
  builder.addCase(userVerificationSendCode.fulfilled, (state, action) => {
    console.log('userVerificationSendCode', action);
    state.isFetching = false;
    if (action.payload.status === 200) {
      state.verification.isProofCode = true;
      localStorage.setItem('isProofCode', 'true');
      state.verification.isSendCodeMessage = action.payload.data.message;
    } else {
      state.verification.codeErrorMessage = action.payload.data.error;
    }
  });
  builder.addCase(userVerificationSendCode.rejected, rejectedReducer);

  builder.addCase(userLogin.pending, pendingReducer);
  builder.addCase(userLogin.fulfilled, (state, action) => {
    console.log('action.payload', action.payload);
    if (action.payload.status !== 201 && action.payload.status !== 200) {
      state.errorRegistration = action.payload.data;
    } else {
      state.isFetching = false;
      state.user = action.payload;
      state.isRegister = true;
    }
  });
  builder.addCase(userLogin.rejected, rejectedReducer);
};

const usersSlice = createSlice({
  name: USER_SLICE_NAME,
  initialState,
  reducers: {
    setIsRegister: (state, action) => {
      state.isRegister = action.payload;
    },
    setIsError: (state, action) => {
      state.errorRegistration = [];
    },
    setIsErrorSendCode: (state, action) => {
      state.verification.codeErrorMessage = '';
    },
    setIsUpdate: (state, action) => {
      console.log('setIsUpdate');
      state.isUpdate = false;
    },
    changePhoneVerificationStatus: (state) => {
      state.verification.isSendPhone = true;
    },
    changeCodeVerificationStatus: (state) => {
      state.verification.isProofCode = true;
    },
    resetChangePassState:(state)=>{
      state.change_password=false
    }
  },
  extraReducers,
});
export const {
  changeCodeVerificationStatus,
  setIsRegister,
  setIsUpdate,
  setIsError,
  setIsErrorSendCode,
  changePhoneVerificationStatus,
  resetChangePassState
} = usersSlice.actions;
export default usersSlice.reducer;
