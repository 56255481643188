
import React, { useEffect} from 'react';
import { useSelector} from 'react-redux';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import PageTitle from '../../components/PageTitle/PageTitle';
import { Stack } from '@mui/material';
import { fonts } from '../../fonts.js';
import LoginForm from '../../components/Forms/LoginForm/LoginForm';
import { Link } from 'react-router-dom';
import history from '../../browserHistory';
import useWindowWidth from '../../utils/screenSize';
import { FormattedMessage } from 'react-intl';
const Login = () => {
  const { isRegister } = useSelector((state) => state.users);
 const fontSize=useWindowWidth()>=1200&&'72px';
  useEffect(() => {
    if(isRegister){
      history.replace('/profile')
    }
  }, [isRegister]);

  return (
    <>
      {' '}
      <Header />
      <Stack
        gap="32px"
        sx={{
          width: 'calc(100% - 40px)',
          alignItems: 'center',
          margin:'84px auto 0px',
          minHeight:'60vh'
        }}
      >
        <Stack gap="32px" sx={{ alignItems: 'center' }}>
          <PageTitle text={<FormattedMessage id={"log_in"}/>}  optionStyle={{fontSize:fontSize}}/>
          <Stack direction="row" gap="40px">
            <Link
              to="/login"
              style={{ ...fonts.fontInter24, color: 'rgba(67, 97, 238, 1)' }}
            >
             <FormattedMessage id={"log_in"}/>
            </Link>
            <Link
              to="/registration"
              style={{
                ...fonts.fontInter24,
                color: 'black',
                textDecoration: 'none',
              }}
            >
              <FormattedMessage id={"registration"}/>
            </Link>
          </Stack>
        </Stack>
        <LoginForm />
      </Stack>
      <Footer />
    </>
  );
};

export default Login;
