import React from 'react';
import { useSwiper } from 'swiper/react';
import styles from './SliderBtns.module.scss';
import arrRight from '../../images/arrowSliderRight.svg';
import arrLeft from '../../images/arrowSliderLeft.svg';
import PinkButton from '../Buttons/PinkButton';
import { Stack } from '@mui/material';

export const SwiperNavButtons = () => {
  const swiper = useSwiper();

  return (
    <Stack>
      <button
        className={styles.swiper_nav_btns_button1}
        onClick={() => swiper.slidePrev()}
      >
        <img src={arrLeft} alt="" />
      </button>
      <PinkButton
        text={'View all'}
        width={'190px'}
        height={'46px'}
        optionStyle={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          bottom: '3px',
          zIndex: '10',
        }}
      />
      <button
        className={styles.swiper_nav_btns_button2}
        onClick={() => swiper.slideNext()}
      >
        <img src={arrRight} alt="" />
      </button>
    </Stack>
  );
};
