import React from 'react';
import { Stack, Box } from '@mui/material';
import { fonts } from '../../fonts';
import { FormattedMessage } from 'react-intl';

import JP from '../../images/flags/JP.png';
import GS from '../../images/flags/GS.png';
import UK from '../../images/flags/UK.png';
import USA from '../../images/flags/USA.png';
import CA from '../../images/flags/CA.png';
import AU from '../../images/flags/AU.png';
import NZ from '../../images/flags/NZ.png';
import IS from '../../images/flags/IS.png';
import CH from '../../images/flags/CH.png';
import NO from '../../images/flags/NO.png';
import UA from '../../images/flags/UA.png';
const flagsPath = [
  {
    path: JP,
    name: 'JP',
  },
  {
    path: GS,
    name: 'GS',
  },
  {
    path: UK,
    name: 'UK',
  },
  {
    path: USA,
    name: 'USA',
  },
  {
    path: CA,
    name: 'CA',
  },
  {
    path: AU,
    name: 'AU',
  },
  {
    path: NZ,
    name: 'NZ',
  },
  {
    path: IS,
    name: 'IS',
  },
  {
    path: CH,
    name: 'CH',
  },
  {
    path: NO,
    name: 'NO',
  },
  {
    path: UA,
    name: 'UA',
  },
];
const FlagsArray = () => {
  return (
    <Stack direction="row" gap="12px" sx={{flexWrap:'wrap',alignItems:'center'}}>
      <Box sx={{ ...fonts.fontInter18_600,width:'70px' }}><FormattedMessage id="for_tourists_from"/></Box>
      {flagsPath.map((path, i) => (
        <Stack sx={{alignItems:'center'}} key={i}>
          <div><img src={path.path} alt="" width="80px" height="60px" style={{borderRadius:'10px'}}/></div>
          <div>{path.name}</div>
        </Stack>
      ))}
    </Stack>
  );
};

export default FlagsArray;
