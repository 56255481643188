import React from 'react';
import PageTitle from '../PageTitle/PageTitle';
import { Stack, Box } from '@mui/material';
import { sectionsStyle } from '../../styles';
import { fonts } from '../../fonts';
import useWindowWidth from '../../utils/screenSize';
import { FormattedMessage } from 'react-intl';

const ProductCharacteristics = ({ characteristics }) => {
  const styleBox = {
    gap: '4px',
  };

  return (
    <>
      {useWindowWidth() >= 1200 ? (
        <Stack sx={{ width: '100%', alignItems: 'center' }}>
          <Stack sx={{ width: '100%', maxWidth: '1600px' }} gap="40px">
            <PageTitle
              text={<FormattedMessage id={"under_hood"}/>}
              optionStyle={{ fontSize: '70px' }}
            />

            <Stack direction="row" width="100%">
              <Stack gap="30px" direction="row" width="67%">
                <Stack gap="40px" sx={{ width: '50%' }}>
                  <Stack sx={{ ...styleBox }}>
                    <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"video"}/></Box>
                    <Box sx={{ ...fonts.fontInter18_400,lineHeight:'24px' }}>
                      {characteristics?.video}
                    </Box>
                  </Stack>
                  <Stack sx={{ ...styleBox }}>
                    <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"gimbal"}/></Box>
                    <Box sx={{ ...fonts.fontInter18_400,lineHeight:'24px' }}>
                      {characteristics?.gimbal}
                    </Box>
                  </Stack>
                  <Stack sx={{ ...styleBox }}>
                    <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"diaphragm"}/></Box>
                    <Box sx={{ ...fonts.fontInter18_400 ,lineHeight:'24px'}}>
                      {characteristics?.diaphragm}
                    </Box>
                  </Stack>
                  <Stack sx={{ ...styleBox }}>
                    <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"flight_ceiling"}/></Box>
                    <Box sx={{ ...fonts.fontInter18_400 ,lineHeight:'24px'}}>
                      {characteristics?.flight_height} m
                    </Box>
                  </Stack>
                  <Stack sx={{ ...styleBox }}>
                    <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"remote_control"}/></Box>
                    <Box sx={{ ...fonts.fontInter18_400,lineHeight:'24px' }}>
                      {characteristics?.remote_control}
                    </Box>
                  </Stack>
                  <Stack sx={{ ...styleBox }}>
                    <Box sx={{ ...fonts.fontInter24 }}>
                      <FormattedMessage id={"speed"}/>
                    </Box>
                    <Box sx={{ ...fonts.fontInter18_400 ,lineHeight:'24px'}}>
                      {characteristics?.max_descent}
                    </Box>
                  </Stack>
                  <Stack sx={{ ...styleBox }}>
                    <Box sx={{ ...fonts.fontInter24 }}>
                    <FormattedMessage id={"support_images"}/>
                    </Box>
                    <Box sx={{ ...fonts.fontInter18_400,lineHeight:'24px' }}>
                      {characteristics?.camera}
                    </Box>
                  </Stack>
                </Stack>
                <Stack gap="40px" width="50%">
                  <Stack sx={{ ...styleBox }}>
                    <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"camera"}/></Box>
                    <Box sx={{ ...fonts.fontInter18_400 }}>
                      {characteristics?.camera}
                    </Box>
                  </Stack>
                  <Stack sx={{ ...styleBox }}>
                    <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"storage"}/></Box>
                    <Box sx={{ ...fonts.fontInter18_400 }}>
                      {characteristics?.storage}
                    </Box>
                  </Stack>
                  <Stack sx={{ ...styleBox }}>
                    <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"performance"}/></Box>
                    <Box sx={{ ...fonts.fontInter18_400 }}>
                      {characteristics?.performance}
                    </Box>
                  </Stack>
                  <Stack sx={{ ...styleBox }}>
                    <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"wind_weather"}/></Box>
                    <Box sx={{ ...fonts.fontInter18_400 }}>
                      {characteristics?.wind_weather}
                    </Box>
                  </Stack>
                  <Stack sx={{ ...styleBox }}>
                    <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"flight_time_UPPERCASE"}/></Box>
                    <Box sx={{ ...fonts.fontInter18_400 }}>
                      {characteristics?.flight_time} minutes
                    </Box>
                  </Stack>
                  <Stack sx={{ ...styleBox }}>
                    <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"max_climb"}/></Box>
                    <Box sx={{ ...fonts.fontInter18_400 }}>
                      {characteristics?.max_climb} minutes
                    </Box>
                  </Stack>
                  <Stack sx={{ ...styleBox }}>
                    <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"weight"}/></Box>
                    <Box sx={{ ...fonts.fontInter18_400 }}>
                      {characteristics?.weight} gram
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction="column"
                sx={{ justifyContent: 'space-between', flexWrap: 'wrap',width:'34%' }}
              >
                {characteristics?.files_specifications.slice(0, 2).map((el, i) => (
                  
                  <img
                    src={characteristics?.files_specifications[i]}
                    alt=""
                    width="100%"
                    style={{maxWidth:'381px', borderRadius:"5px"}}
                   
                    key={i}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack sx={{ ...sectionsStyle }}>
          <PageTitle text={<FormattedMessage id={"under_hood"}/>} />
          <Stack gap="20px">
            <Stack sx={{ ...styleBox }}>
              <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"video"}/></Box>
              <Box sx={{ ...fonts.fontInter18_400 }}>
                {characteristics?.video}
              </Box>
            </Stack>
            <Stack sx={{ ...styleBox }}>
              <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"gimbal"}/></Box>
              <Box sx={{ ...fonts.fontInter18_400 }}>
                {characteristics?.gimbal}
              </Box>
            </Stack>
            <Stack sx={{ ...styleBox }}>
              <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"diaphragm"}/></Box>
              <Box sx={{ ...fonts.fontInter18_400 }}>
                {characteristics?.diaphragm}
              </Box>
            </Stack>
            <Stack sx={{ ...styleBox }}>
              <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"flight_ceiling"}/></Box>
              <Box sx={{ ...fonts.fontInter18_400 }}>
                {characteristics?.flight_height} m
              </Box>
            </Stack>
            <Stack sx={{ ...styleBox }}>
              <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"remote_control"}/></Box>
              <Box sx={{ ...fonts.fontInter18_400 }}>
                {characteristics?.remote_control}
              </Box>
            </Stack>
            <Stack sx={{ ...styleBox }}>
              <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"speed"}/></Box>
              <Box sx={{ ...fonts.fontInter18_400 }}>
                {characteristics?.max_descent}
              </Box>
            </Stack>
            <Stack sx={{ ...styleBox }}>
              <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"support_images"}/></Box>
              <Box sx={{ ...fonts.fontInter18_400 }}>
                {characteristics?.camera}
              </Box>
            </Stack>
            <Stack sx={{ ...styleBox }}>
              <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"camera"}/></Box>
              <Box sx={{ ...fonts.fontInter18_400 }}>
                {characteristics?.camera}
              </Box>
            </Stack>
            <Stack sx={{ ...styleBox }}>
              <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"storage"}/></Box>
              <Box sx={{ ...fonts.fontInter18_400 }}>
                {characteristics?.storage}
              </Box>
            </Stack>
            <Stack sx={{ ...styleBox }}>
              <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"performance"}/></Box>
              <Box sx={{ ...fonts.fontInter18_400 }}>
                {characteristics?.performance}
              </Box>
            </Stack>
            <Stack sx={{ ...styleBox }}>
              <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"wind_weather"}/></Box>
              <Box sx={{ ...fonts.fontInter18_400 }}>
                {characteristics?.wind_weather}
              </Box>
            </Stack>
            <Stack sx={{ ...styleBox }}>
              <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"flight_time_UPPERCASE"}/></Box>
              <Box sx={{ ...fonts.fontInter18_400 }}>
                {characteristics?.flight_time} minutes
              </Box>
            </Stack>
            <Stack sx={{ ...styleBox }}>
              <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"max_climb"}/></Box>
              <Box sx={{ ...fonts.fontInter18_400 }}>
                {characteristics?.max_climb} minutes
              </Box>
            </Stack>
            <Stack sx={{ ...styleBox }}>
              <Box sx={{ ...fonts.fontInter24 }}><FormattedMessage id={"weight"}/></Box>
              <Box sx={{ ...fonts.fontInter18_400 }}>
                {characteristics?.weight} gram
              </Box>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            sx={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
          >
            {characteristics?.files_specifications.map((el, i) => (
              <img
              key={i}
                src={characteristics?.files_specifications[i]}
                alt=""
                width="165px"
                style={{borderRadius:"5px"}}
                
              />
            ))}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default ProductCharacteristics;
