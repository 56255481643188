import React from 'react';
import { Stack, Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { sectionsStyle } from '../../styles';
import PageTitle from '../PageTitle/PageTitle';
import ProductForm from '../Forms/ProductForm/ProductForm';
import useWindowWidth from '../../utils/screenSize';
import { DeliveryIcon } from '../Icons/Icons.js';
import { SwiperNavButtonsProduct } from './SliderBtns';
import { primary } from '../../colors';
import { fonts } from '../../fonts';
import { FormattedMessage } from 'react-intl';
import visa from '../../images/cards/visa.png';
import mastercard from '../../images/cards/mastercard-icon.png';
import maestro from '../../images/cards/maestro.png';
import amex from '../../images/cards/american-express.svg';

const imgStyle = {
  width: '103px',
  borderRadius: '5px',
  border: '1px solid #4361EE4D',
  padding: '5px',
};
const imgStyleHD = {
  width: '100%',
  maxWidth: '794px',
  height: '99%',
  maxHeight: '794px',
  borderRadius: '5px',
  border: '1px solid #4361EE4D',
  // padding: '5px',
};
const imgDescriptionStyleHD = {
  width: '100%',
  maxWidth: '243px',
  height: '100%',
  maxHeight: '243px',
  borderRadius: '5px',
  border: '1px solid #4361EE4D',
  padding: '5px',
};
const mainImgStyle = {
  width: 'calc(100% - 10px)',
  height: 'calc(100% - 10px)',
  padding: '5px',
  borderRadius: '5px',
  border: '1px solid #4361EE4D',
};
const infoBoxStyle = {
  backgroundColor: primary.blue,
  maxWidth: '207px',
  color: '#fff',
  padding: '24px',
  borderRadius: '10px',
};
const ProductTitle = ({ characteristics ,memoryCard}) => {
  return (
    <>
      {useWindowWidth() >= 1200 ? (
        <Stack
          direction="row"
          gap="40px"
          sx={{ width: '100%', maxWidth: '1600px' }}
        >
          <Stack gap="20px" width="50%">
            <Swiper
              style={{
                width: '100%',
              }}
              loop={true}
              modules={[Navigation]}
              spaceBetween={30}
              slidesPerView={1}
            >
              {characteristics?.files_specifications.map((el, i) => (
                <SwiperSlide key={i} style={{ width: '100%' }}>
                  <img
                    src={characteristics?.files_specifications[i]}
                    alt=""
                    style={{ ...imgStyleHD }}
                  />
                </SwiperSlide>
              ))}
              <SwiperNavButtonsProduct />
            </Swiper>

            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              {characteristics?.files_specifications.map((el, i) => (
                <img
                  key={i}
                  src={characteristics?.files_specifications[i]}
                  alt=""
                  style={{ ...imgDescriptionStyleHD }}
                />
              ))}
            </Stack>
            <Stack direction="row" gap="18px">
              <Stack
                gap="21px"
                sx={{
                  ...infoBoxStyle,
                }}
              >
                <DeliveryIcon fill={'#fff'} />
                <Box sx={{ ...fonts.fontInter24, lineHeight: '36px' }}>
                  <FormattedMessage id={'delivery_return'} />
                </Box>
                <Box sx={{ ...fonts.fontInter18_400, lineHeight: '27px' }}>
                  <FormattedMessage id={'delivery_price'} />
                </Box>
              </Stack>
              <Stack
                gap="21px"
                sx={{
                  ...infoBoxStyle,
                }}
              >
                <DeliveryIcon fill={'#fff'} />
                <Box sx={{ ...fonts.fontInter24, lineHeight: '36px' }}>
                  <FormattedMessage id={'easy_payment'} />
                </Box>
                <Box sx={{ ...fonts.fontInter18_400, lineHeight: '27px' }}>
                  <FormattedMessage id={'no_deposit'} />
                </Box>
                <Stack direction="row" gap="4px" sx={{backgroundColor:'#fff',padding:'4px',borderRadius:'4px',alignItems:'center'}}>
                  {' '}
                  <img src={visa} alt="" style={{ width: '48px',height:"32px" }} />
                  <img src={mastercard} alt="" style={{ width: '48px',height:"32px" }} />
                  <img src={maestro} alt="" style={{ width: '48px',height:"24px" }} />
                  <img src={amex} alt="" style={{ width: '48px',height:"32px" }} />
                </Stack>
              </Stack>
              <Stack
                gap="21px"
                sx={{
                  ...infoBoxStyle,
                }}
              >
                <DeliveryIcon fill={'#fff'} />
                <Box sx={{ ...fonts.fontInter24, lineHeight: '36px' }}>
                  <FormattedMessage id={'tech_in_top_condition'} />
                </Box>
                <Box sx={{ ...fonts.fontInter18_400, lineHeight: '27px' }}>
                  <FormattedMessage id={'all_checked'} />
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <PageTitle
              text={characteristics?.title}
              optionStyle={{ fontSize: '70px' }}
            />
            <ProductForm characteristics={characteristics} memoryCard={memoryCard}/>
          </Stack>
        </Stack>
      ) : (
        <Stack sx={{ ...sectionsStyle }}>
          <PageTitle text={characteristics?.title} />
          <Stack gap="11px">
            <img
              src={characteristics?.files[0]}
              alt=""
              style={{ ...mainImgStyle }}
            />
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              {characteristics?.files_specifications.map((el, i) => (
                <img
                  key={i}
                  src={characteristics?.files_specifications[i]}
                  alt=""
                  style={{ ...imgStyle }}
                />
              ))}
            </Stack>
          </Stack>
          <ProductForm characteristics={characteristics} memoryCard={memoryCard}/>
        </Stack>
      )}
    </>
  );
};

export default ProductTitle;
