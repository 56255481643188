import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as restController from '../../api/rest/restController';
import { pendingReducer, rejectedReducer } from '../../utils/store';

const REPAIR_SLICE_NAME = 'repairs';
const initialState = {
  repairs: [],
  error: null,
  isFetching: false,
  isSaveRepairRequest: false,
  isSaveRepairConsultationRequest: false,
  errorConsultation: false,
  errorOrderRepair: false,
};

export const getRepairsList = createAsyncThunk(
  `${REPAIR_SLICE_NAME}/getRepairsList`,
  async (params = {}, thunkAPI) => {
    try {
      const data = await restController.getRepairsList();
      return data;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);
export const repairConsultation = createAsyncThunk(
  `${REPAIR_SLICE_NAME}/repairConsultation`,
  async (payload, thunkAPI) => {
    try {
      const { data, status } = await restController.repairConsultation(payload);

      return { data, status };
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);
export const submitRepair = createAsyncThunk(
  `${REPAIR_SLICE_NAME}/submitRepair`,
  async (payload, thunkAPI) => {
    try {
      const { data, status } = await restController.submitRepair(payload);
      return { data, status };
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);

const extraReducers = (builder) => {
  builder.addCase(getRepairsList.pending, pendingReducer);
  builder.addCase(getRepairsList.fulfilled, (state, action) => {
    state.isFetching = false;
    state.repairs = action.payload;
  });
  builder.addCase(getRepairsList.rejected, rejectedReducer);

  builder.addCase(submitRepair.pending, pendingReducer);
  builder.addCase(submitRepair.fulfilled, (state, action) => {
    console.log(action);
    if (action.payload.status !== 200 && action.payload.status !== 201) {
      state.errorOrderRepair = true;
    } else {
      state.isSaveRepairRequest = true;
    }

    state.isFetching = false;
    state.error = null;
  });

  builder.addCase(submitRepair.rejected, rejectedReducer);

  builder.addCase(repairConsultation.pending, pendingReducer);
  builder.addCase(repairConsultation.fulfilled, (state, action) => {
    console.log(action);
    if (action.payload.status !== 200 && action.payload.status !== 201) {
      state.errorConsultation = true;
    } else {
      state.isSaveRepairConsultationRequest = true;
    }
  });
  builder.addCase(repairConsultation.rejected, rejectedReducer);
};
const repairsSlice = createSlice({
  name: REPAIR_SLICE_NAME,
  initialState,
  reducers: {
    cleanIsSaveRepairRequest: (state, action) => {
      state.isSaveRepairRequest = false;
    },

    cleanIsSaveRepairConsultationRequest: (state, action) => {
      state.isSaveRepairConsultationRequest = false;
    },
    cleanErrorConsultation: (state, action) => {
      state.errorConsultation = false;
    },
    cleanErrorOrderRepair: (state, action) => {
      state.errorOrderRepair = false;
    },
  },
  extraReducers,
});
export const {
  cleanIsSaveRepairRequest,
  cleanIsSaveRepairConsultationRequest,
  cleanErrorConsultation,
  cleanErrorOrderRepair,
} = repairsSlice.actions;
export default repairsSlice.reducer;
