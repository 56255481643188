import React from 'react';
import { Stack } from '@mui/material';
import RepairService from './RepairService';
import useWindowWidth from '../../utils/screenSize';
import PageTitle from '../PageTitle/PageTitle';
import { FormattedMessage } from 'react-intl';

const serviceKeys = [
  'full_diagnostics',
  'replacement_body_parts',
  'replacement_chassis_mechanisms',
  'recovery_difficult_falls',
  'replacement_boards_sensors',
  'recovery_after_moisture',
  'updating_software',
  'remote_control_repair',
];
const RepairServices = () => {
  const styles = {
    backgroundColor: '#4361EE33',
    width: '100%',
    alignItems: 'center',
    padding: '24px 0px 24px 0px',
    gap: '20px',
  };
  return (
    <>
      {useWindowWidth() >= 1200 ? (
        <Stack sx={{width:'100%',alignItems:'center',backgroundColor: '#4361EE33',padding:'40px 0px'}}>
          <Stack sx={{width:'calc(100% - 40px)',maxWidth:'1600px',gap:'40px',  }}>
            <PageTitle text={<FormattedMessage id={'our_repair_services'}/>} optionStyle={{fontSize:'70px'}}/>
            <Stack direction='row' sx={{gap:'20px',flexWrap:'wrap',alignItems: 'center',}}>
            {serviceKeys.map((el, i) => (
            <RepairService
              key={i}
              title={<FormattedMessage id={el}/>}
              description={<FormattedMessage id={"calculated_individually"}/>}
            />
          ))}
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack sx={{ ...styles }}>
          {serviceKeys.map((el, i) => (
            <RepairService
              key={i}
              title={<FormattedMessage id={el} />}
              description={<FormattedMessage id={"calculated_individually"}/>}
            />
          ))}
        </Stack>
      )}
    </>
  );
};

export default RepairServices;
