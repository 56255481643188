import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';
import { Stack } from '@mui/material';
import { SwiperNavButtons4 } from './SliderBtns4';
import PageTitle from '../PageTitle/PageTitle';
import { sectionsStyle } from '../../styles';
import { FormattedMessage } from 'react-intl';
import styles from './SliderBtns4.module.scss';
import PinkButton from '../Buttons/PinkButton';

const Slider4 = () => {
  return (
    <>
      <Stack sx={{ alignItems: 'center',width:'100%' }}>
        <Stack
          sx={{
            ...sectionsStyle,
            width: 'calc(100% - 40px',
            maxWidth: '1600px',
            alignItems: 'center',
          }}
        >
          <PageTitle
            text={<FormattedMessage id={"video_by_our_drones"}/>}
            optionStyle={{ fontSize: '70px',width:'100%' }}
          />
          <Swiper
            style={{
              width: '1050px',
              height: '400px',
              paddingTop: '50px',
              paddingBottom: '50px',
            }}
            loop={true}
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={2}
            coverflowEffect={{
              rotate: 10,
              stretch: 0,
              depth: 200,
              modifier: 1,
              slideShadows: false,
            }}
            modules={[EffectCoverflow, Navigation]}
          >
            <SwiperSlide className={styles.swiperSlide}>
              <img className={styles.swiperSlideImg} src="https://swiperjs.com/demos/images/nature-1.jpg" />
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
              <img
                className={styles.swiperSlideImg}
                src="https://swiperjs.com/demos/images/nature-2.jpg"
              />
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
              <img
                className={styles.swiperSlideImg}
                src="https://swiperjs.com/demos/images/nature-3.jpg"
               
              />
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
              <img
                className={styles.swiperSlideImg}
                src="https://swiperjs.com/demos/images/nature-4.jpg"
               
              />
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
              <img
                className={styles.swiperSlideImg}
                src="https://swiperjs.com/demos/images/nature-7.jpg"
            
              />
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlide}>
              <img
                className={styles.swiperSlideImg}
                src="https://swiperjs.com/demos/images/nature-8.jpg"
           
              />
            </SwiperSlide>
            <SwiperNavButtons4 />
          </Swiper>
          <PinkButton
            text={<FormattedMessage id={"view_all"}/>}
            width={'311px'}
            height={'72px'}
            optionStyle={{ margin: 'auto' }}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default Slider4;
