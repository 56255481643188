import { combineReducers } from '@reduxjs/toolkit';
import usersReducer from './slices/usersSlice';
import repairsReducer from './slices/repairsSlice';
import productsReducer from './slices/productsSlice';
import reviewReducer from './slices/reviews_faqs';
import customerOrdersReducer from './slices/customerOrdersSlice';
import paymentReducer from './slices/paymentSlice';
import languagesSlice from './slices/language';



const rootReducer = combineReducers({
  users: usersReducer,
  repairs:repairsReducer,
  products:productsReducer,
  reviews:reviewReducer,
  orders:customerOrdersReducer,
  payment:paymentReducer,
  languages:languagesSlice
});

export default rootReducer;