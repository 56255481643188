import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as restController from '../../api/rest/restController';
import { pendingReducer, rejectedReducer } from '../../utils/store';

const ORDER_SLICE_NAME = 'orders';

const initialState = {
  orders: [],
  order: [],
  error: null,
  isFetching: false,
  status: null,
  orderError:[]
};

export const getOrderList = createAsyncThunk(
  `${ORDER_SLICE_NAME}/getOrderList`,
  async (params = {}, thunkAPI) => {
    try {
      const data = await restController.getOrderList();

      return data;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);
export const orderCreate = createAsyncThunk(
  `${ORDER_SLICE_NAME}/orderCreate`,
  async (payload, thunkAPI) => {
    try {
     const {data,status} = await restController.orderCreate(payload);
       const result={data,status}
      console.log(result);
      return result;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);
const extraReducers = (builder) => {
  builder.addCase(getOrderList.pending, pendingReducer);
  builder.addCase(getOrderList.fulfilled, (state, action) => {
    state.isFetching = false;
    state.orders = action.payload;
  });
  builder.addCase(getOrderList.rejected, rejectedReducer);

  builder.addCase(orderCreate.pending, pendingReducer);
  builder.addCase(orderCreate.fulfilled, (state, action) => {
    if (action.payload.status !== 200 && action.payload.status !== 201) {
      state.orderError = action.payload.data;
    } else {
     
      state.status = action.payload.status;
      state.order = action.payload.data.items;
      localStorage.setItem('order_id', JSON.stringify(action.payload.data.order_id))
      state.isFetching = false;
    }
  });
  builder.addCase(orderCreate.rejected, rejectedReducer);
};
const ordersSlice = createSlice({
  name: ORDER_SLICE_NAME,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = null;
    },
    resetError: (state,action) => {
      state.orderError = action.payload;
    },
  },
  extraReducers,
});
export const { resetStatus,resetError } = ordersSlice.actions;
export default ordersSlice.reducer;
