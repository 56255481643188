import React from 'react';
import FlagsArray from '../FlagsArray/FlagsArray';
import { Stack, Box } from '@mui/material';
import { sectionsStyle } from '../../styles';
import EU_flag from '../../images/EU_flag.png';
import { fonts } from '../../fonts';
import useWindowWidth from '../../utils/screenSize';
import { FormattedMessage } from 'react-intl';
const ProductFlagSection = () => {
  return (
    <>
      {useWindowWidth() >= 1200 ? (
        <Stack direction="row" gap="159px" sx={{width:'100%',maxWidth:'1600px' }}>
          <Stack direction="row">
            <Box
              component="p"
              sx={{ ...fonts.fontInter18_600, lineHeight: '27px',maxWidth:'158px' }}
            >
              <FormattedMessage id={"rent_is_possible"}/>
            </Box>
            <img src={EU_flag} alt="" />
          </Stack>
          <FlagsArray />
        </Stack>
      ) : (
        <Stack sx={{ ...sectionsStyle }}>
          <Stack direction="row">
            <Box
              component="p"
              sx={{ ...fonts.fontInter18_600, lineHeight: '27px' }}
            >
               <FormattedMessage id={"rent_is_possible"}/>
            </Box>
            <img src={EU_flag} alt="" />
          </Stack>
          <FlagsArray />
        </Stack>
      )}
    </>
  );
};

export default ProductFlagSection;
