
// const env = process.env.NODE_ENV || 'development';
const serverIP = 'localhost';
const serverPort = 3000;

const CONSTANTS = {
  BASE_BLOGS_URL:'https://wordpress-1277687-4657800.cloudwaysapps.com',
  BLOGS_URL:'https://wordpress-1277687-4657800.cloudwaysapps.com/blog-list/',
  screenSize:window.innerWidth,
  BASE_URL: `https://drone2rent.devolux.nl`,
  publicURL:`http://${serverIP}:${serverPort}/public/images/`,
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  PAYMENT_KEY:'pk_test_51OjgJpFhyWqh3iRjcCBidbbgb4OZi9ir4jiIYodvEv36vLrQR8qivOsa2OIP6Mdr99OD9wVAjURMcDwTvBthMHS800uGOAJQg6',
  WP_POST_1:33,
  WP_POST_2:10,

};


export default CONSTANTS;
