import React from 'react';
import { Stack, Box } from '@mui/material';
import { primary } from '../../colors';
import { fonts } from '../../fonts';
import { FormattedMessage } from 'react-intl';
const VerificationSteps = ({ isSelect, setSelect, steps }) => {
  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
      <Box
        sx={{
          color: isSelect === 'step1' ? primary.blue : '#000',
          ...fonts.fontInter16_600,
          textDecoration: isSelect === 'step1' ? 'underline' : 'none',
          cursor:'pointer'
        }}
        onClick={() => setSelect(steps.step1)}
      >
        <FormattedMessage id={"step"}/> 1
      </Box>
      <Box
        sx={{
          color: isSelect === 'step2' ? primary.blue : '#000',
          ...fonts.fontInter16_600,
          textDecoration: isSelect === 'step2' ? 'underline' : 'none',
          cursor:'pointer'

        }}
        onClick={() => setSelect(steps.step2)}
      >
        <FormattedMessage id={"step"}/> 2
      </Box>
      <Box
        sx={{
          color: isSelect === 'step3' ? primary.blue : '#000',
          ...fonts.fontInter16_600,
          textDecoration: isSelect === 'step3' ? 'underline' : 'none',
          cursor:'pointer'

        }}
        onClick={() => setSelect(steps.step3)}
      >
        <FormattedMessage id={"step"}/> 3
      </Box>
      <Box
        sx={{
          color: isSelect === 'step4' ? primary.blue : '#000',
          ...fonts.fontInter16_600,
          textDecoration: isSelect === 'step4' ? 'underline' : 'none',
          cursor:'pointer'

        }}
        onClick={() => setSelect(steps.step4)}
      >
        <FormattedMessage id={"step"}/> 4
      </Box>
      <Box
        sx={{
          color: isSelect === 'step5' ? primary.blue : '#000',
          ...fonts.fontInter16_600,
          textDecoration: isSelect === 'step5' ? 'underline' : 'none',
          cursor:'pointer'

        }}
        onClick={() => setSelect(steps.step5)}
      >
        <FormattedMessage id={"step"}/> 5
      </Box>
    </Stack>
  );
};

export default VerificationSteps;
