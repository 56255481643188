import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from '../../../store/slices/usersSlice.js';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { userLoginSchema } from '../../../utils/validationSchems.js';
import styles from './LoginForm.module.scss';
import PinkButton from '../../Buttons/PinkButton';
import { Box, Link } from '@mui/material';
import { fonts } from '../../../fonts.js';
import useWindowWidth from '../../../utils/screenSize';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { setIsError } from '../../../store/slices/usersSlice.js';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import stylesSweetAlert from '../../../custom-sweetalert.module.scss';

const initialValues = {
  email: '',
  password: '',
};
const customStyleSweetAlert = {
  container: stylesSweetAlert.custom_swal_container,
  title: stylesSweetAlert.custom_swal_title,
  content: stylesSweetAlert.custom_swal_content,
  confirmButton: stylesSweetAlert.custom_swal_confirm_button,
  popup: stylesSweetAlert.swal2_popup,
  icon: stylesSweetAlert.custom_swal_icon,
};
const LoginForm = ({ intl }) => {
  const widthButton = useWindowWidth() >= 1200 ? '472px' : '168px';
  const heightButton = useWindowWidth() >= 1200 ? '72px' : '46px';
  const dispatch = useDispatch();
  const { errorRegistration } = useSelector((state) => state.users);
  const onSubmit = (values, { resetForm, setSubmitting }) => {
    dispatch(userLogin(values));
    resetForm();
    setSubmitting(false);
  };
  useEffect(() => {
    if (errorRegistration.detail) {
      console.log(errorRegistration.detail);
      Swal.fire({
        title:intl.formatMessage({ id: 'warning' }),
        text:intl.formatMessage({ id: 'you_email_and_password' }),
        icon: 'warning',
        confirmButtonText: 'Ok',
        customClass: customStyleSweetAlert,
      });
      dispatch(setIsError());
    }
  }, [errorRegistration, dispatch]);
  return (
    <div className={styles.form}>
      <Formik
        initialValues={initialValues}
        validationSchema={userLoginSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form style={{ width: '100%' }}>
            <div>
              <Field
                type="email"
                id="email"
                name="email"
                placeholder={intl.formatMessage({ id: 'email' })}
                style={{borderColor:'rgba(67, 97, 238, 0.2)'}}
              />
              <ErrorMessage name="email">
                {(msg) => (
                  <div className={styles.error}>
                    <FormattedMessage id={msg} />
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div>
              <Field
                type="password"
                id="password"
                name="password"
                placeholder={intl.formatMessage({ id: 'password' })}
                style={{borderColor:'rgba(67, 97, 238, 0.2)'}}
              />
              <ErrorMessage name="password">
                {(msg) => (
                  <div className={styles.error}>
                    <FormattedMessage id={msg} />
                  </div>
                )}
              </ErrorMessage>
            </div>
            <Box
              component="p"
              sx={{
                margin: '10px',
                ...fonts.fontInter16,
                textAlign: 'center',
                lineHeight: '2',
              }}
            >
              <FormattedMessage id={'forgot_pass'} /> &nbsp;
              <Link href="/forgot-password">
                <FormattedMessage id={'will_restore'} />
              </Link>
            </Box>
            <PinkButton
              type="submit"
              text={'Log In'}
              width={widthButton}
              height={heightButton}
              optionStyle={{ margin: '10px' }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default injectIntl(LoginForm);
