import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import useWindowWidth from '../../utils/screenSize';
import { FormattedMessage } from 'react-intl';
export default function BreadcrumbsCustom(props) {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/">
     <FormattedMessage id="home" />
    </Link>,
    <Typography key="2" color="text.primary">
      {props.pageName}
    </Typography>,
  ];

  return (
    <>
      {useWindowWidth() >= 1200 ? (
        <Stack spacing={2} sx={{width:'100%'}}>
          <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ maxWidth: '1600px',width:'calc(100% - 40px)' }}>
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
      ) : (
        <Stack spacing={2} sx={{ maxWidth: '1600px' }}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
      )}
    </>
  );
}
