import React from 'react';
import { fonts } from '../../fonts';
import { primary } from '../../colors';
import { Box, Stack } from '@mui/material';
import { sectionsStyle } from '../../styles';
import picture from '../../images/repair_page_title.png';
import useWindowWidth from '../../utils/screenSize';
import BreadcrumbsCustom from '../Breadcrumbs/Breadcrumbs';
import { FormattedMessage } from 'react-intl';
import  LazyLoad  from 'react-lazyload';
const TitleRepairPage = () => {
  const spanStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    backgroundColor: primary.blue,
    border: `2px solid ${primary.blue}`,
    width: '155px',
    height: '61px',
    borderRadius: '100px',
  };
  const spanStyleHD = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    backgroundColor: primary.blue,
    border: `2px solid ${primary.blue}`,
    width: '352px',
    height: '137px',
    borderRadius: '100px',
  };
  const bgStyle = {
    backgroundImage: `url(${picture})`,
    backgroundSize: '75%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 60px',
  };
 
  return (
    <>
      {useWindowWidth() >= 1200 ? (
        <LazyLoad offset={100}>
        <Stack sx={{ width: '100%', alignItems: 'center' }}>
          
          
            <Stack
              sx={{
                width: 'calc(100% - 40px)',
                maxWidth: '1600px',
                alignItems: 'center',
                ...bgStyle,
                height: '797px',
            
            
              }}
            >
                <BreadcrumbsCustom pageName={<FormattedMessage id="drone_repair"/>}/>
              <Box
                sx={{
                  ...fonts.fontPoppins80,
                  lineHeight: '80px',
                  textAlign: 'center',
                  position: 'relative',
                }}
              >
                <FormattedMessage id="specialized"/> &nbsp;
                <span
                  style={{
                    ...spanStyleHD,
                  }}
                >
                  <FormattedMessage id="drone"/>
                </span>
                &nbsp; <FormattedMessage id="repair_service"/>
                <Box
                  sx={{
                    ...fonts.fontInter24,
                    position: 'absolute',
                    right: '10%',
                  }}
                >
                  <FormattedMessage id="your_birds_sky"/>
                </Box>
              </Box>
            </Stack>
        </Stack>
        </LazyLoad>

      ) : (
        <LazyLoad offset={100}>
          <Stack sx={{ ...sectionsStyle }}>
            <Box
              component="p"
              sx={{
                ...fonts.fontPoppins32,
                lineHeight: '48px',
                textAlign: 'center',
              }}
            >
              <FormattedMessage id="specialized"/> &nbsp;
              <span
                style={{
                  ...spanStyle,
                }}
              >
                <FormattedMessage id="drone"/>
              </span>
              &nbsp; <FormattedMessage id="repair_service"/>
            </Box>
            <Box
              component="p"
              sx={{ textAlign: 'center', ...fonts.fontInter18_400 }}
            >
              <FormattedMessage id="your_birds_sky"/>
            </Box>
            <img src={picture} alt="oops" />
          </Stack>
        </LazyLoad>
      )}
    </>
  );
};

export default TitleRepairPage;
