import React, { useEffect } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Header from '../../components/Header/Header.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import RepairFormOrder from '../../components/Forms/RepairFormOrder/RepairFormOrder';
import BreadcrumbsCustom from '../../components/Breadcrumbs/Breadcrumbs';
import { Stack } from '@mui/material';
import useWindowWidth from '../../utils/screenSize';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  cleanIsSaveRepairRequest,cleanErrorOrderRepair
} from '../../store/slices/repairsSlice.js';
import {injectIntl, FormattedMessage } from 'react-intl';
import stylesSweetAlert from '../../custom-sweetalert.module.scss';

const customStyleSweetAlert = {
  container: stylesSweetAlert.custom_swal_container,
  title: stylesSweetAlert.custom_swal_title,
  content: stylesSweetAlert.custom_swal_content,
  confirmButton: stylesSweetAlert.custom_swal_confirm_button,
  popup: stylesSweetAlert.swal2_popup,
  icon: stylesSweetAlert.custom_swal_icon,
};
const RepairOrder = ({intl}) => {
  const { isSaveRepairRequest,errorOrderRepair } = useSelector(
    (state) => state.repairs
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (isSaveRepairRequest) {
      Swal.fire({
        title:intl.formatMessage({ id: 'success' }),
        text:intl.formatMessage({ id: 'your_repair_order_saved' }),
        icon: 'success',
        confirmButtonText: 'Ok',
        customClass: customStyleSweetAlert,

      });
      navigate('/repair');
      dispatch(cleanIsSaveRepairRequest());
    }
  }, [isSaveRepairRequest]);
  
  useEffect(() => {
    if (errorOrderRepair) {
      Swal.fire({
        title:intl.formatMessage({ id: 'warning' }),
        text:intl.formatMessage({ id: 'your_request_has_not_been_saved' }),
        icon: 'warning',
        confirmButtonText: 'Ok',
        customClass: customStyleSweetAlert,

      });
      dispatch(cleanErrorOrderRepair());
    }
  }, [errorOrderRepair]);

 
  return (
    <>
      <Header />
      {useWindowWidth() >= 1200 ? (
        <Stack sx={{ alignItems: 'center' }}>
          <Stack
            mt={'84px'}
            sx={{
              alignItems: 'flex-start',
              gap: '32px',
              width: '100%',
              maxWidth: '1600px',
            }}
          >
            <BreadcrumbsCustom pageName={<FormattedMessage id={"order_repair"}/>} />
            <RepairFormOrder />
          </Stack>
        </Stack>
      ) : (
        <Stack
          mt={'84px'}
          sx={{ alignItems: 'flex-start', padding: '0px 20px', gap: '32px' }}
        >
          <BreadcrumbsCustom pageName={<FormattedMessage id={"order_repair"}/>} />
          <RepairFormOrder />
        </Stack>
      )}
      <Footer />
    </>
  );
};

export default injectIntl(RepairOrder);
