import React from 'react';
import avatar1 from '../../images/ourServicesAvatars/Ellipse1.png';
import avatar2 from '../../images/ourServicesAvatars/Ellipse2.png';
import avatar3 from '../../images/ourServicesAvatars/Ellipse3.png';
import avatar4 from '../../images/ourServicesAvatars/Ellipse4.png';
import styles from './OurServicesAvatars.module.scss'

const OurServicesAvatars = () => {

 
    return (
      <div className={styles.container}>
        <img className={`${styles.avatar} ${styles.avatar1}`} alt="Avatar 1" src={avatar1} />
        <img className={`${styles.avatar} ${styles.avatar2}`} alt="Avatar 2" src={avatar2} />
        <img className={`${styles.avatar} ${styles.avatar3}`} alt="Avatar 3" src={avatar3} />
        <img className={`${styles.avatar} ${styles.avatar4}`} alt="Avatar 4" src={avatar4} />
      </div>
    );
 
}

export default OurServicesAvatars;
