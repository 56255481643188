import React, { useState } from 'react';
import { Stack, Box, RadioGroup, FormControl } from '@mui/material';
import { fonts } from '../../fonts';

import CustomRadioBtn2 from './CustomRadioBtn2';
import RangeInput from './RangeInput';
import PinkButton from '../Buttons/PinkButton';
import { useDispatch, useSelector } from 'react-redux';
import { getProductList } from '../../store/slices/productsSlice.js';
import { FormattedMessage } from 'react-intl';

const CatalogFilter = ({ setIsFiltration, isFiltration }) => {
  const {
    prices: { min_price_month, max_price_month },
  } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState({
    sorting: '',
    price: max_price_month / 2,
    weight: '',
    time: '',
  });

  const submitFilters = () => {
    const filters = {
      weight: filterData.weight,
      flight_time: filterData.time,
      price: `${min_price_month}-${filterData.price}`,
    };
    if (filterData.sorting === 'true') {
      filters.sort_by_popularity = true;
    }
    if (filterData.sorting === 'price') {
      filters.ordering = 'price';
    }
    if (filterData.sorting === '-price') {
      filters.ordering = '-price';
    }
    if (filterData.sorting === 'true_j') {
      filters.just_arrived = 'true';
    }
    console.log('apply filter', filters);
    dispatch(getProductList(filters));
    setIsFiltration(!isFiltration);
  };
  const ClearFilters = () => {
    localStorage.removeItem('sorted_drones')
    dispatch(getProductList());
    setIsFiltration(!isFiltration);
    setFilterData({
      sorting: '',
      price: 50,
      weight: '',
      time: '',
    });
  };
  const handleChangeSort = (value) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      sorting: value,
    }));
  };
  const handleChangeWeight = (value) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      weight: value,
    }));
  };
  const handleChangeTime = (value) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      time: value,
    }));
  };
  const handleChangePrice = (value) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      price: value,
    }));
  };
  return (
    <>
      <Stack>
        <Stack gap="25px">
          <Box component="h4" sx={{ ...fonts.fontInter24 }}>
          <FormattedMessage id={"sorting"}/>
          </Box>
          <FormControl>
            <RadioGroup
              value={filterData.sorting}
              onChange={(e) => handleChangeSort(e.target.value)}
              sx={{ display: 'flex' }}
            >
              <CustomRadioBtn2
                value="true"
                label={<FormattedMessage id={"the_most_popular"}/>}
                selectedValue={filterData.sorting}
                borderRadius={'50%'}
              />
              <CustomRadioBtn2
                value="-price"
                label={<FormattedMessage id={"price_high_to_low"}/>}
                selectedValue={filterData.sorting}
                borderRadius={'50%'}
              />
              <CustomRadioBtn2
                value="price"
                label={<FormattedMessage id={"price_low_to_high"}/>}
                selectedValue={filterData.sorting}
                borderRadius={'50%'}
              />
              <CustomRadioBtn2
                value="true_j"
                label={<FormattedMessage id={"just_arrived"}/>}
                selectedValue={filterData.sorting}
                borderRadius={'50%'}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <RangeInput
          filterData={filterData}
          handleChangePrice={handleChangePrice}
          min_price_month={min_price_month}
          max_price_month={max_price_month}
        />
        <Stack gap="25px">
          <Box component="h4" sx={{ ...fonts.fontInter24 }}>
          <FormattedMessage id={"weight"}/>
          </Box>
          <FormControl>
            <RadioGroup
              value={filterData.weight}
              onChange={(e) => handleChangeWeight(e.target.value)}
              sx={{ display: 'flex' }}
            >
              <CustomRadioBtn2
                value="0-249"
                label={<FormattedMessage id={"less_than_249"}/>}
                selectedValue={filterData.weight}
                borderRadius={'5%'}
              />
              <CustomRadioBtn2
                value="430-500"
                label="430-500g"
                selectedValue={filterData.weight}
                borderRadius={'5%'}
              />
              <CustomRadioBtn2
                value="500-10000"
                label={<FormattedMessage id={"over_500g"}/>}
                selectedValue={filterData.weight}
                borderRadius={'5%'}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack gap="25px">
          <Box component="h4" sx={{ ...fonts.fontInter24 }}>
          <FormattedMessage id={"flight_time"}/>
          </Box>
          <FormControl>
            <RadioGroup
              value={filterData.time}
              onChange={(e) => handleChangeTime(e.target.value)}
              sx={{ display: 'flex' }}
            >
              <CustomRadioBtn2
                value="20-45"
                label="20-45 min"
                selectedValue={filterData.time}
                borderRadius={'5%'}
              />
              <CustomRadioBtn2
                value="46-61"
                label="46-61 min"
                selectedValue={filterData.time}
                borderRadius={'5%'}
              />
              <CustomRadioBtn2
                value="62-77"
                label="62-77 min"
                selectedValue={filterData.time}
                borderRadius={'5%'}
              />
              <CustomRadioBtn2
                value="93-102"
                label="93-102 min"
                selectedValue={filterData.time}
                borderRadius={'5%'}
              />
              <CustomRadioBtn2
                value="102-10000"
                label={<FormattedMessage id={"more_than_102"}/>}
                selectedValue={filterData.time}
                borderRadius={'5%'}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack gap={'20px'} mt={'20px'}>
          <PinkButton
            text={<FormattedMessage id={"apply_filters"}/>}
            width={'270px'}
            action={submitFilters}
          />
          <PinkButton
            text={<FormattedMessage id={"clear_filters"}/>}
            width={'270px'}
            action={ClearFilters}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default CatalogFilter;
