import axios from 'axios';

import http from '../interceptor';
import CONSTANTS from '../../constants';
const wp_posts = axios.create({
  baseURL: CONSTANTS.BASE_BLOGS_URL,
});
/**PAYMENT */

export const makePayment = async (data) => {
console.log('makePaymentController',data);
  try {
    const response = await http.post(`/payments/make-payment/`,JSON.stringify(data),
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    console.log('response make payment', response);

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getSubscription = async (data) => {
  console.log('getSubscription',data);
 
  try {
    const response = await http.post(
      `payments/create-subscription/`,
      JSON.stringify(data),
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    console.log('getSubscription', response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
// export const getVerify = async (data) => {
//   console.log('getVerify',data);
//   try {
//     const response = await http.post(
//       `/payments/make-payment/`,
//       JSON.stringify(data),
//       {
//         headers: { 'Content-Type': 'application/json' },
//       }
//     );
//     console.log('getVerify', response);
//     return response;
//   } catch (error) {
//     console.log(error);
//   }
// };
/**ORDERS request */
export const getOrderList = async () => {
  try {
    const response = await http.get(`/user/orders/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('getOrderList',response);
    return response.data.results;
  } catch (error) {
    if (error.response) {
      console.error('Server responded with status:', error.response.status);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error', error.message);
    }
  }
};
export const orderCreate = async (data) => {
  console.log('orderCreate',data);
  try {
    const response = await http.post(`/orders/create/`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
    // console.log('orderCreateResult', response);
    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
/**REPAIR request */
export const getRepairsList = async () => {
  try {
    const response = await http.get(`/repairs/list/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data.results;
  } catch (error) {
    if (error.response) {
      console.error('Server responded with status:', error.response.status);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error', error.message);
    }
  }
};
export const repairConsultation = async (data) => {
  // console.log('repairConsultation', data);
  try {
    const response = await http.post(`/repairs/consultation/`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    // console.log('repairConsultationResult', response);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const submitRepair = async (data) => {
  // console.log('submitRepair', data);
  try {
    const response = await http.post(`/repairs/create/`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    // console.log('submitRepairResult', response);
    return response;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

/**Revies and FAQ request */
export const get_WP_POST = async (params) => {
 
  try {
    const response = await wp_posts.get(`/wp-json/wp/v2/posts/${params.id}?_embed`);
    console.log('get_WP_POST response',response);
     return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Server responded with status:', error.response.status);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error', error.message);
    }
  }
};

export const getFAQList = async (params='en') => {
  try {
    const response = await http.get(`/reviews/fqa-list`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: { lang: params }
    });
    console.log(response.data.results);
    return response.data.results;
  } catch (error) {
    if (error.response) {
      console.error('Server responded with status:', error.response.status);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error', error.message);
    }
  }
};
export const getReviewsList = async (params) => {
  try {
    const response = await http.get(`/reviews/reviews-list/`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: { lang: params }
    });
    return response.data.results;
  } catch (error) {
    if (error.response) {
      console.error('Server responded with status:', error.response.status);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error', error.message);
    }
  }
};
/**USER request */
export const userForgotPassword= async (data) => {
  try {
    const response = await http.post(`/user/forgot-password/`, JSON.stringify(data), {
      headers: { 'Content-Type': 'application/json' },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const userResetPassword= async (data) => {
  console.log('userResetPassword',data);
  try {
    const response = await http.post(`/user/password-reset/${data.uidb64}/${data.token}/`, JSON.stringify(data), {
      headers: { 'Content-Type': 'application/json' },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const userChangePassword= async (data) => {
  console.log('userChangePassword',data);
  try {
    const response = await http.post(`/user/change-password/`, JSON.stringify(data), {
      headers: { 'Content-Type': 'application/json' },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const userRegistration = async (data) => {
  try {
    const response = await http.post(`/user/register/`, JSON.stringify(data), {
      headers: { 'Content-Type': 'application/json' },
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const userLogin = async (data) => {
  try {
    const response = await http.post(`/api/v1/token/`, JSON.stringify(data), {
      headers: { 'Content-Type': 'application/json' },
    });

    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getUser = async () => {
  try {
    const response = await http.get(`/user/profile/`);
    // console.log('getUser', response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const updateProfile = async (data) => {
  // console.log('updateProfile', data);
  try {
    const response = await http.put(`/user/profile/`, JSON.stringify(data), {
      headers: { 'Content-Type': 'application/json' },
    });
    // console.log('updateProfile', response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const userVerificationSendPhone = async (data) => {
  try {
    const response = await http.post(
      `/verifications/send-code/`,
      JSON.stringify(data),
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    // console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const repeatSendCode = async (data) => {
  // console.log('repeatSendCode', data);
  try {
    const response = await http.post(
      `/verifications/extend-verification/`,
      JSON.stringify(data),
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    // console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const userVerificationSendCode = async (data) => {
  // console.log('userVerificationSendCode', data);
  try {
    const response = await http.post(
      `/verifications/verify-code/`,
      JSON.stringify(data),
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    // console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const userVerification = async (data) => {
  try {
    const response = await http.post(`/verifications/create/`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    // console.log('userVerificationResult', response);
    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
/**PRODUCT request */
export const getProductList = async (filters) => {
  // console.log('APIFilters', filters);
  try {
    const response = await http.get(`/products/list/`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: filters,
    });
    // console.log(response);
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Server responded with status:', error.response.status);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error', error.message);
    }
  }
};

export const getProduct = async (data) => {
  // console.log('getProduct', data);
  try {
    const response = await http.get(`/products/card/${data}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    //  console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Server responded with status:', error.response.status);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error', error.message);
    }
  }
};
