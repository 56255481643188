import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProductList } from '../../store/slices/productsSlice.js';
import Header from '../../components/Header/Header.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import { Stack, Box } from '@mui/material';
import ProductList from '../../components/ProductList/ProductList';
import { sectionsStyle } from '../../styles';
import BreadcrumbsCustom from '../../components/Breadcrumbs/Breadcrumbs';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CatalogFilter from '../../components/CatalogFilter/CatalogFilter';
import PageTitle from '../../components/PageTitle/PageTitle';
import useWindowWidth from '../../utils/screenSize';
import { FormattedMessage } from 'react-intl';

const Catalog = () => {
const styles={
  width:'100%',
  maxWidth:'1600px'
}
  const catalogPageStyle=useWindowWidth() >= 1200?styles:sectionsStyle;
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);
 const viewedProductsIds = JSON.parse(localStorage.getItem('viewedProducts')) || [];
 const viewedProducts=products.filter(product => viewedProductsIds.includes(product.id));

  const [isFiltration, setIsFiltration] = useState(false);
  const changeFilter = () => {
    setIsFiltration(!isFiltration);
  };
  useEffect(() => {
    dispatch(getProductList());
  }, [dispatch]);
  const sorted_drones=localStorage.getItem('sorted_drones')
  return (
    <>
      <Header />
      <Stack sx={{alignItems:'center'}}>
        <Stack mt={'84px'} mb={'64px'} sx={{ ...catalogPageStyle }}>
        <BreadcrumbsCustom pageName={<FormattedMessage id={"catalog"}/>} />
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
           
            {useWindowWidth() <= 1200 && (
              <Box onClick={changeFilter} sx={{ cursor: 'pointer' }}>
                {!isFiltration ? <FilterAltOutlinedIcon /> : <FilterAltIcon />}
              </Box>
            )}
          </Stack>
          {useWindowWidth() >= 1200 ? (
            <>
              <Stack mt="30px" gap="40px">
                <Stack direction="row-reverse" sx={{justifyContent:'start',gap:"20px" }}>
                  <Stack>
                    <ProductList />
                  </Stack>
                  <CatalogFilter
                    setIsFiltration={setIsFiltration}
                    isFiltration={isFiltration}
                  />
                </Stack>
                <PageTitle text={<FormattedMessage id={"recently_viewed"}/>} optionStyle={{fontSize:'70px'}}/>
                <ProductList propsProducts={viewedProducts} />
              </Stack>
            </>
          ) : isFiltration ? (
            <CatalogFilter
              setIsFiltration={setIsFiltration}
              isFiltration={isFiltration}
            />
          ) : (
            <Stack>
              <ProductList propsProducts={JSON.parse(sorted_drones)}/>
              <PageTitle text={<FormattedMessage id={"recently_viewed"}/>} />
              <ProductList propsProducts={viewedProducts} />{' '}
            </Stack>
          )}
        </Stack>
      </Stack>
      <Footer />
    </>
  );
};

export default Catalog;
