import React, { useState, useEffect } from 'react';

import { Stack, Box } from '@mui/material';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { sectionsStyle } from '../../styles';
import PageTitle from '../../components/PageTitle/PageTitle';
import VerificationSteps from '../../components/VerificationSteps/VerificationSteps';
import VerificationForm from '../../components/Forms/VerificationForm/VerificationForm';
import useWindowWidth from '../../utils/screenSize';
import { useSelector } from 'react-redux';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { FormattedMessage } from 'react-intl';
const steps = {
  step1: 'step1',
  step2: 'step2',
  step3: 'step3',
  step4: 'step4',
  step5: 'step5',
};
const Verification = () => {
  const pageSize = useWindowWidth();
  const [isSelect, setSelect] = useState(steps.step1);
  const { verification } = useSelector((state) => state.users);

  useEffect(()=>{
    if(verification.isProofCode&&verification.waitingVerification===false){setSelect(steps.step2)}
    if(verification.isProofCode&&verification.waitingVerification){setSelect(steps.step5)}

  },[verification.isProofCode,verification.waitingVerification])
  return (
    <>
      <Header />
      {verification.isVerification === false && (
        <Stack mt="86px" sx={{ alignItems: 'center', minHeight:'60vh' }}>
          <Stack
            sx={{
              ...sectionsStyle,
              maxWidth: pageSize >= 1200 ? '754px' : '100%',
            
              paddingTop: pageSize >= 1200 && '50px',
            }}
          >
            <PageTitle
              text={<FormattedMessage id={"verification"}/>}
              optionStyle={{
                fontSize: pageSize >= 1200 ? '72px' : '',
                textAlign: pageSize >= 1200 && 'center',
              }}
            />
            <VerificationSteps
              isSelect={isSelect}
              setSelect={setSelect}
              steps={steps}
            />
            <VerificationForm
            
              isSelect={isSelect}
              setSelect={setSelect}
              steps={steps}
            />
          </Stack>
        </Stack>
      )}
      {verification.isVerification && (
        <Stack
          mt={'80px'}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '40px',
          }}
        >
          You are verification{' '}
          <Box
            sx={{
              color: 'lightgreen',
              display: 'inline-flex',
              width: '40px',
            }}
          >
            <EmojiEmotionsIcon sx={{ fontSize: 60 }} />
          </Box>
        </Stack>
      )}
      <Footer />
    </>
  );
};

export default Verification;
