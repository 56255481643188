import React,{useEffect} from 'react';
import RequestForConsultation from '../../components/Forms/RequestForConsultation/RequestForConsultation';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useSelector ,useDispatch} from 'react-redux';
import { cleanIsSaveRepairConsultationRequest ,cleanErrorConsultation} from '../../store/slices/repairsSlice.js';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Stack } from '@mui/material';
import BreadcrumbsCustom from '../../components/Breadcrumbs/Breadcrumbs';
import { FormattedMessage,injectIntl } from 'react-intl';
import stylesSweetAlert from '../../custom-sweetalert.module.scss';
const customStyleSweetAlert = {
  container: stylesSweetAlert.custom_swal_container,
  title: stylesSweetAlert.custom_swal_title,
  content: stylesSweetAlert.custom_swal_content,
  confirmButton: stylesSweetAlert.custom_swal_confirm_button,
  popup: stylesSweetAlert.swal2_popup,
  icon: stylesSweetAlert.custom_swal_icon,
};
const Consultation = ({intl}) => {
  const { isSaveRepairConsultationRequest ,errorConsultation} = useSelector(
    (state) => state.repairs
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSaveRepairConsultationRequest) {
      Swal.fire({
        title:intl.formatMessage({ id: 'success' }),
        text:intl.formatMessage({ id: 'your_appointment_saved' }),
        icon: 'success',
        confirmButtonText: 'Ok',
        customClass: customStyleSweetAlert,
      });
      dispatch(cleanIsSaveRepairConsultationRequest());
    }
   
  }, [isSaveRepairConsultationRequest]);


  useEffect(() => {
  
    if (errorConsultation===true) {
      Swal.fire({
        title:intl.formatMessage({ id: 'warning' }),
        text:intl.formatMessage({ id: 'your_request_has_not_been_saved' }),
        icon: 'warning',
        confirmButtonText: 'Ok',
        customClass: customStyleSweetAlert,
      });
      dispatch(cleanErrorConsultation());
    }
  }, [errorConsultation]);
  return (
    <div>
      <Header/>
      <Stack mt={'80px'} sx={{maxWidth:'1600px',margin:'80px auto 20px'}}><BreadcrumbsCustom pageName={<FormattedMessage id="consultationUpper"/>}/></Stack>
       <RequestForConsultation />
       <Footer/>
    </div>
  );
}

export default injectIntl(Consultation);
