import React from 'react';
import {
  YouTubeIcon,
  InstagramIcon,
  FacebookIcon,
} from '../Icons/Icons';
import { Box, Stack } from '@mui/material';
import { primary } from '../../colors.js';
import logo from '../../images/footer_logo.svg';
import { fonts } from '../../fonts.js';
import { Link } from 'react-router-dom';
import useWindowWidth from '../../utils/screenSize';
import { FormattedMessage } from 'react-intl';
const Footer = () => {
  const linkClass = {
    color: '#fff',
    textDecoration: 'none',
    ...fonts.fontInter18_400,
  };
  const footerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '45px',
    backgroundColor: primary.blueViolet,
    width: '100%',
    paddingTop: '40px',
    minHeight: '376px',
    marginTop: '90px',
  };
  const footerStyleHD = {
    // position:'fixed',
    // bottom:'0px',
    // left:'0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: primary.blueViolet,
    width: '100%',
    paddingTop: '40px',
    paddingBottom: '40px',
    minHeight: '190px',
    marginTop: '90px',
  };
  const linkClassHD = {
    color: '#fff',
    textDecoration: 'none',
    ...fonts.fontInter18_500,
  };
  return (
    <>
      {' '}
      {useWindowWidth() >= 1200 ? (
        <Stack
          component="footer"
          sx={{
            ...footerStyleHD,
          }}
        >
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-around',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Link
              to={'/'}
              style={{
                color: '#fff',
                textDecoration: 'none',
                ...fonts.fontPoppins50,
              }}
            >
              <img src={logo} alt="" width="250px" />
            </Link>

            <Stack component="ul" direction="row" gap="30px">
              <Box component="li">
                <Link to={'/catalog'} style={linkClassHD}>
                  <FormattedMessage id="catalog" />
                </Link>
              </Box>
              <Box component="li">
                <Link style={linkClassHD}>
                  {' '}
                  <FormattedMessage id="how_it_work" />
                </Link>
              </Box>
              <Box component="li">
                <Link to={'/repair'} style={linkClassHD}>
                  <FormattedMessage id="drone_repair" />
                </Link>
              </Box>
            </Stack>

            <Stack direction="row" gap="33px" sx={{ alignItems: 'center' }}>
              <Link
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YouTubeIcon fill1={'#fff'} fill2={'#000'} />
              </Link>
              <Link
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </Link>
              <Link
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </Link>
              
            </Stack>
          </Stack>
          <Box sx={{ ...fonts.fontInter18_400, color: '#fff' }}>
            <FormattedMessage id="copyright" />
          </Box>
        </Stack>
      ) : (
        <Box
          component="footer"
          gap="25px"
          sx={{
            ...footerStyle,
          }}
        >
          <Link
            to={'/'}
            style={{
              color: '#fff',
              textDecoration: 'none',
              ...fonts.fontPoppins32,
            }}
          >
            <img src={logo} alt="" width="250px" />
          </Link>
          <Stack component="ul" gap="10px" sx={{ width: 'calc(100% - 40px)' }}>
            <Box component="li">
              <Link to={'/catalog'} style={linkClass}>
                <FormattedMessage id="catalog" />
              </Link>
            </Box>
            <Box component="li">
              <Link style={linkClass}>
                {' '}
                <FormattedMessage id="how_it_work" />
              </Link>
            </Box>
            <Box component="li">
              <Link to={'/repair'} style={linkClass}>
                Drone repair
              </Link>
            </Box>
          </Stack>
          <Stack style={{ alignItems: 'center', gap: '25px' }}>
            <Stack direction="row" gap="33px" sx={{ alignItems: 'center' }}>
              <Link
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YouTubeIcon fill1={'#fff'} fill2={'#000'} />
              </Link>
              <Link
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </Link>
              <Link
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </Link>
             
            </Stack>
            <Box sx={{ ...fonts.fontInter18_400, color: '#fff' }}>
            <FormattedMessage id="copyright" />
            </Box>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default Footer;
