import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import { getUser } from '../../store/slices/usersSlice';
import { Stack } from '@mui/material';
import { fonts } from '../../fonts';
const RegistrationIndicator = () => {
  const { isRegister } = useSelector((state) => state.users);
  const [indicator, setIndicator] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);
  useEffect(() => {
    setIndicator(isRegister);
  }, [isRegister]);
  return (
    <div>
      {indicator ? (
        <Stack direction="row" gap="10px" sx={{alignItems:'center',...fonts.fontInter18_600}}>
          <WbIncandescentIcon sx={{ color: 'green' }} />
          {/* <span>{user?.user_email}</span> */}
        </Stack>
      ) : (
        <WbIncandescentIcon sx={{ color: 'gray' }} />
      )}
    </div>
  );
};

export default RegistrationIndicator;
