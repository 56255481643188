import React, { useState,useEffect } from 'react';
import { Stack } from '@mui/material';
import { fonts } from '../../fonts';
import { FormattedMessage } from 'react-intl';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import PinkButton from '../Buttons/PinkButton';
const CustomerOrder = ({ order }) => {
  const navigate = useNavigate();
  const [isPaid, setIsPaid] = useState(true);
  const redirectToPaymentStep = (order_id) => {
    navigate(`/payments/${order_id}`);
  };
console.log(order);
  let statusKey;
  useEffect(() => {
    const hasNotPaidOrders = order.payment_statuses.some((payment_status) => payment_status.status === 'Not paid'&&payment_status.type!=='Verification fee of 1 EUR')
    const hasNotPaidOrdersSubscription = order.items.some((item) => item.subscription_status === 'Not paid')
    if (hasNotPaidOrders || hasNotPaidOrdersSubscription) {
      setIsPaid(false)
    }
  }, []);
  switch (order.status) {
    case 'New':
      statusKey = 'new';
      break;
    case 'In process':
      statusKey = 'in_process';
      break;
    case 'Delivered':
      statusKey = 'delivered';
      break;
    case 'Shipped':
      statusKey = 'shipped';
      break;
    case 'Rental period ended':
      statusKey = 'rental_period_ended';
      break;
    case 'Cancelled':
      statusKey = 'cancelled';
      break;
    case 'Not paid':
      statusKey = 'unpaid';
      break;
    default:
      statusKey = 'New';
      break;
  }

  let productsInOrder = [];
  if (order.items.length > 0) {
    productsInOrder = order.items;
  }
  return (
    <Stack
      direction="row"
      gap="20px"
      sx={{ borderBottom: '2px solid gray', paddingBottom: '20px' }}
    >
      <Stack sx={{ width: '100%', maxWidth: '33.3%' }} gap="20px">
        <span style={{ ...fonts.fontInter18_600 }}>
          <FormattedMessage id="order_number" />:
        </span>{' '}
        <span style={{ ...fonts.fontInter16_400 }}>{order.order}</span>
      </Stack>
      <Stack sx={{ width: '100%', maxWidth: '33.3%' }} gap="20px">
        <span style={{ ...fonts.fontInter18_600 }}>
          <FormattedMessage id="rental_start_date" />:
        </span>{' '}
        {order.delivery_date ? (
          <span style={{ ...fonts.fontInter16_400 }}>
            {format(parseISO(order.delivery_date), 'yyyy-MM-dd')}
          </span>
        ) : (
          <span>
            <FormattedMessage id="rental_not_started" />
          </span>
        )}
      </Stack>
      <Stack sx={{ width: '100%', maxWidth: '33.3%' }} gap="20px">
        <span style={{ ...fonts.fontInter18_600 }}>
          <FormattedMessage id="status" />:
        </span>{' '}
        <span style={{ ...fonts.fontInter16_400 }}>
          <FormattedMessage id={statusKey} />
        </span>
      </Stack>
      <Stack sx={{ width: '100%', maxWidth: '33.3%' }} gap="20px">
        <span style={{ ...fonts.fontInter18_600 }}>
          <FormattedMessage id="drones" />:
        </span>
        {productsInOrder.map((product, i) => (
          <Stack key={i} gap="10px">
            <div style={{ ...fonts.fontInter16_400 }}>
              <FormattedMessage id="name" /> : <br />
              {product.product_title}
            </div>
            <div>
              <FormattedMessage id="quantity" />:{product.quantity}
            </div>
            <div>
              <FormattedMessage id="rental_period_order" />:
              {product.rental_duration}
            </div>
            <div>
              <FormattedMessage id="price_month" />: {product.price_month}{' '}
              <FormattedMessage id="euro" />{' '}
            </div>
          </Stack>
        ))}
      </Stack>
      {isPaid===false && (
        <Stack>
          <PinkButton
            text={'Paid'}
            action={() => {
              redirectToPaymentStep(order.order);
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default CustomerOrder;
