import React, { useEffect} from 'react';
import { useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import PageTitle from '../../components/PageTitle/PageTitle';
import { Stack} from '@mui/material';
import {fonts} from '../../fonts.js';
import RegistrationForm from '../../components/Forms/RegistrationForm/RegistrationForm';
import { Link } from 'react-router-dom';
import useWindowWidth from '../../utils/screenSize';
import { FormattedMessage } from 'react-intl';


const Registration = () => {
 const navigate=useNavigate();
 const fontSize=useWindowWidth()>=1200&&'72px';
  const {isRegister} = useSelector((state) => state.users);
  useEffect(() => {
   if(isRegister){
    navigate('/login');
   }
  }, [isRegister,navigate]);

  return (
    <>
      {' '}
      <Header />
      <Stack gap="32px" sx={{width:'calc(100% - 40px)',alignItems: 'center',margin:'84px auto 0px', minHeight:'60vh'}}>
        <Stack gap="32px" sx={{ alignItems: 'center' }}>
          <PageTitle text={<FormattedMessage id={"registration"}/>} optionStyle={{fontSize:fontSize}}/>
          <Stack direction="row" gap="40px">
            <Link to="/login" style={{...fonts.fontInter24,color:'black',textDecoration:'none'}}><FormattedMessage id={"log_in"}/></Link>
            <Link to="/registration" style={{...fonts.fontInter24,color:'rgba(67, 97, 238, 1)'}}><FormattedMessage id={"registration"}/></Link>
          </Stack>
        </Stack>
        <RegistrationForm/>
      </Stack>

      <Footer />
    </>
  );
};

export default Registration;
