import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { Box, Stack } from '@mui/material';
import { SwiperNavButtons2 } from './SliderBtns2';
import PageTitle from '../PageTitle/PageTitle';
import { sectionsStyle } from '../../styles';
import { primary } from '../../colors';
import { fonts } from '../../fonts';
import { FormattedMessage } from 'react-intl';

const slideStyle={
  width: '100%',
  backgroundColor: primary.blue,
  height: '300px',
  color: '#fff',
  borderRadius:'10px'
}

const Slider2 = () => {
  return (
    <Stack sx={{ ...sectionsStyle }}>
      <PageTitle text={'Customer Testimonials'} />
      <Box>
        <Swiper
          style={{
            width: '100%',
            height: '350px',
          }}
          loop={true}
          modules={[Navigation]}
          spaceBetween={30}
          slidesPerView={1}
        >
          <SwiperSlide
            style={{
             ...slideStyle
            }}
          >
            <Stack gap="10px" sx={{padding:'20px'}}>
              <Box sx={{...fonts.fontInter24}}>E.J.</Box>
              <Box sx={{...fonts.fontInter16_400}}>
              <FormattedMessage id={"testimonial_1"}/>
              </Box>
            </Stack>
          </SwiperSlide>
          <SwiperSlide
            style={{
             ...slideStyle
            }}
          >
            <Stack gap="10px" sx={{padding:'20px'}}>
              <Box sx={{...fonts.fontInter24}}>S.W.</Box>
              <Box sx={{...fonts.fontInter16_400}}>
              <FormattedMessage id={"testimonial_2"}/>
              </Box>
            </Stack>
          </SwiperSlide>
          <SwiperSlide
            style={{
             ...slideStyle
            }}
          >
            <Stack gap="10px" sx={{padding:'20px'}}>
              <Box sx={{...fonts.fontInter24}}>R.H.</Box>
              <Box sx={{...fonts.fontInter16_400}}>
              <FormattedMessage id={"testimonial_3"}/>
              </Box>
            </Stack>
          </SwiperSlide>
          <SwiperSlide
            style={{
             ...slideStyle
            }}
          >
            <Stack gap="10px" sx={{padding:'20px'}}>
              <Box sx={{...fonts.fontInter24}}>L.K.</Box>
              <Box sx={{...fonts.fontInter16_400}}>
              <FormattedMessage id={"testimonial_4"}/>
              </Box>
            </Stack>
          </SwiperSlide>
          <SwiperSlide
            style={{
             ...slideStyle
            }}
          >
            <Stack gap="10px" sx={{padding:'20px'}}>
              <Box sx={{...fonts.fontInter24}}>E.F.</Box>
              <Box sx={{...fonts.fontInter16_400}}>
              <FormattedMessage id={"testimonial_5"}/>
              </Box>
            </Stack>
          </SwiperSlide>
          <SwiperSlide
            style={{
             ...slideStyle
            }}
          >
            <Stack gap="10px" sx={{padding:'20px'}}>
              <Box sx={{...fonts.fontInter24}}>E.C.</Box>
              <Box sx={{...fonts.fontInter16_400}}>
              <FormattedMessage id={"testimonial_6"}/>
              </Box>
            </Stack>
          </SwiperSlide>
          <SwiperSlide
            style={{
             ...slideStyle
            }}
          >
            <Stack gap="10px" sx={{padding:'20px'}}>
              <Box sx={{...fonts.fontInter24}}>V.S.</Box>
              <Box sx={{...fonts.fontInter16_400}}>
              <FormattedMessage id={"testimonial_7"}/>
              </Box>
            </Stack>
          </SwiperSlide>
          <SwiperSlide
            style={{
             ...slideStyle
            }}
          >
            <Stack gap="10px" sx={{padding:'20px'}}>
              <Box sx={{...fonts.fontInter24}}>V.K.</Box>
              <Box sx={{...fonts.fontInter16_400}}>
              <FormattedMessage id={"testimonial_8"}/>
              </Box>
            </Stack>
          </SwiperSlide>
          <SwiperNavButtons2/>
        </Swiper>
      </Box>
    </Stack>
  );
};

export default Slider2;
