import React from 'react';
import ProductListInCart from '../ProductListInCart/ProductListInCart';
import { Stack, Box } from '@mui/material';
import PinkButton from '../Buttons/PinkButton';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import PageTitle from '../PageTitle/PageTitle';
import ProductList from '../ProductList/ProductList';
import { fonts } from '../../fonts';
import { FormattedMessage } from 'react-intl';
import MemoryCardListInCart from '../MemoryCardListInCart/MemoryCardListInCart';

const CartMob = ({
  productsInCart,
  totalSumCart,
  isPopularProducts,
  goToCreateOrder,
  memory_card_in_cart,
  totalSumMemoryCard
}) => {
  return (
    <div>
      <Stack gap="20px">
        {productsInCart.length > 0&&<Box component="p" sx={{...fonts.fontPoppins32}}><FormattedMessage id="drones_in_cart" /></Box>}
        <ProductListInCart productsInCart={productsInCart} />
        <Stack
              direction="row"
              sx={{ alignItems: 'center', ...fonts.fontInter18_600,justifyContent:'flex-end' }}
            >
              <FormattedMessage id="total" /> <EuroSymbolIcon /> {totalSumCart}{' '}
              <FormattedMessage id={'tax_included'} />
            </Stack>
      </Stack>
      <Stack sx={{borderBottom:'1px solid gray'}}></Stack>
      {memory_card_in_cart.length>0&&
        <><Stack gap="20px">
          <Box component="p" sx={{...fonts.fontPoppins32}}>  <FormattedMessage id={'memory_cards_in_cart'} /></Box>
          <MemoryCardListInCart memoryCards={memory_card_in_cart} />
          <Stack
              direction="row"
              sx={{ alignItems: 'center', ...fonts.fontInter18_600,justifyContent:'flex-end' }}
            >
              <FormattedMessage id="total" /> <EuroSymbolIcon /> {totalSumMemoryCard}{' '}
              <FormattedMessage id={'tax_included'} />
            </Stack>
        </Stack>
        <Stack sx={{borderBottom:'1px solid gray'}}></Stack></>}
      {productsInCart.length !== 0 && (
        <Stack
          direction="row"
          sx={{ justifyContent: 'space-between', marginTop: '20px' }}
        >
          <PinkButton
            text={<FormattedMessage id="order" />}
            optionStyle={{ width: '100%', maxWidth: '132px' }}
            action={goToCreateOrder}
          />
         
        </Stack>
      )}
      <PageTitle
        text={<FormattedMessage id="most_popular" />}
        optionStyle={{ margin: '20px 0px' }}
      />
      <ProductList propsProducts={isPopularProducts} />
    </div>
  );
};

export default CartMob;
