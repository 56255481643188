


import Header from '../../components/Header/Header.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import { Stack } from '@mui/material';
import Slider2 from '../../components/Slider2/Slider2';
import Cubes from '../../components/Cubes/Cubes';
import RepairStatistic from '../../components/RepairStatistic/RepairStatistic';
import OurResponsibility from '../../components/OurResponsibility/OurResponsibility';
import RepairMotivation from '../../components/RepairMotivation/RepairMotivation';
import RepairServices from '../../components/RepairServices/RepairServices';
import TitleRepairPage from '../../components/TitleRepairPage/TitleRepairPage';
import useWindowWidth from '../../utils/screenSize';
import Slider3 from '../../components/Slider3/Slider3';


const Repair = () => {

  return (
    <>
      <Header />

      <Stack mt={'84px'}>
        <TitleRepairPage />
        <Stack sx={{ alignItems: 'flex-start', gap: '120px' }}>
          <RepairServices />
          <RepairMotivation />
          <OurResponsibility />
          <RepairStatistic />
          <Cubes />
         
          {useWindowWidth() >= 1200 ? <Slider3 /> : <Slider2 />}
        </Stack>
      </Stack>

      <Footer />
    </>
  );
};

export default Repair;
