import React from 'react';
import { Button } from '@mui/material';
import { primary } from '../../colors.js';
import {fonts} from '../../fonts.js';
const PinkButton = (props) => {

  return (
    <Button
      type={props.type}
      variant="contained"
      sx={{
        textTransform:'none',
        backgroundColor: primary.pink,
        borderRadius: '100px',
        width: props?.width,
        height: props?.height,
        '&:hover': {
          backgroundColor:primary.violet,
        },
        ...fonts.fontInter18_600,
     ...props.optionStyle
      }}
      onClick={props.action}
    >
      {props.text}
    </Button>
  );
};

export default PinkButton;
