import React, { useEffect,useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import StripeSubscription from '../../components/Forms/StripePaymentForm/StripeSubscription';
import CONSTANTS from '../../constants';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useSelector, useDispatch } from 'react-redux';
import StripePaymentMemoryCard from '../../components/Forms/StripePaymentForm/StripePaymentMemoryCard';
import { getOrderList } from '../../store/slices/customerOrdersSlice';
import { Stack, Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { fonts } from '../../fonts';
const stripePromise = loadStripe(CONSTANTS.PAYMENT_KEY);
const PaymentsPage = () => {
  const[isPayment,setIsPayment]=useState({
    hasNotPaidMC:false,
    hasNotPaidDEP:false,
    hasNotPaidSubscription:false,
  })
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrderList());
  }, []);

  const { orders } = useSelector((state) => state.orders);
  const url = window.location.href;
  const lastPart = url.split('/').pop();
  const order_id = Number(lastPart);
  let this_order = null;

  if (orders.length > 0) {
    this_order = orders.filter((order) => {
      return Number(order.order) === order_id;
    });
  }
 
  useEffect(() => {
    if (this_order && this_order.length > 0) {
      console.log(this_order);
      const hasNotPaidMC = this_order[0].payment_statuses.some(
        (payment_status) =>
          payment_status.status === 'Not paid' &&
          payment_status.type === 'Payment for memory card'
      );
      if(hasNotPaidMC){
        setIsPayment(prevState => ({
          ...prevState,
          hasNotPaidMC: true
        }));
      }
      const hasNotPaidDEP = this_order[0].payment_statuses.some(
        (payment_status) =>
          payment_status.status === 'Not paid' &&
          payment_status.type === 'Deposit for rental'
      );
      if(hasNotPaidDEP){
        setIsPayment(prevState => ({
          ...prevState,
          hasNotPaidDEP: true
        }));
      }
      const hasNotPaidSubscription = this_order[0].items.some(
        (item) => item.subscription_status === 'Not paid'
      );
      if(hasNotPaidSubscription){
        setIsPayment(prevState => ({
          ...prevState,
          hasNotPaidSubscription: true
        }));
      }
    }  
  }, []);


  const totalFullPrice =
    this_order && this_order.length > 0 && this_order[0].items
      ? this_order[0].items.reduce((sum, item) => sum + item.full_price, 0)
      : 0;
  const totalMemoryCardPrice =
    this_order &&
    this_order.length > 0 &&
    this_order[0].memory_cards.reduce(
      (sum, card) => sum + card.price * card.quantity,
      0
    );
  const isLicensePilot = localStorage.getItem('isLicense');
  let totalPriceDeposit = 0;
  if (isLicensePilot) {
    totalPriceDeposit =
      totalFullPrice - totalFullPrice * 0.05 - totalFullPrice * 0.5;
  } else if (!isLicensePilot) {
    totalPriceDeposit = totalFullPrice * 0.5;
  }

  return (
    <>
      <Header />

      <div
        style={{
          gap: '30px',
          margin: '120px auto',
          width: 'calc(100% - 40px)',
          maxWidth: '1600px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {isPayment.hasNotPaidSubscription&&(<div style={{width:'100%'}}>
          <Stack>
            <Box component="h5" mb="20px" sx={{ ...fonts.fontInter24 }}>
              <FormattedMessage id={'subscription'} />
            </Box>
            <Elements stripe={stripePromise}>
              <StripeSubscription order_id_from_page={order_id} />
            </Elements>
          </Stack>
          <Stack mt="20px" sx={{ borderBottom: '1px solid black', width: '100%' }}></Stack>
        </div>)}

        {isPayment.hasNotPaidMC&&(<div style={{width:'100%'}}>
          <Stack>
            <Box component="h5" mb="20px" sx={{ ...fonts.fontInter24 }}>
              <FormattedMessage id={'buying_card'} /> : €{totalMemoryCardPrice}
              <FormattedMessage id={'tax_included'} />
            </Box>
            <Elements stripe={stripePromise}>
              <StripePaymentMemoryCard
                payment_type={'MC'}
                order_id_from_page={order_id}
                totalPrice={totalMemoryCardPrice}
              />
            </Elements>
          </Stack>
          <Stack mt="20px" sx={{ borderBottom: '1px solid black', width: '100%' }}></Stack>
        </div>)}

        {isPayment.hasNotPaidDEP&&(<div style={{width:'100%'}}>
          <Stack>
            <Box component="h5" mb="20px" sx={{ ...fonts.fontInter24 }}>
              <FormattedMessage id={'drone_deposit'} /> : €{totalPriceDeposit}
              <FormattedMessage id={'tax_included'} />
            </Box>
            <Elements stripe={stripePromise}>
              <StripePaymentMemoryCard
                payment_type={'DEP'}
                order_id_from_page={order_id}
                totalPrice={totalPriceDeposit}
              />
            </Elements>
          </Stack>
          <Stack mt="20px" sx={{ borderBottom: '1px solid black', width: '100%' }}></Stack>
        </div>)}
      </div>
      <Footer />
    </>
  );
};

export default PaymentsPage;
