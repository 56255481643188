import { SvgIcon } from '@mui/material';
import useWindowWidth from '../../utils/screenSize';


export const TwitterIcon = ({ fill = '#FFF', width = '35', height = '29' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 35 29"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0715 7.66092L17.147 8.90655L15.888 8.75402C11.3052 8.16934 7.30156 6.1865 3.90224 2.85636L2.24034 1.20399L1.81228 2.4242C0.905796 5.14424 1.48494 8.01681 3.37345 9.94881C4.38066 11.0165 4.15404 11.169 2.41661 10.5335C1.81228 10.3301 1.2835 10.1776 1.23314 10.2539C1.05688 10.4318 1.6612 12.7451 2.13962 13.6603C2.79431 14.9313 4.12886 16.1769 5.58931 16.9142L6.82314 17.4988L5.36269 17.5243C3.9526 17.5243 3.90224 17.5497 4.05332 18.0835C4.55692 19.7359 6.54615 21.4899 8.76201 22.2526L10.3232 22.7864L8.96345 23.5999C6.94904 24.7692 4.5821 25.4302 2.21516 25.481C1.08206 25.5064 0.150391 25.6081 0.150391 25.6844C0.150391 25.9386 3.22237 27.3622 5.01016 27.9214C10.3735 29.5738 16.7441 28.862 21.5284 26.0403C24.9277 24.032 28.327 20.0409 29.9134 16.1769C30.7695 14.1178 31.6256 10.3555 31.6256 8.55065C31.6256 7.38129 31.7011 7.22876 33.1112 5.83061C33.9422 5.01714 34.7228 4.12741 34.8738 3.8732C35.1256 3.3902 35.1005 3.3902 33.8163 3.82235C31.676 4.58498 31.3738 4.4833 32.4314 3.33936C33.212 2.52588 34.1436 1.05147 34.1436 0.619312C34.1436 0.543049 33.7659 0.670154 33.3379 0.898943C32.8846 1.15315 31.8774 1.53447 31.122 1.76326L29.7623 2.19541L28.5284 1.35652C27.8486 0.898943 26.8917 0.390523 26.3881 0.237997C25.1039 -0.117896 23.1399 -0.0670542 21.9816 0.339681C18.8341 1.48362 16.8448 4.43246 17.0715 7.66092Z"
      fill={fill}
    />
  </svg>
);
export const YouTubeIcon = ({
  fill1 = '#533E2D',
  fill2 = '#533E2D',
  width = '41',
  height = '29',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 41 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.2305 4.49702C38.7697 2.72682 37.412 1.33287 35.6879 0.859782C32.5635 0 20.0339 0 20.0339 0C20.0339 0 7.5043 0 4.37969 0.859782C2.65563 1.33287 1.29788 2.72682 0.837099 4.49702C0 7.70529 0 14.3994 0 14.3994C0 14.3994 0 21.0932 0.837099 24.3017C1.29788 26.0719 2.65563 27.4659 4.37969 27.9392C7.5043 28.7988 20.0339 28.7988 20.0339 28.7988C20.0339 28.7988 32.5635 28.7988 35.6879 27.9392C37.412 27.4659 38.7697 26.0719 39.2305 24.3017C40.0678 21.0932 40.0678 14.3994 40.0678 14.3994C40.0678 14.3994 40.0678 7.70529 39.2305 4.49702Z"
      fill={fill1}
    />
    <path
      d="M16.2773 21.2858V8.76465L26.2943 15.0255L16.2773 21.2858Z"
      fill={fill2}
    />
  </svg>
);
export const InstagramIcon = ({
  fill = '#FFF',
  width = '40',
  height = '40',
}) => {
  return (
    <svg fill={fill} width={width} height={height} viewBox="0 0 256 256">
      <path d="M128,84a44,44,0,1,0,44,44A44.04978,44.04978,0,0,0,128,84Zm0,80a36,36,0,1,1,36-36A36.04061,36.04061,0,0,1,128,164ZM172,32H84A52.059,52.059,0,0,0,32,84v88a52.059,52.059,0,0,0,52,52h88a52.059,52.059,0,0,0,52-52V84A52.059,52.059,0,0,0,172,32Zm44,140a44.04978,44.04978,0,0,1-44,44H84a44.04978,44.04978,0,0,1-44-44V84A44.04978,44.04978,0,0,1,84,40h88a44.04978,44.04978,0,0,1,44,44ZM188,76a8,8,0,1,1-8-8A8.00917,8.00917,0,0,1,188,76Z" />
    </svg>
  );
};
export const FacebookIcon = ({
  fill = '#FFF',
  width = '15',
  height = '29',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.52908 28.7988V14.3977H13.5044L14.0312 9.43501H9.52908L9.53584 6.95113C9.53584 5.65679 9.65882 4.96325 11.5179 4.96325H14.0031V0H10.0272C5.25151 0 3.5706 2.40744 3.5706 6.45599V9.43557H0.59375V14.3983H3.5706V28.7988H9.52908Z"
        fill={fill}
      />
    </svg>
  );
};
export const MenuHeader = ({ width = '32', height = '32' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.33301 5.99967H26.6663" stroke="#323232" />
    <path d="M5.33301 12.6667H26.6663" stroke="#323232" />
    <path d="M5.33301 19.3337H26.6663" stroke="#323232" />
    <path d="M5.33301 25.9997H26.6663" stroke="#323232" />
  </svg>
);

export const CardImg = () => {
  return (
    <SvgIcon sx={{ padding: '0px', width: '54px', height: '54px' }}>
      <svg
        width="58"
        height="58"
        viewBox="0 0 58 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="58" height="58" rx="29" fill="white" />
        <rect
          x="0.402778"
          y="0.402778"
          width="56"
          height="56"
          rx="28.5972"
          stroke="#4361EE"
        />
        <path
          d="M35.5138 23.6299H22.4874C21.9214 23.6299 21.4531 24.0681 21.4155 24.632L20.5605 37.45C20.4778 38.6895 21.4617 39.741 22.7044 39.741H35.2968C36.5395 39.741 37.5234 38.6895 37.4407 37.45L36.5857 24.632C36.5481 24.0681 36.0798 23.6299 35.5138 23.6299Z"
          stroke="black"
        />
        <path
          d="M32.2238 25.7783V21.482V21.482V21.482C32.2238 19.7022 30.7814 18.2598 29.0016 18.2598H29.0005C27.2208 18.2598 25.7783 19.7022 25.7783 21.482V21.482V21.482V25.7783"
          stroke="black"
        />
        <path
          d="M29.0004 36.6838C28.3108 36.6838 27.7234 36.4962 27.2383 36.1208C26.7531 35.7425 26.3824 35.1948 26.1261 34.4777C25.8698 33.7576 25.7416 32.8879 25.7416 31.8688C25.7416 30.8557 25.8698 29.9907 26.1261 29.2736C26.3854 28.5535 26.7577 28.0043 27.2429 27.6259C27.7311 27.2445 28.3169 27.0538 29.0004 27.0538C29.6839 27.0538 30.2683 27.2445 30.7534 27.6259C31.2416 28.0043 31.6139 28.5535 31.8702 29.2736C32.1296 29.9907 32.2593 30.8557 32.2593 31.8688C32.2593 32.8879 32.1311 33.7576 31.8748 34.4777C31.6185 35.1948 31.2478 35.7425 30.7626 36.1208C30.2774 36.4962 29.69 36.6838 29.0004 36.6838ZM29.0004 35.6769C29.6839 35.6769 30.2149 35.3473 30.5932 34.6882C30.9716 34.0291 31.1608 33.0893 31.1608 31.8688C31.1608 31.0571 31.0738 30.366 30.8999 29.7954C30.729 29.2248 30.4819 28.79 30.1584 28.491C29.838 28.1919 29.452 28.0424 29.0004 28.0424C28.323 28.0424 27.7936 28.3765 27.4122 29.0448C27.0308 29.71 26.8401 30.6513 26.8401 31.8688C26.8401 32.6805 26.9255 33.3701 27.0964 33.9376C27.2673 34.5052 27.5129 34.9369 27.8333 35.2329C28.1567 35.5289 28.5458 35.6769 29.0004 35.6769Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export const ArrowRight = ({ width = '13px', height = '9.3px' , fill='#fff'}) => {
  return (
    <SvgIcon sx={{ padding: '0px', width: width, height: height }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 15 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M13.8074 6.12944H0.987305" stroke={fill} />
        <path d="M9.22852 10.7631L13.8071 6.12891" stroke={fill} />
        <path d="M9.22852 1.49512L13.8071 6.12934" stroke={fill} />
      </svg>
    </SvgIcon>
  );
};
export const CalculatorShop = ({ width = '45px', height = '44px' }) => {
  return (
    <SvgIcon sx={{ padding: '0px',    width: useWindowWidth() >= 1200 ? '75px' : '45px',
    height: useWindowWidth() >= 1200 ? '75px' : '45px',}}>
      <svg
        width="45"
        height="44"
        viewBox="0 0 45 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38.9152 22.3172V36.7763C38.9152 37.7745 38.106 38.5837 37.1078 38.5837H26.2634C25.2653 38.5837 24.4561 37.7745 24.4561 36.7763V22.3172C24.4561 21.319 25.2653 20.5098 26.2634 20.5098H37.1078C38.106 20.5098 38.9152 21.319 38.9152 22.3172Z"
          stroke="#F72585"
        />
        <path d="M38.9152 25.931H24.4561" stroke="#F72585" />
        <path
          d="M19.0336 34.9681H9.99662C8.00023 34.9681 6.38184 33.3497 6.38184 31.3533V9.66459C6.38184 7.6682 8.00023 6.0498 9.99662 6.0498H31.6853C33.6817 6.0498 35.3001 7.6682 35.3001 9.66459V15.0868"
          stroke="#F72585"
        />
        <path d="M6.38184 13.2797H35.3001" stroke="#F72585" />
        <path d="M11.8037 20.5092H17.2259" stroke="#F72585" />
        <path d="M11.8037 25.931H13.6111" stroke="#F72585" />
        <path
          d="M29.5165 29.9985C29.5165 30.0484 29.476 30.0888 29.4261 30.0888C29.3762 30.0888 29.3358 30.0483 29.3358 29.9985C29.3358 29.9486 29.3762 29.9081 29.426 29.9081C29.4501 29.908 29.4731 29.9175 29.4901 29.9345C29.507 29.9515 29.5166 29.9745 29.5165 29.9985"
          stroke="#F72585"
        />
        <path
          d="M34.0351 29.9985C34.035 30.0484 33.9946 30.0888 33.9447 30.0888C33.8948 30.0888 33.8544 30.0483 33.8543 29.9985C33.8543 29.9486 33.8947 29.9081 33.9446 29.9081C33.9686 29.908 33.9916 29.9175 34.0086 29.9345C34.0256 29.9515 34.0351 29.9745 34.0351 29.9985"
          stroke="#F72585"
        />
        <path
          d="M34.0351 34.5171C34.035 34.567 33.9946 34.6074 33.9447 34.6073C33.8948 34.6073 33.8544 34.5669 33.8543 34.517C33.8543 34.4671 33.8947 34.4267 33.9446 34.4266C33.9686 34.4266 33.9916 34.4361 34.0086 34.4531C34.0256 34.4701 34.0351 34.4931 34.0351 34.5171"
          stroke="#F72585"
        />
        <path
          d="M29.5165 34.5171C29.5165 34.567 29.476 34.6074 29.4261 34.6073C29.3762 34.6073 29.3358 34.5669 29.3358 34.517C29.3358 34.4671 29.3762 34.4267 29.426 34.4266C29.4501 34.4266 29.4731 34.4361 29.4901 34.4531C29.507 34.4701 29.5166 34.4931 29.5165 34.5171"
          stroke="#F72585"
        />
      </svg>
    </SvgIcon>
  );
};
export const ThumpsUpCircle = ({ width = '45px', height = '44px' }) => {
  return (
    <SvgIcon
      sx={{
        padding: '0px',
        width: useWindowWidth() >= 1200 ? '75px' : '45px',
        height: useWindowWidth() >= 1200 ? '75px' : '45px',
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.2928 19.2758V16.5282C25.2928 15.2258 24.5569 14.0352 23.392 13.4528L23.3658 13.4397C22.5269 13.0202 21.5065 13.3506 21.0727 14.1822L18.4156 19.2758H15.063C14.066 19.2758 13.2578 20.084 13.2578 21.081V27.7861C13.2578 28.7831 14.066 29.5913 15.063 29.5913H27.5224C28.7936 29.5913 29.8937 28.7071 30.167 27.4656L31.2456 22.5657C31.4219 21.7645 31.2255 20.9266 30.7116 20.2871C30.1976 19.6477 29.4214 19.2758 28.601 19.2758H25.2928Z"
          stroke="#F72585"
        />
        <path d="M18.4026 19.2754V29.5909" stroke="#F72585" />
        <circle cx="22.2831" cy="22.2831" r="16.2469" stroke="#F72585" />
      </svg>
    </SvgIcon>
  );
};
export const DeliveryIcon = ({
  fill = '#000',
  width = '24',
  height = '24',
}) => (
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.704 18.796C19.323 19.415 19.323 20.418 18.704 21.036C18.085 21.655 17.082 21.655 16.464 21.036C15.845 20.417 15.845 19.414 16.464 18.796C17.083 18.177 18.086 18.177 18.704 18.796" stroke={fill} />
<path d="M7.704 18.7959C8.323 19.4149 8.323 20.4179 7.704 21.0359C7.085 21.6549 6.082 21.6549 5.464 21.0359C4.846 20.4169 4.845 19.4139 5.464 18.7959C6.083 18.1779 7.085 18.1769 7.704 18.7959" stroke={fill} />
<path d="M10 4.5H14C14.552 4.5 15 4.948 15 5.5V15.5H2" stroke={fill} />
<path d="M5 19.916H3C2.448 19.916 2 19.468 2 18.916V13.5" stroke={fill} />
<path d="M15 7.5H19.323C19.732 7.5 20.1 7.749 20.251 8.129L21.857 12.143C21.951 12.379 22 12.631 22 12.885V18.833C22 19.385 21.552 19.833 21 19.833H19.169" stroke={fill} />
<path d="M16 19.9199H8.17004" stroke={fill} />
<path d="M22 14.5H18V10.5H21.2" stroke={fill} />
<path d="M2 4.5H7" stroke={fill} />
<path d="M2 7.5H5" stroke={fill} />
<path d="M3 10.5H2" stroke={fill} />
</svg>

);
export const Drones=({fill="#F72585"})=>(
<svg width="80" height="80" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="47.9951" y="47.4949" width="25.0104" height="25.0104" rx="2" stroke="#F72585" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M50.4381 49.9376L35.4902 34.9897" stroke="#F72585" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M47.9951 34.9896C47.9964 29.0236 43.7831 23.8875 37.932 22.7225C32.0808 21.5574 26.2215 24.6878 23.9375 30.1993C21.6534 35.7107 23.5807 42.0681 28.5406 45.3835C33.5006 48.6988 40.1118 48.0487 44.3311 43.8308" stroke="#F72585" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M70.5625 49.9376L85.5104 34.9897" stroke="#F72585" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M85.5106 47.4949C91.4765 47.4961 96.6126 43.2828 97.7777 37.4317C98.9427 31.5806 95.8123 25.7213 90.3009 23.4372C84.7894 21.1531 78.432 23.0804 75.1167 28.0404C71.8013 33.0004 72.4514 39.6116 76.6694 43.8308" stroke="#F72585" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M70.5625 70.0625L85.5104 85.0104" stroke="#F72585" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M85.5106 72.5054C91.4765 72.5041 96.6126 76.7174 97.7777 82.5685C98.9427 88.4196 95.8123 94.2789 90.3009 96.563C84.7894 98.8471 78.432 96.9198 75.1167 91.9598C71.8013 86.9998 72.4514 80.3886 76.6694 76.1694" stroke="#F72585" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M50.4381 70.0625L35.4902 85.0104" stroke="#F72585" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M47.9951 85.0106C47.9964 90.9765 43.7831 96.1126 37.932 97.2777C32.0808 98.4427 26.2215 95.3123 23.9375 89.8009C21.6534 84.2894 23.5807 77.932 28.5406 74.6167C33.5006 71.3013 40.1118 71.9514 44.3311 76.1694" stroke="#F72585" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);
export const Flexibility=({fill="#F72585"})=>(
<svg width="80" height="80" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M35.5 64.1667V47.5H68.8333V64.1667" stroke="#F72585" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M68.8337 47.4999H73.0003V30.8333H39.667V47.4999" stroke="#F72585" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M84.7848 69.0483C91.2935 75.5571 91.2935 86.1098 84.7848 92.6185C78.276 99.1273 67.7233 99.1273 61.2146 92.6185C54.7058 86.1098 54.7058 75.557 61.2146 69.0483C67.7233 62.5396 78.2761 62.5396 84.7848 69.0483" stroke="#F72585" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M35.5 80.8333V97.4999H73" stroke="#F72585" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M72.9997 64.1667H31.333V80.8334H56.333" stroke="#F72585" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  );