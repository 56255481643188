import React, { useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import PinkButton from '../../Buttons/PinkButton';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { makePayment } from '../../../store/slices/paymentSlice';
import VisaIcon from '../../../images/cards/visa.png';
import MasterCardIcon from '../../../images/cards/mastercard-icon.png';
import MaestroIcon from '../../../images/cards/maestro.png';
import AmericanIcon from '../../../images/cards/american-express.svg';
import { fonts } from '../../../fonts';

import { FormattedMessage } from 'react-intl';
import useWindowWidth from '../../../utils/screenSize';
import CircularProgress from '@mui/material/CircularProgress';
const cardStyle = {
  style: {
    base: {
      color: '#2fae21',
      ...fonts.fontInter24,
      '::placeholder': {
        color: '#aab7c4',
        fontSize: '16px',
      },
      lineHeight: '40px',
      padding: '10px 12px',
    },
    invalid: {
      color: '#f74724',
      iconColor: '#ea4c2d',
    },
  },
};
const StripePaymentMemoryCard = ({
  // totalPrice,
  payment_type,
  order_id_from_page,
}) => {
  const windowSize = useWindowWidth();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { isFetching } = useSelector((state) => state.payment);
  const [error, setError] = useState(null);
  const [cardType, setCardType] = useState(null);

  // const cardWidth = useWindowWidth() >= 1200 ? '60%' : 'calc(100% - 100px)';
  const padding = useWindowWidth() >= 1200 ? '30px 50px 30px 30px':'20px 5px 20px 10px';
  const handleCardNumberChange = (event) => {
    if (event.brand) {
      setCardType(event.brand);
    }
  };
  const getCardIcon = (cardType) => {
    switch (cardType) {
      case 'visa':
        return (
          <img src={VisaIcon} alt="Visa" style={{ width: 50, height: 30 }} />
        );
      case 'mastercard':
        return (
          <img
            src={MasterCardIcon}
            alt="MasterCard"
            style={{ width: 50, height: 30 }}
          />
        );
      case 'maestro':
        return (
          <img
            src={MaestroIcon}
            alt="MaestroCard"
            style={{ width: 50, height: 30 }}
          />
        );
      case 'amex':
        return (
          <img
            src={AmericanIcon}
            alt="AmexCard"
            style={{ width: 50, height: 30 }}
          />
        );

      default:
        return null;
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
    });

    console.log('paymentMethod', paymentMethod);
    if (error) {
      setError(error.message);
    } else {
      const jsonData = localStorage.getItem('order_id');
      let order_id = JSON.parse(jsonData);
      setError(null);
      dispatch(
        makePayment({
          // amount: totalPrice * 100,
          payment_method_id: paymentMethod.id,
          payment_type: payment_type,
          order: order_id_from_page ? order_id_from_page : order_id,
          
        })
      );
    }
  };
  if (isFetching) {
    return (
      <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <Stack gap="20px" >
        <Stack
          sx={{
            width: '100%',
            minWidth:'310px',
            maxWidth: '420px',
            border: '1px solid #4361EE33',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            padding: padding,
          }}
        >
          <Stack>
            <label style={{ ...fonts.fontInter20 }}>
              <FormattedMessage id={'card_number'} />
              <CardNumberElement
                options={{
                  ...cardStyle,
                  placeholder: '1234 1234 1234 1234',
                }}
                onChange={handleCardNumberChange}
              />
            </label>
            {cardType && getCardIcon(cardType)}
          </Stack>
          <Stack
            direction="row"
            sx={{ width: '100%', gap: windowSize > 1200 ? '150px' : '120px' }}
          >
            <Stack>
              <label style={{ ...fonts.fontInter20 }}>
                <FormattedMessage id={'expiry_date'} />
                <CardExpiryElement
                  options={{
                    ...cardStyle,
                    placeholder: 'MM / YY',
                  }}
                />
              </label>
            </Stack>
            <Stack>
              <label style={{ ...fonts.fontInter20 }}>
                CVC
                <CardCvcElement
                  options={{
                    ...cardStyle,
                    placeholder: 'CVC',
                  }}
                />
              </label>
            </Stack>
          </Stack>
        </Stack>

        {error && <div style={{ color: 'red' }}>{error}</div>}
        <PinkButton
          type="submit"
          text={<FormattedMessage id={'pay'} />}
          width={'237px'}
          height={'72px'}
        />
      </Stack>
    </form>
  );
};

export default StripePaymentMemoryCard;
