import React from 'react';
import PageTitle from '../PageTitle/PageTitle';
import { Stack, Box } from '@mui/material';
import { sectionsStyle } from '../../styles';
import { fonts } from '../../fonts';
import useWindowWidth from '../../utils/screenSize';
import bg from '../../images/repair_page_title.png'
import { FormattedMessage } from 'react-intl';
const bgStyle={
  backgroundImage:`url(${bg})`,
  backgroundPosition:'220% center',
  backgroundSize:'70%',
  backgroundRepeat:'no-repeat'
}
const RepairStatistic = () => {
  return (
    <>
      {useWindowWidth() >= 1200 ? (
       <Stack sx={{alignItems:'center',width:'100%',...bgStyle}}>
         <Stack sx={{width:'calc(100% - 40px)',maxWidth:'1600px',gap:'100px'}}>
         <PageTitle text={<FormattedMessage id={"we_will_definitely"}/>} optionStyle={{fontSize:'70px'}}/>
         <Stack direction="row" sx={{maxWidth:'1300px',justifyContent:'space-between'}}>
           <Stack direction="column" gap="36px" sx={{ alignItems: 'center' }}>
             <Stack sx={{alignItems:'center'}}>
               <Box sx={{ ...fonts.fontPoppins80 }}>70%</Box>
               <Box sx={{ ...fonts.fontInter18_600 }}><FormattedMessage id={"customers"}/></Box>
             </Stack>
             <Stack
               component="p"
               sx={{ ...fonts.fontInter18_400, lineHeight: '24px',width:'378px' }}
             >
               <FormattedMessage id={"they_contact_us_with"}/>
             </Stack>
           </Stack>
           <Stack direction="column" gap="36px" sx={{ alignItems: 'center' }}>
             <Stack sx={{alignItems:'center'}}>
               <Box sx={{ ...fonts.fontPoppins80 }}>20%</Box>
               <Box sx={{ ...fonts.fontInter18_600 }}><FormattedMessage id={"customers"}/></Box>
             </Stack>
             <Stack
               component="p"
               sx={{ ...fonts.fontInter18_400, lineHeight: '24px',width:'378px' }}
             >
             <FormattedMessage id={"come_with_more_serious_problems"}/>
             </Stack>
           </Stack>
           <Stack direction="column" gap="36px" sx={{ alignItems: 'center' }}>
             <Stack sx={{alignItems:'center'}}>
               <Box sx={{ ...fonts.fontPoppins80 }}>10%</Box>
               <Box sx={{ ...fonts.fontInter18_600 }}><FormattedMessage id={"customers"}/></Box>
             </Stack>
             <Stack
               component="p"
               sx={{ ...fonts.fontInter18_400, lineHeight: '24px',width:'378px' }}
             >
           <FormattedMessage id={"hopeless"}/>
             </Stack>
           </Stack>
         </Stack>
              </Stack>
       </Stack>
      ) : (
        <Stack sx={{ ...sectionsStyle }}>
          <PageTitle text={<FormattedMessage id={"we_will_definitely"}/>} />
          <Stack direction="row" gap="36px" sx={{ alignItems: 'center' }}>
            <Stack>
              <Box sx={{ ...fonts.fontPoppins50 }}>70%</Box>
              <Box sx={{ ...fonts.fontInter16_600 }}><FormattedMessage id={"customers"}/></Box>
            </Stack>
            <Stack
              component="p"
              sx={{ ...fonts.fontInter16_400, lineHeight: '24px' }}
            >
              <FormattedMessage id={"they_contact_us_with"}/>
            </Stack>
          </Stack>
          <Stack direction="row" gap="36px" sx={{ alignItems: 'center' }}>
            <Stack>
              <Box sx={{ ...fonts.fontPoppins50 }}>20%</Box>
              <Box sx={{ ...fonts.fontInter16_600 }}><FormattedMessage id={"customers"}/></Box>
            </Stack>
            <Stack
              component="p"
              sx={{ ...fonts.fontInter16_400, lineHeight: '24px' }}
            >
              <FormattedMessage id={"come_with_more_serious_problems"}/>
            </Stack>
          </Stack>
          <Stack direction="row" gap="36px" sx={{ alignItems: 'center' }}>
            <Stack>
              <Box sx={{ ...fonts.fontPoppins50 }}>10%</Box>
              <Box sx={{ ...fonts.fontInter16_600 }}><FormattedMessage id={"customers"}/></Box>
            </Stack>
            <Stack
              component="p"
              sx={{ ...fonts.fontInter16_400, lineHeight: '24px' }}
            >
              <FormattedMessage id={"hopeless"}/>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default RepairStatistic;
