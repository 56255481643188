import React from 'react';
import { primary } from '../../colors';
import { fonts } from '../../fonts';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CookieConsent } from 'react-cookie-consent';

const CustomCookieConsent = ({ intl }) => {
  return (
    <CookieConsent
        location="bottom"
        buttonText={intl.formatMessage({ id: 'i_understand' })}
        cookieName="myAwesomeCookieName2"
        style={{
          display:'flex',
          flexWrap:'wrap',
          flexDirection:'row',
          background: primary.blue,
          // height:"75px",
          ...fonts.fontInter18_400,}}
        buttonStyle={{ 
          color:"#fff",
           ...fonts.fontInter24,
           backgroundColor: primary.pink,
           borderRadius:"20px",
           padding:"5px 20px"
          }}
        expires={150}
      >
        <FormattedMessage id={"cookies_message"}/>
      </CookieConsent>
  );
}

export default injectIntl(CustomCookieConsent);
