import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { Box, Stack } from '@mui/material';
import { SwiperNavButtons } from './SliderBtns';
import PageTitle from '../PageTitle/PageTitle';
import { sectionsStyle } from '../../styles';

const Slider = () => {
  return (
    <Stack sx={{...sectionsStyle}}>
      <PageTitle text={'Video footage captured by our drones'}/>
      <Box>
        <Swiper
          style={{
            width:'100%',
            height: '360px',
          }}
          loop={true}
          modules={[Navigation]}
          spaceBetween={30}
          slidesPerView={1}
        >
          <SwiperSlide style={{width:'100%'}}>
            <div>
              <iframe
                width="350"
                height="300"
                src="https://www.youtube.com/embed/jNoyEScYWqs?si=5ZBwHNqrEqZSJdwZ"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <iframe
                width="350"
                height="300"
                src="https://www.youtube.com/embed/ywSrJFNuDGs?si=IXQP51IuaSkiKjoD"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div>
          </SwiperSlide>
          <SwiperSlide >
            <div>
              <iframe
                width="350"
                height="300"
                src="https://www.youtube.com/embed/NeQM1c-XCDc?si=x1SF34ZUBMZlDRik"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div>
          </SwiperSlide>
          <SwiperNavButtons />
        </Swiper>
        
      </Box>
    </Stack>
  );
};

export default Slider;
