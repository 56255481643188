
import React,{useEffect} from 'react';
import Header from '../../components/Header/Header.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import TitleSection from '../../components/HomePageSections/TitleSection';
import ProductsSection from '../../components/HomePageSections/ProductsSection';
import OurServicesSection from '../../components/HomePageSections/OurServicesSection';
import RepairDrones from '../../components/HomePageSections/RepairDrones';
import { Stack } from '@mui/material';
import ChooseDrone from '../../components/HomePageSections/ChooseDrone';
import OurBlog from '../../components/HomePageSections/OurBlog';
import  Rating  from '../../components/HomePageSections/Rating';
import FAQ from '../../components/FAQ/FAQ';
import Slider from '../../components/Slider/Slider';
import Slider2 from '../../components/Slider2/Slider2';
import Slider3 from '../../components/Slider3/Slider3';
import Slider4 from '../../components/Slider4/Slider4';
import WorkingWithUs from '../../components/HomePageSections/WorkingWithUs';
import RatingHD from '../../components/HomePageSections/RatingHD/RatingHD';
import useWindowWidth from '../../utils/screenSize';
import {getReviewsList} from '../../store/slices/reviews_faqs.js'
import { useSelector,useDispatch } from 'react-redux';
import star from '../../images/TrustpilotStarBg.png';
import starHalf from '../../images/TrustpilotStarBgHalf.png';
const Home = (props) => {
  const dispatch=useDispatch();
  const {reviews}=useSelector((state) => state.reviews)
  const { locale } = useSelector((state) => state.languages);
  useEffect(()=>{
    dispatch(getReviewsList(locale))
  },[])
  const getStars = (rated) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rated) {
        stars.push(star);
      } else if (i - 0.5 <= rated) {
        stars.push(starHalf);
      } else {
        stars.push(null);
      }
    }
    return stars;
  };
  const calculateAverageRating = (reviews) => {
    if (reviews.length === 0) return 0;
    const total = reviews.reduce((sum, review) => sum + parseFloat(review.rated), 0);
    return total / reviews.length;
  };
  const averageRating = calculateAverageRating(reviews);
  const starArr = getStars(averageRating);
  return (
    <>
      <Header />
      <Stack gap="40px" mt={'84px'} sx={{}}>
        <TitleSection />
        {useWindowWidth()<1200&&<OurServicesSection/>}
        <ProductsSection />
        <RepairDrones/>
        <WorkingWithUs/>
        {useWindowWidth()>=1200?<Slider3/>:<Slider2/>}
        {useWindowWidth()>=1200&&<RatingHD reviews={reviews} starArr={starArr} averageRating={averageRating}/>}
        <FAQ/>
        {useWindowWidth()<1200&&<Rating starArr={starArr} averageRating={averageRating}/>}
        <ChooseDrone/>
        {useWindowWidth()>=1200?<Slider4/>:<Slider/>}
        
        <OurBlog/>
      </Stack>
      <Footer />
    </>
  );
};
export default Home;
