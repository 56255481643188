import React, { useState } from 'react';
import { Box, Stack, IconButton, MenuItem, Menu, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { CardImg } from '../Icons/Icons';
import PinkButton from '../Buttons/PinkButton';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { fonts } from '../../fonts';
import { primary } from '../../colors';
import pictureMenu1 from '../../images/menuHeaderHd/myOrders.png';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import RegistrationIndicator from '../RegistrationIndicator/RegistrationIndicator';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useDispatch } from 'react-redux';
import { setIsRegister } from '../../store/slices/usersSlice.js';
import { useScroll } from '../ScrollContext/ScrollContext';
import logo from '../../images/drone_logo.svg';
import { FormattedMessage } from 'react-intl';

const HeaderHD = ({ indicator }) => {
  const { scrollToSection } = useScroll();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  // console.log('indicator',indicator);
  const exitProfile = () => {
    handleClose1();
    localStorage.clear();
    dispatch(setIsRegister(false));
    navigate('/');
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  return (
    <>
      <Stack
        component="header"
        gap="70px"
        sx={{
          position: 'fixed',
          zIndex: '1000',
          backgroundColor: 'white',
          top: '0px',
          left: '0px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
          paddingTop: '10px',
          paddingBottom: '10px',
        }}
      >
        <Stack
          sx={{
            maxWidth: '1600px',
            width: 'calc(100% - 40px)',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Link
            to="/"
            style={{
              textDecoration: 'none',
              color: '#000',
              ...fonts.fontPoppins50,
            }}
          >
            <img src={logo} alt="" width="250px" />
          </Link>
          <Box
            component="nav"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '20px',
            }}
          >
            <Link
              to="/catalog"
              style={{
                textDecoration: 'none',
                color: '#000',
                ...fonts.fontInter18_500,
              }}
            >
              <FormattedMessage id="catalog" />
            </Link>
            <Box
              onClick={() => {
                navigate('/#scrollToRef');
                scrollToSection('RepairDrones');
              }}
              style={{
                textDecoration: 'none',
                color: '#000',
                ...fonts.fontInter18_500,
                cursor: 'pointer',
              }}
            >
              <FormattedMessage id="how_it_work" />
            </Box>
            <Link
              to="/repair"
              style={{
                textDecoration: 'none',
                color: '#000',
                ...fonts.fontInter18_500,
              }}
            >
              <FormattedMessage id="drone_repair" />
            </Link>
          </Box>

          <RegistrationIndicator />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '20px',
              alignItems: 'center',
            }}
          >
            <LanguageSelector />
            <Link to="/cart">
              <IconButton
                sx={{ padding: '0px', width: '58px', height: '60px' }}
              >
                <CardImg />
              </IconButton>
            </Link>

            {indicator ? (
              <Button
                sx={{
                  width: '237px',
                  height: '60px',
                  textTransform: 'none',
                  color: '#fff',
                  backgroundColor: primary.pink,
                  borderRadius: '100px',
                  '&:hover': {
                    backgroundColor: primary.violet,
                  },
                  ...fonts.fontInter18_600,
                }}
                aria-controls={open1 ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
                onClick={handleClick1}
              >
                <FormattedMessage id="profile" />
              </Button>
            ) : (
              <Button
                sx={{
                  width: '237px',
                  height: '60px',
                  textTransform: 'none',
                  color: '#fff',
                  backgroundColor: primary.pink,
                  borderRadius: '100px',
                  '&:hover': {
                    backgroundColor: primary.violet,
                  },
                  ...fonts.fontInter18_600,
                }}
                aria-controls={open1 ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
                onClick={handleClick1}
              >
               <FormattedMessage id="log_in" />
              </Button>
            )}
            <Menu
              disableScrollLock={true}
              id="basic-menu"
              anchorEl={anchorEl1}
              open={open1}
              onClose={handleClose1}
              PaperProps={{
                style: {
                  display: 'flex',
                  gap: '30px',
                  // width: '43%',
                  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                  borderRadius: '8px',
                  zIndex: 1000,
                },
              }}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 80, left: 1700 }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {indicator ? (
                <div>
                  <MenuItem
                    onClick={handleClose1}
                    sx={{ ...fonts.fontInter18_500 }}
                  >
                    <Link
                      to="/profile"
                      style={{
                        textDecoration: 'none',
                        color: '#000',
                        display: 'flex',
                        gap: '20px',
                        alignItems: 'center',
                      }}
                    >
                      <PersonOutlineOutlinedIcon sx={{ fontSize: '24px' }} />
                      <span><FormattedMessage id="profile_settings" /></span>
                    </Link>
                  </MenuItem>

                  <MenuItem
                    onClick={handleClose1}
                    sx={{ ...fonts.fontInter18_500, margin: '15px 0px' }}
                  >
                    <Link
                      to="/my-order-list"
                      style={{
                        textDecoration: 'none',
                        color: '#000',
                        display: 'flex',
                        gap: '20px',
                      }}
                    >
                      <img src={pictureMenu1} alt="" />
                      <span><FormattedMessage id="my_orders"/></span>
                    </Link>
                  </MenuItem>

                  <MenuItem
                    onClick={exitProfile}
                    sx={{ ...fonts.fontInter18_500, margin: '15px 0px' }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        textDecoration: 'none',
                        color: '#000',
                        display: 'flex',
                        gap: '20px',
                      }}
                    >
                      <ExitToAppIcon />
                      <span><FormattedMessage id="exit"/></span>
                    </Stack>
                  </MenuItem>
                </div>
              ) : (
                <div>
                  <MenuItem
                    onClick={handleClose1}
                    sx={{ ...fonts.fontInter24 }}
                  >
                   <FormattedMessage id="welcome_to"/>
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose1}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '25px',
                      // justifyContent: 'space-between',
                    }}
                  >
                    <Link to="/registration" sx={{ width: '50%' }}>
                      <PinkButton
                        text={<FormattedMessage id="registration" />}
                        width={'384px'}
                        height={'72px'}
                      />
                    </Link>
                    <Link to="/login">
                      <PinkButton
                        text={<FormattedMessage id="log_in" />}
                        width={'364px'}
                        height={'72px'}
                        optionStyle={{
                          color: primary.pink,
                          backgroundColor: '#fff',
                          border: `2px solid ${primary.pink}`,
                        }}
                      />
                    </Link>
                  </MenuItem>
                </div>
              )}
            </Menu>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default HeaderHD;
