import React from 'react';
import { Stack, Box } from '@mui/material';
import { fonts } from '../../fonts';
import { ThumpsUpCircle, CalculatorShop, Drones,Flexibility } from '../Icons/Icons';
import useWindowWidth from '../../utils/screenSize';
import { FormattedMessage } from 'react-intl';




const OurServices = () => {
  const fontsStyle =
  useWindowWidth() >= 1200 ? fonts.fontInter24 : fonts.fontInter10_600;
  const boxStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent:'center',
    gap: '12px',
    border: '1px solid rgba(67, 97, 238, 0.3)',
    borderRadius: '5px',
    padding: '13px 0px',
    width: useWindowWidth() >= 1200 ? '381px' : '165px',
    height: useWindowWidth() >= 1200 ? '230px' : '110px',
  };
  const boxTextStyles = {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: fontsStyle,
    alignItems: 'center',
    gap: '6px',
  };

  return (
    <Stack sx={{ flexWrap: 'wrap', flexDirection: 'row', gap: '10px' }}>
      <Box
        sx={{
          ...boxStyles,
        }}
      >
        <Drones/>
        <Box sx={{ ...boxTextStyles }}>
          <span>100+</span>
          <FormattedMessage id="drones_counting" />
        </Box>
      </Box>
      <Box
        sx={{
          ...boxStyles,
        }}
      >
        <CalculatorShop />
        <Box sx={{ ...boxTextStyles }}>
        <span>
          <FormattedMessage id="affordable"/>
        </span>
        <FormattedMessage id="a_services"/>
        </Box>
      </Box>
      <Box
        sx={{
          ...boxStyles,
        }}
      >
        <ThumpsUpCircle />
        <Box sx={{ ...boxTextStyles }}>
          <span>1000+</span>
          <FormattedMessage id="users_counting"/>
        </Box>
      </Box>
      <Box
        sx={{
          ...boxStyles,
        }}
      >
        <Flexibility/>
        <Box sx={{ ...boxTextStyles }}>
        <span><FormattedMessage id="flexibility"/></span> <FormattedMessage id="convenience"/>
        </Box>
      </Box>
    </Stack>
  );
};

export default OurServices;
