import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { submitRepair } from '../../../store/slices/repairsSlice.js';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { OrderRepairSchema } from '../../../utils/validationSchems.js';
import styles from './RepairFormOrder.module.scss';
import PinkButton from '../../Buttons/PinkButton';
import PageTitle from '../../PageTitle/PageTitle';
import { useParams } from 'react-router-dom';
import useWindowWidth from '../../../utils/screenSize';
import { Stack, Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

const RepairFormOrder = ({ intl }) => {
  const { title } = useParams();
  console.log('title',title);
  let type_repair;

  if (title === 'Full diagnosis') {
    type_repair = 'FD';
} else if (title === 'Replacement of body parts') {
    type_repair = 'RB';
} else if (title === 'Replacement of the chassis, turning mechanisms, beams with motors') {
    type_repair = 'RC';
} else if (title === 'Recovery from difficult falls') {
    type_repair = 'RF';
} else if (title === 'Replacement of drone boards, sensors and modules') {
    type_repair = 'RDB';
} else if (title === 'Recovery after moisture') {
    type_repair = 'RAM';
} else if (title === 'Updating the drone software') {
    type_repair = 'SU';
} else if (title === 'Quadcopter remote control repair') {
    type_repair = 'RCR';
} else {
    type_repair = 'GR';  // Значение по умолчанию, если текст не соответствует ни одному из условий
}
  const [selectedFileCount, setSelectedFileCount] = useState('');
  const dispatch = useDispatch();
  const initialValues = {
    name: '',
    phone: '',
    city: '',
    address: '',
    comment: '',
    files: [],
    
  };
  const handleFileChange = (event) => {
    const { files } = event.target;
    setSelectedFileCount(`${files.length} file(s) selected`);
  };

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('phone', values.phone);
    formData.append('address', values.address);
    formData.append('city', values.city);
    formData.append('comment', values.comment);
    formData.append('type', type_repair);

    if (values.files && values.files.length > 0) {
      for (let i = 0; i < values.files.length; i++) {
        formData.append(`files[${i}]`, values.files[i]);
      }
    }
    // formData.append('repair_type', title);
    formData.forEach((value, key) => {
      console.log(`FormData[${key}]:`, value);
    });

    dispatch(submitRepair(formData));
    setSelectedFileCount('');
    setSubmitting(false);
    resetForm();
  };

  return useWindowWidth() >= 1200 ? (
    <div className={styles.form}>
      <PageTitle
        text={<FormattedMessage id={title}/>}
        optionStyle={{ marginBottom: '32px', fontSize: '80px' }}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={OrderRepairSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form style={{ width: '100%' }}>
            <Stack direction="row" gap="32px">
              <Box
                sx={{
                  width: '100%',
                  maxWidth: '794px',
                }}
              >
                <Field
                  type="text"
                  id="name"
                  name="name"
                  placeholder={intl.formatMessage({ id: 'name' })}
                  style={{ height: '72px', borderRadius: '100px' ,borderColor:'rgba(67, 97, 238, 0.2)'}}
                />
               <ErrorMessage name="name">
                {(msg) => (
                  <div className={styles.error}>
                    <FormattedMessage id={msg} />
                  </div>
                )}
              </ErrorMessage>
              </Box>
              <Box sx={{ width: '100%', maxWidth: '794px' }}>
                <Field
                  type="phone"
                  id="phone"
                  name="phone"
                  placeholder={intl.formatMessage({ id: 'phone' })}
                  style={{ height: '72px', borderRadius: '100px',borderColor:'rgba(67, 97, 238, 0.2)' }}
                />
               <ErrorMessage name="phone">
                {(msg) => (
                  <div className={styles.error}>
                    <FormattedMessage id={msg} />
                  </div>
                )}
              </ErrorMessage>
              </Box>
            </Stack>
            <Stack direction="row" gap="32px">
              <Box sx={{ width: '100%', maxWidth: '794px' }}>
                <Field
                  type="text"
                  id="city"
                  name="city"
                  placeholder={intl.formatMessage({ id: 'city' })}
                  style={{ height: '72px', borderRadius: '100px',borderColor:'rgba(67, 97, 238, 0.2)' }}
                />
                <ErrorMessage
                  name="city"
                  component="div"
                  className={styles.error}
                />
              </Box>
              <Box sx={{ width: '100%', maxWidth: '794px' }}>
                <Field
                  type="text"
                  id="address"
                  name="address"
                  placeholder={intl.formatMessage({ id: 'address' })}
                  style={{ height: '72px', borderRadius: '100px',borderColor:'rgba(67, 97, 238, 0.2)' }}
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className={styles.error}
                />
              </Box>
            </Stack>
            <Stack direction="row" gap="32px">
              <div style={{width: '100%'}}>
                <Field
                  type="text"
                  id="comment"
                  name="comment"
                  placeholder={intl.formatMessage({ id: 'comment' })}
                  style={{ height: '72px', borderRadius: '100px',borderColor:'rgba(67, 97, 238, 0.2)' }}
                />
                <ErrorMessage
                  className={styles.error}
                  name="comment"
                  component="div"
                />
              </div>
              {/* <div style={{width: '100%', maxWidth: '794px' }}>
              
              </div> */}
            </Stack>
            <PageTitle
              text={<FormattedMessage id={"photo_breakdown"}/>}
              optionStyle={{ margin: '32px 0px' }}
            />
            <div className={styles.fileInputContainer}>
              <label htmlFor="files" className={styles.fileInputLabel}>
                <span className={styles.icon}>+</span>
                <span className="selected-file-count">{selectedFileCount}</span>
              </label>
              <input
                className={styles.fileInput}
                type="file"
                id="files"
                name="files"
                multiple
                accept="image/*"
                onChange={(event) => {
                  handleFileChange(event);
                  const filesArray = Array.from(event.currentTarget.files);
                  setFieldValue('files', filesArray);
                }}
              />
              <ErrorMessage name="files" component="div" className="error" />
            </div>

            <PinkButton
              type="submit"
              text={<FormattedMessage id={"send"}/>}
              width={'237px'}
              height={'72px'}
              optionStyle={{ margin: '10px' }}
            />
          </Form>
        )}
      </Formik>
    </div>
  ) : (
    <div className={styles.form}>
      <PageTitle text={<FormattedMessage id={title}/>} optionStyle={{ marginBottom: '32px' }} />
      <Formik
        initialValues={initialValues}
        validationSchema={OrderRepairSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form style={{ width: '100%' }}>
            <div>
              <Field type="text" id="name" name="name" placeholder={intl.formatMessage({ id: 'name' })} />
              <ErrorMessage
                className={styles.error}
                name="name"
                component="div"
              />
            </div>

            <div>
              <Field type="phone" id="phone" name="phone" placeholder={intl.formatMessage({ id: 'phone' })} />
              <ErrorMessage
                name="phone"
                component="div"
                className={styles.error}
              />
            </div>
            <div>
              <Field type="text" id="city" name="city" placeholder={intl.formatMessage({ id: 'city' })} />
              <ErrorMessage
                name="city"
                component="div"
                className={styles.error}
              />
            </div>
            <div>
              <Field
                type="text"
                id="address"
                name="address"
                placeholder={intl.formatMessage({ id: 'address' })}
              />
              <ErrorMessage
                name="address"
                component="div"
                className={styles.error}
              />
            </div>
            <div>
              <Field
                type="text"
                id="comment"
                name="comment"
                placeholder={intl.formatMessage({ id: 'comment' })}
              />
              <ErrorMessage
                className={styles.error}
                name="comment"
                component="div"
              />
            </div>
            <PageTitle
              text={<FormattedMessage id={"photo_breakdown"}/>}
              optionStyle={{ margin: '32px 0px' }}
            />
            <div className={styles.fileInputContainer}>
              <label htmlFor="files" className={styles.fileInputLabel}>
                <span className={styles.icon}>+</span>
                <span className="selected-file-count">{selectedFileCount}</span>
              </label>
              <input
                className={styles.fileInput}
                type="file"
                id="files"
                name="files"
                multiple
                accept="image/*"
                onChange={(event) => {
                  handleFileChange(event);
                  const filesArray = Array.from(event.currentTarget.files);
                  setFieldValue('files', filesArray);
                }}
              />
              <ErrorMessage name="files" component="div" className="error" />
            </div>

            <PinkButton
              type="submit"
              text={<FormattedMessage id={"send"}/>}
              width={'168px'}
              height={'46px'}
              optionStyle={{ margin: '10px' }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default injectIntl(RepairFormOrder) ;
