import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as restController from '../../api/rest/restController';
import { pendingReducer, rejectedReducer } from '../../utils/store';

const PRODUCT_SLICE_NAME = 'products';
const initialState = {
  products: [],
  product: [],
  memory_card: [],
  memory_card_in_cart: [],
  cart: [],
  isLicensePilot:false,
  totalSumCart: 0,
  totalSumMemoryCard: 0,
  totalDiscount: 0,
  error: null,
  isFetching: false,
  prices: {},
};

export const getProductList = createAsyncThunk(
  `${PRODUCT_SLICE_NAME}/getProductList`,
  async (params = {}, thunkAPI) => {
    try {
      const data = await restController.getProductList(params);
      console.log('getProductList',data.results);
      if(params){
        localStorage.setItem('sorted_drones', JSON.stringify(data.results))
      }
      return data;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);

export const getProduct = createAsyncThunk(
  `${PRODUCT_SLICE_NAME}/getProduct`,
  async (page_name, thunkAPI) => {
    try {
      const data = await restController.getProduct(page_name);
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue(error);
    }
  }
);

const extraReducers = (builder) => {
  builder.addCase(getProductList.pending, pendingReducer);
  builder.addCase(getProductList.fulfilled, (state, action) => {
    console.log(action);
    state.isFetching = false;
    state.products = action.payload.results;
    state.prices = action.payload.prices;
  });
  builder.addCase(getProductList.rejected, rejectedReducer);

  builder.addCase(getProduct.pending, pendingReducer);
  builder.addCase(getProduct.fulfilled, (state, action) => {
    state.isFetching = false;
    state.product = action.payload.product_content;
    state.memory_card = action.payload.memory_card;
  });
  builder.addCase(getProduct.rejected, rejectedReducer);
};
const productsSlice = createSlice({
  name: PRODUCT_SLICE_NAME,
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const existingProduct = state.cart.find(
        (product) => product.id === action.payload.id
      );
      if (existingProduct) {
        existingProduct.quantity += action.payload.quantity;
        
      } else {
        state.cart.push({ ...action.payload, quantity: 1 });
      }
      state.totalSumCart += action.payload.rentPrice;
      state.totalDiscount += action.payload.discount;
      localStorage.setItem('totalSumCart', state.totalSumCart);
      localStorage.setItem('productsInCart', JSON.stringify(state.cart));
      localStorage.setItem('totalDiscount', state.totalDiscount);
    },
    removeFromCart: (state, action) => {
      const removedProduct = state.cart.find(
        (product) => product.id === action.payload
      );
      if (removedProduct) {
        state.cart = state.cart.filter(
          (product) => product.id !== action.payload
        );
        if (state.cart.length === 0) {
          state.totalSumCart = 0;
          state.totalDiscount = 0;
          state.totalSumMemoryCard = 0;
          localStorage.removeItem('totalSumCart');
          localStorage.removeItem('totalDiscount');
          localStorage.removeItem('productsInCart');
          localStorage.removeItem('totalSumMemoryCard');
          localStorage.removeItem('memoryCardInCart');
        }
        if (state.cart.length > 0) {
          state.totalSumCart -=
            removedProduct.rentPrice * removedProduct.quantity;
          state.totalDiscount -=
            removedProduct.discount * removedProduct.quantity;
          localStorage.setItem('totalSumCart', state.totalSumCart);
          localStorage.setItem('totalDiscount', state.totalDiscount);
        }
      }
    },
    reWriteTotalSumCart: (state, action) => {
      state.totalSumCart = action.payload;
    },
    reWriteTotalDiscount: (state, action) => {
      state.totalDiscount = action.payload;
    },
 
    removeTotalSum: (state, action) => {
      const removedProduct = state.cart.find(
        (product) => product.id === action.payload.productID
      );
      console.log('removeTotalSum', action.payload.rentPrice);
      removedProduct.quantity -= 1;
      state.totalSumCart -= action.payload.rentPrice;
      state.totalDiscount -= action.payload.discount;
      localStorage.setItem('totalSumCart', state.totalSumCart);
      localStorage.setItem('totalDiscount', state.totalDiscount);
    },
    reWriteCart: (state, action) => {
      state.cart = action.payload;
    },

    addMemoryCard: (state, action) => {
      const existingProduct = state.memory_card_in_cart.find(
        (product) => product.id === action.payload.id
      );
      if (existingProduct) {
        existingProduct.quantity += action.payload.quantity;
      
      } else {
        state.memory_card_in_cart.push({ ...action.payload, quantity: 1 });
      }
      state.totalSumMemoryCard += action.payload.price;
      localStorage.setItem('totalSumMemoryCard', state.totalSumMemoryCard);
      localStorage.setItem(
        'memoryCardInCart',
        JSON.stringify(state.memory_card_in_cart)
      );
    },
    reWriteTotalSumCartMemoryCard: (state, action) => {
      state.totalSumMemoryCard = action.payload;
    },
    reWriteCardInCart: (state, action) => {
      state.memory_card_in_cart = action.payload;
    },
    removeTotalSumCard: (state, action) => {
      const removedProduct = state.memory_card_in_cart.find(
        (product) => product.id === action.payload.productID
      );
      removedProduct.quantity -= 1;
      state.totalSumMemoryCard -= action.payload.price;
      localStorage.setItem('totalSumMemoryCard', state.totalSumMemoryCard);
    },
    removeCardFromCart: (state, action) => {
      const removedProduct = state.memory_card_in_cart.find(
        (product) => product.id === action.payload
      );
      if (removedProduct) {
        state.memory_card_in_cart = state.memory_card_in_cart.filter(
          (product) => product.id !== action.payload
        );
        if (state.memory_card_in_cart.length === 0) {
          state.totalSumMemoryCard = 0;
          localStorage.removeItem('totalSumMemoryCard');
          localStorage.removeItem('memoryCardInCart');
        }
        if (state.memory_card_in_cart.length > 0) {
          state.totalSumMemoryCard -=
            removedProduct.price * removedProduct.quantity;
          localStorage.setItem('totalSumMemoryCard', state.totalSumCart);
        }
      }
    },
    changeIsLicensePilot:(state,action)=>{
     state.isLicensePilot=action.payload
    }
  },
  extraReducers,
});
export const {
  reWriteCart,
  addToCart,
  removeFromCart,
  removeTotalSum,
  reWriteTotalDiscount,
  reWriteTotalSumCart,
  addMemoryCard,
  addTotalSumCards,
  reWriteTotalSumCartMemoryCard,
  reWriteCardInCart,
  removeTotalSumCard,
  removeCardFromCart,
  changeIsLicensePilot
} = productsSlice.actions;
export default productsSlice.reducer;
