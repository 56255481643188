import React from 'react';
import { Stack, Box } from '@mui/material';
import { primary } from '../../colors';
import { fonts } from '../../fonts';
import { FormattedMessage } from 'react-intl';
import useWindowWidth from '../../utils/screenSize';
const OrderStatusSelector = ({ isSelect, setSelect, selectStatus }) => {
  let fontSize=useWindowWidth()>1200? fonts.fontInter24:fonts.fontInter18_600;
  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
      <Box
        sx={{
          color: isSelect === 'all' ? primary.blue : '#000',
          ...fontSize,
          textDecoration: isSelect === 'all' ? 'underline' : 'none',
          cursor:'pointer'
        }}
        onClick={() => setSelect(selectStatus.all)}
      >
        <FormattedMessage id="all" />
      </Box>
      <Box
        sx={{
          color: isSelect === 'atWork' ? primary.blue : '#000',
          ...fontSize,
          textDecoration: isSelect === 'atWork' ? 'underline' : 'none',
          cursor:'pointer'

        }}
        onClick={() => setSelect(selectStatus.atWork)}
      >
        <FormattedMessage id="at_work" />
      </Box>
      <Box
        sx={{
          color: isSelect === 'finished' ? primary.blue : '#000',
          ...fontSize,
          textDecoration: isSelect === 'finished' ? 'underline' : 'none',
          cursor:'pointer'

        }}
        onClick={() => setSelect(selectStatus.finished)}
      >
        <FormattedMessage id="finished" />
      </Box>
      <Box
        sx={{
          color: isSelect === 'cancelled' ? primary.blue : '#000',
          ...fontSize,
          textDecoration: isSelect === 'cancelled' ? 'underline' : 'none',
          cursor:'pointer'

        }}
        onClick={() => setSelect(selectStatus.cancelled)}
      >
        <FormattedMessage id="cancelled" />
      </Box>

      <Box
        sx={{
          color: isSelect === 'unpaid' ? primary.blue : '#000',
          ...fontSize,
          textDecoration: isSelect === 'unpaid' ? 'underline' : 'none',
          cursor:'pointer'

        }}
        onClick={() => setSelect(selectStatus.unpaid)}
      >
        <FormattedMessage id="unpaid" />
      </Box>
    </Stack>
  );
};

export default OrderStatusSelector;
