import React from 'react';
import CustomerOrder from '../CustomerOrder/CustomerOrder';
import { Stack } from '@mui/material';

const CustomerOrdersList = ({orders}) => {
  return (
   <Stack gap="30px">{orders.map((order,i)=>(<CustomerOrder order={order}  key={i}/>))}</Stack>
  );
}

export default CustomerOrdersList;
