import React from 'react';
import { Stack, Box } from '@mui/material';
import BreadcrumbsCustom from '../Breadcrumbs/Breadcrumbs';
import PageTitle from '../PageTitle/PageTitle';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { fonts } from '../../fonts';
import { primary } from '../../colors';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { setIsRegister } from '../../store/slices/usersSlice.js';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import OrderStatusSelector from '../OrderStatusSelector/OrderStatusSelector';
import CustomerOrdersList from '../CustomerOrdersList/CustomerOrdersList';
import PinkButton from '../Buttons/PinkButton';
import { FormattedMessage } from 'react-intl';
const linkStyle = {
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  width: '100%',
  maxWidth: '341px',
  height: '68px',
  borderRadius: '10px',
  color: '#000',
  ...fonts.fontInter18_400,
  transition: '0.5s',
  padding: '0px 20px',
  ':hover': {
    color: '#fff',
    backgroundColor: primary.blue,
    transition: '0.5s',
  },
};
const CustomerOrderHD = ({
  filteredOrders,
  isSelect,
  setSelect,
  selectStatus,
}) => {
  const { isRegister } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const exitProfile = () => {
    localStorage.clear();
    dispatch(setIsRegister(false));
    navigate('/');
  };
  return (
    <Stack sx={{ alignItems: 'center' }}>
      <Stack sx={{ width: 'calc(100% - 40px)', maxWidth: '1600px', gap: '50px' }}>
        <BreadcrumbsCustom pageName={ <FormattedMessage id="my_orders" />} />
        <PageTitle
          text={<FormattedMessage id="personal_office" />}
          optionStyle={{ fontSize: '40px' }}
        />
        <Stack direction="row" gap="200px" sx={{ flexWrap: 'wrap-reverse' }}>
          <Stack>
            <Link to={isRegister?"/profile":"/login"} style={{ ...linkStyle }}>
              <PersonOutlineOutlinedIcon /> <FormattedMessage id="my_profile" />
            </Link>
            <Link to={isRegister?"/my-order-list":"/login"} style={{ ...linkStyle }}>
              <GradingOutlinedIcon />
              <FormattedMessage id="my_orders" />
            </Link>
            <Stack direction="row" onClick={exitProfile} sx={{ ...linkStyle }}>
              {' '}
              <ExitToAppIcon />
              <span><FormattedMessage id="exit"/></span>
            </Stack>
          </Stack>
          <Stack sx={{ width: '100%', maxWidth: '1100px' }} gap="50px">
            <PageTitle text={<FormattedMessage id="my_orders"/>} optionStyle={{ fontSize: '80px' }} />{' '}
            <OrderStatusSelector
              isSelect={isSelect}
              setSelect={setSelect}
              selectStatus={selectStatus}
            />
            <Box component="p" sx={{ ...fonts.fontInter24 }}>
            <FormattedMessage id="order_information"/>
            </Box>
            <CustomerOrdersList orders={filteredOrders} />
         
              <PinkButton
                width={'50%'}
                height={'72px'}
                text={'Leave a review'}
                optionStyle={{
                  backgroundColor: '#fff',
                  color: primary.pink,
                  margin:'auto',
                  border: `1px solid ${primary.pink}`,
                }}
              />

            
           
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CustomerOrderHD;
