import React from 'react';
import { useSwiper } from 'swiper/react';
import styles from './SliderBtns4.module.scss';
import arrRight from '../../images/arrowSliderRight.svg';
import arrLeft from '../../images/arrowSliderLeft.svg';
import { Stack } from '@mui/material';

export const SwiperNavButtons4 = () => {
  const swiper = useSwiper();

  return (
    <Stack direction='row' sx={{width:'100%'}}>
  
        <img src={arrLeft} alt=""    className={styles.swiper_nav_btns_button1}
        onClick={() => swiper.slidePrev()}/>
    
     
        <img src={arrRight} alt="" className={styles.swiper_nav_btns_button2}
        onClick={() => swiper.slideNext()}/>
     
    </Stack>
  );
};
