import React from 'react';
import { Stack, Box } from '@mui/material';
import { fonts } from '../../../fonts';
import { primary } from '../../../colors';
import { FormattedMessage } from 'react-intl';

const MemoryCardSwitcher = ({
  setSelectedCard,
  selectedCard,
  setPriceCard,
  Cards,
  characteristics,
  priceMemoryCard,
  switchStyle,
  memoryCard,
  setSelectedCardForBuy
}) => {
  const handleCardSelect = (capacity) => {
    const selectedCard = memoryCard.find(card => card.capacity === capacity);
    if (selectedCard) {
      setPriceCard(selectedCard.price);
      setSelectedCardForBuy(selectedCard)
    }
  };
  return (
    <Stack gap={'20px'}>
      <Stack
        direction="row"
        sx={{
          ...fonts.fontInter16_600,
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'center',
          borderRadius: '100px',
          border: `2px solid rgba(67, 97, 238, 0.5)`,
          height: '50px',
        }}
      >
        <Box
          onClick={() => {
            setSelectedCard(Cards.card128);
            handleCardSelect("128")
          }}
          sx={{
            ...switchStyle,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor:
              selectedCard === Cards.card128 ? primary.blue : 'white',
            color: selectedCard === Cards.card128 ? 'white' : 'black',
            borderRadius: '100px 0 0 100px',
            transform:
              selectedCard === Cards.card128 ? 'scale(1.2)' : 'scale(1)',
            zIndex: selectedCard === Cards.card128 && 10,
          }}
        >
          128Gb
        </Box>
        <Box
          onClick={() => {
            setSelectedCard(Cards.card256);
            handleCardSelect("256")
          }}
          sx={{
            ...switchStyle,
            backgroundColor:
              selectedCard === Cards.card256 ? primary.blue : 'white',
            color: selectedCard === Cards.card256 ? 'white' : 'black',
            transform:
              selectedCard === Cards.card256 ? 'scale(1.2)' : 'scale(1)',
            zIndex: selectedCard === Cards.card256 && 10,
          }}
        >
          256Gb
        </Box>
        <Box
          onClick={() => {
            setSelectedCard(Cards.card512);
            handleCardSelect("512")
          }}
          sx={{
            ...switchStyle,
            backgroundColor:
              selectedCard === Cards.card512 ? primary.blue : 'white',
            color: selectedCard === Cards.card512 ? 'white' : 'black',
            transform:
              selectedCard === Cards.card512 ? 'scale(1.2)' : 'scale(1)',
            zIndex: selectedCard === Cards.card512 && 10,
          }}
        >
          512Gb
        </Box>
        <Box
          onClick={() => {
            setSelectedCard(Cards.card1024);
            handleCardSelect("1024")
          }}
          sx={{
            ...switchStyle,
            backgroundColor:
              selectedCard === Cards.card1024 ? primary.blue : 'white',
            color: selectedCard === Cards.card1024 ? 'white' : 'black',
            transform: selectedCard === Cards.card1024 ? 'scale(1.2)' : 'scale(1)',
            borderRadius: '0px 100px 100px 0px',
            zIndex: selectedCard === Cards.card1024 && 10,
          }}
        >
          1Tb
        </Box>
      </Stack>
      <Box sx={{ ...fonts.fontInter40}}>
    € {priceMemoryCard}{' '}<span style={{ ...fonts.fontInter24 }}>
      <FormattedMessage id={'tax_included'} />
    </span>
      </Box>
    </Stack>
  );
};

export default MemoryCardSwitcher;
