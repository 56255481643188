import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getProduct,
  getProductList,
} from '../../store/slices/productsSlice.js';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import FAQ from '../../components/FAQ/FAQ';
import { Stack, Typography, Breadcrumbs } from '@mui/material';
import ProductCharacteristics from '../../components/ProductCharacteristics/ProductCharacteristics';
import ProductTitle from '../../components/ProductTitle/ProductTitle';
import Slider from '../../components/Slider/Slider';
import ProductFlagSection from '../../components/ProductFlagSection/ProductFlagSection';
import ProductList from '../../components/ProductList/ProductList';
import PageTitle from '../../components/PageTitle/PageTitle';
import ProductRentTerms from '../../components/ProductRentTerms/ProductRentTerms';
import useWindowWidth from '../../utils/screenSize';
import Slider4 from '../../components/Slider4/Slider4';
import { FormattedMessage } from 'react-intl';

const Product = () => {
  const { page_name } = useParams();
  const dispatch = useDispatch();
  const reduxProduct = useSelector((state) => state.products.product[0]);
  const memory_card = useSelector((state) => state.products.memory_card);

  const viewedProducts= JSON.parse(localStorage.getItem('viewedProducts')) || [];
  if (reduxProduct && reduxProduct.id &&!viewedProducts.includes(reduxProduct?.id)) {
    viewedProducts.push(reduxProduct?.id);
      localStorage.setItem('viewedProducts', JSON.stringify(viewedProducts));
  }
 
  
  const { products } = useSelector((state) => state.products);
  let isPopularProducts = [];
  if (products) {
    isPopularProducts = products.filter((product) => product.is_popular);
  }
  useEffect(() => {
    dispatch(getProduct(page_name));
    dispatch(getProductList());
  }, [dispatch, page_name]);
  return (
    <>
      <Header />

      <Stack mt={'84px'} gap="44px" sx={{ alignItems: 'center' }}>
        <Stack sx={{ width: '100%', maxWidth: '1600px' }}>
        <Breadcrumbs aria-label="breadcrumb">
        <Link  style={{
          textDecoration:'none'
         }} to="/">
         <FormattedMessage id={"home"}/>
        </Link>
        <Link
         style={{
          textDecoration:'none'
         }}
          to="/catalog"
        >
          <FormattedMessage id={"catalog"}/>
        </Link>
        <Typography color="text.primary">{reduxProduct?.title}</Typography>
      </Breadcrumbs>
        </Stack>
        <ProductTitle characteristics={reduxProduct} memoryCard={memory_card}/>
        {useWindowWidth() < 1200 && <ProductRentTerms />}
        <ProductFlagSection />
        <ProductCharacteristics characteristics={reduxProduct} />
        {useWindowWidth() >= 1200 ? <Slider4 /> : <Slider />}
        <PageTitle
          text={<FormattedMessage id={"you_might_also_like"}/>}
          optionStyle={{
            width: 'calc(100% - 40px)',
            maxWidth: '1600px',
            fontSize: useWindowWidth() >= 1200 && '70px',
          }}
        />
        <ProductList propsProducts={isPopularProducts} />
        <FAQ />
      </Stack>

      <Footer />
    </>
  );
};

export default Product;
