import React, { useEffect, useState } from 'react';
import useWindowWidth from '../../utils/screenSize';
import { Link } from 'react-router-dom';
import { primary } from '../../colors';
import { fonts } from '../../fonts';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { get_WP_POST } from '../../store/slices/reviews_faqs.js';
import TextTruncate from '../../utils/textTruncate';
const LinkToBlogArticle = (props) => {
  const windowsSize = useWindowWidth();
  const dispatch = useDispatch();
  const { posts } = useSelector((state) => state.reviews);
  let myPost = null;
  let parser = null;
  let htmlDocument = null;
  if (posts.length > 0) {
    myPost = posts.find((post) => post.id === props.post);
    parser = new DOMParser();
    htmlDocument = parser.parseFromString(
      myPost?.content.rendered,
      'text/html'
    );
  }

  // console.log('LinkToBlogArticle', myPost);
  useEffect(() => {
    dispatch(get_WP_POST({ id: props.post }));
  }, []);

  if (posts.length > 0) {
    return (
      <>
        {windowsSize >= 1200 ? (
          <Link to={myPost?.link} style={{ ...props.styleLink }}>
            <img
              src={myPost?._embedded['wp:featuredmedia'][0].source_url}
              alt=""
              style={{ width: '100%', maxWidth: '319px' }}
            />
            <Box>{myPost?.title.rendered}</Box>
            <Box sx={{ ...fonts.fontInter18_400 }}>
              {TextTruncate(htmlDocument.body.textContent, 300)} &nbsp;
              <span style={{ color: primary.pink }}>Read more</span>{' '}
            </Box>
          </Link>
        ) : (
          <Link to={myPost?.link} style={{ ...props.styleLink }}>
            <img
              src={myPost?._embedded['wp:featuredmedia'][0].source_url}
              alt=""
              style={{ width: '100%', maxWidth: '319px' }}
            />
            {myPost?.title.rendered}
          </Link>
        )}
      </>
    );
  } else {
    return <div>Engineering works</div>;
  }
};

export default LinkToBlogArticle;
