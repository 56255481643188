import React, { useEffect } from 'react';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import styles from './ProfileForm.module.scss';
import { FormControl, MenuItem, Select } from '@mui/material';
import PinkButton from '../../Buttons/PinkButton';
import useWindowWidth from '../../../utils/screenSize';
import { userProfileSchema } from '../../../utils/validationSchems.js';
import { getTodayDate } from '../../../utils/getTodayDate.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateProfile,
  setIsUpdate,
} from '../../../store/slices/usersSlice.js';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
const ProfileForm = ({ user, isChange, setIsChange, intl }) => {
  const borderRadius = useWindowWidth() >= 1200 ? '100px' : '23px';
  const height = useWindowWidth() >= 1200 ? '72px' : '46px';
  const dispatch = useDispatch();
  console.log('user_profile', user);
  const initialValues = {
    name: user.name,
    surname: user.surname,
    date_of_birth: user.date_of_birth,
    phone: user.phone,
    city: user.city,
    delivery_address: user.delivery_address,
    sex: user.sex === 'Men' ? 'M' : 'W',
    email: '',
    email_confirm: '',
  };
  console.log(initialValues);
  const { isUpdate } = useSelector((state) => state.users);
  useEffect(() => {
    if (isUpdate) {
      dispatch(setIsUpdate());
      setIsChange(false);
    }
  }, [isUpdate, dispatch, setIsChange]);
  const date = getTodayDate();
  const minDate = `${date.year - 100}-${date.month}-${date.day}`;
  const maxDate = `${date.year - 17}-${date.month}-${date.day}`;

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    console.log('submit', values);
    dispatch(updateProfile(values));
    setSubmitting(false);
    resetForm();
  };

  return (
    <div className={styles.form}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={userProfileSchema}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form style={{ width: '100%' }}>
            <div>
              <Field
                type="text"
                id="name"
                name="name"
                placeholder={intl.formatMessage({ id: 'name' })}
                style={{ borderColor: 'rgba(67, 97, 238, 0.2)' }}
              />
              <ErrorMessage name="name">
                {(msg) => (
                  <div className={styles.error}>
                    <FormattedMessage id={msg} />
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div>
              <Field
                type="text"
                id="surname"
                name="surname"
                placeholder={intl.formatMessage({ id: 'surname' })}
                style={{ borderColor: 'rgba(67, 97, 238, 0.2)' }}
              />
              <ErrorMessage name="surname">
                {(msg) => (
                  <div className={styles.error}>
                    <FormattedMessage id={msg} />
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div>
              <input
                type="date"
                id="date_of_birth"
                name="date_of_birth"
                value={values.date_of_birth}
                onChange={(e) => setFieldValue('date_of_birth', e.target.value)}
                min={minDate}
                max={maxDate}
                style={{ borderColor: 'rgba(67, 97, 238, 0.2)' }}
              />
            </div>
            <div>
              <FormControl fullWidth>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  id="sex"
                  name="sex"
                  value={values.sex}
                  onChange={(e) => setFieldValue('sex', e.target.value)}
                  inputProps={{ 'aria-label': 'Without label' }}
                  placeholder="sex"
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      //  borderColor:'rgba(67, 97, 238, 0.2)',
                      border: '2px solid rgba(67, 97, 238, 0.2)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(67, 97, 238, 0.2)', // Border color on hover
                    },
                    borderRadius: borderRadius,
                    height: height,
                    padding: '0px 0px 0px 20px',
                    margin: '10px 0px 0px 0px',
                  }}
                >
                  <MenuItem value={'M'}>
                    <FormattedMessage id={'men'} />
                  </MenuItem>
                  <MenuItem value={'W'}>
                    <FormattedMessage id={'woman'} />
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <Field
                type="tel"
                id="phone"
                name="phone"
                placeholder={intl.formatMessage({ id: 'phone' })}
                style={{ borderColor: 'rgba(67, 97, 238, 0.2)' }}
              />
              <ErrorMessage name="phone">
                {(msg) => (
                  <div className={styles.error}>
                    <FormattedMessage id={msg} />
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div>
              <Field
                type="email"
                id="email"
                name="email"
                placeholder={intl.formatMessage({ id: 'email' })}
                style={{ borderColor: 'rgba(67, 97, 238, 0.2)' }}
              />
              <ErrorMessage name="email">
                {(msg) => (
                  <div className={styles.error}>
                    <FormattedMessage id={msg} />
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div>
              <Field
                type="email"
                id="email_confirm"
                name="email_confirm"
                placeholder={intl.formatMessage({ id: 'confirm_email' })}
                style={{ borderColor: 'rgba(67, 97, 238, 0.2)' }}
              />
              <ErrorMessage name="email_confirm">
                {(msg) => (
                  <div className={styles.error}>
                    <FormattedMessage id={msg} />
                  </div>
                )}
              </ErrorMessage>
            </div>
            <div>
              <Field
                type="text"
                id="delivery_address"
                name="delivery_address"
                placeholder={intl.formatMessage({ id: 'address' })}
                style={{ borderColor: 'rgba(67, 97, 238, 0.2)' }}
              />
              <ErrorMessage name="delivery_address">
                {(msg) => (
                  <div className={styles.error}>
                    <FormattedMessage id={msg} />
                  </div>
                )}
              </ErrorMessage>
              <Field
                type="text"
                id="city"
                name="city"
                placeholder={intl.formatMessage({ id: 'city' })}
                style={{ borderColor: 'rgba(67, 97, 238, 0.2)' }}
              />
              <ErrorMessage name="city">
                {(msg) => (
                  <div className={styles.error}>
                    <FormattedMessage id={msg} />
                  </div>
                )}
              </ErrorMessage>
            </div>

            <PinkButton
              text={<FormattedMessage id={'change_my_profile'} />}
              type="submit"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default injectIntl(ProfileForm);
