import React from 'react';
import { Stack, Box } from '@mui/material';
import { primary } from '../../colors';
import { fonts } from '../../fonts';

const OrderStages = ({step,setStep,Steps,memory_card_in_cart,isResident}) => {
 
  const cubeStyle = {
    minWidth: '54px',
    height: '54px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...fonts.fontInter24,
    borderRadius: '4px',
  };
  const lineContainerStyle = {
    width: '100%',
    maxWidth:memory_card_in_cart.length>0?'30px':'80px',
    height: '54px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const lineStyle = {
    width: '100%',
    height: '1px',
    backgroundColor: primary.blue,
  };

  return (
    <Stack direction="row" gap="8px">
      <Box
        sx={{
          ...cubeStyle,
          backgroundColor: step === Steps.firstStep ? primary.blue : '#a1b0f7',
        }}
        onClick={()=>{setStep(Steps.firstStep)}}
      >
        1
      </Box>
      <Box sx={{ ...lineContainerStyle }}>
        <Box sx={{ ...lineStyle }}></Box>
      </Box>
      <Box
        sx={{
          ...cubeStyle,
          backgroundColor: step === Steps.secondStep ? primary.blue : '#a1b0f7',
        }}
        onClick={()=>{setStep(Steps.secondStep)}}

      >
        2
      </Box>
      <Box sx={{ ...lineContainerStyle }}>
        <Box sx={{ ...lineStyle }}></Box>
      </Box>
      <Box
        sx={{
          ...cubeStyle,
          backgroundColor: step === Steps.thirdStep ? primary.blue : '#a1b0f7',
        }}
        onClick={()=>{setStep(Steps.thirdStep)}}

      >
        3
      </Box>
      {memory_card_in_cart.length>0&& (<>
        <Box sx={{ ...lineContainerStyle }}>
        <Box sx={{ ...lineStyle }}></Box>
      </Box>
      <Box
        sx={{
          ...cubeStyle,
          backgroundColor: step === Steps.fourthStep ? primary.blue : '#a1b0f7',
        }}
        onClick={()=>{setStep(Steps.fourthStep)}}

      >
        4
      </Box></>)}

      {!isResident&& (<>
        <Box sx={{ ...lineContainerStyle }}>
        <Box sx={{ ...lineStyle }}></Box>
      </Box>
      <Box
        sx={{
          ...cubeStyle,
          backgroundColor: step === Steps.depositStep ? primary.blue : '#a1b0f7',
        }}
        onClick={()=>{setStep(Steps.depositStep)}}

      >
       {memory_card_in_cart.length>0?5:4}
      </Box></>)}
    </Stack>
  );
};

export default OrderStages;
