import React from 'react';
import { Stack, Box } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import bg from '../../../images/chooseDroneSectionImg.jpeg';
import PageTitle from '../../PageTitle/PageTitle';
import { RequestForConsultationSchema } from '../../../utils/validationSchems.js';
import PinkButton from '../../Buttons/PinkButton';
import useWindowWidth from '../../../utils/screenSize';
import styles from './RepairFormOrder.module.scss';
import { fonts } from '../../../fonts';
import { primary } from '../../../colors';
import { useDispatch } from 'react-redux';
import { repairConsultation } from '../../../store/slices/repairsSlice.js';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

const initialState = {
  name: '',
  phone: '',
  message: '',
};
const stylesThisSection = {
  width: '100%',
  position: 'relative',
  backgroundImage: `url(${bg})`,
  overflow: 'hidden',
  padding: '50px 0px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  // marginTop:'76px',
  marginBottom: '-96px',
};
const pseudoElementStyle = {
  content: '""',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(166, 177, 226, 0.6)',
  zIndex: 1,
};
const stylesThisSectionHD = {
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  backgroundImage: `url(${bg})`,
  overflow: 'hidden',
  padding: '50px 0px',
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '0px -100px',
  gap: '20px',
  // marginTop:'76px',
  marginBottom: '-96px',
  minHeight: '52.5vh',
};
const spanStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  backgroundColor: primary.blue,
  border: `2px solid ${primary.blue}`,
  padding: '10px 30px',
  borderRadius: '100px',
};

const RequestForConsultation = ({ intl }) => {
  const dispatch = useDispatch();
  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    dispatch(repairConsultation(values));
    setSubmitting(false);
    resetForm();
  };

  return (
    <>
      {useWindowWidth() >= 1200 ? (
        <Stack sx={{ ...stylesThisSectionHD }}>
          <div style={pseudoElementStyle} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              zIndex: '10',
              ...fonts.fontPoppins80,
            }}
          >
            <FormattedMessage id="application" /> &nbsp;
            <span style={{ ...spanStyle }}>
              <FormattedMessage id="consultation" />
            </span>
          </Box>
          <Formik
            initialValues={initialState}
            onSubmit={handleSubmit}
            validationSchema={RequestForConsultationSchema}
          >
            {({ values, handleChange, setFieldValue, isSubmitting }) => (
              <Form className={styles.form}>
                <Stack direction="row">
                  <div>
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      placeholder={intl.formatMessage({ id: 'name' })}
                      style={{ borderColor:'rgba(67, 97, 238, 0.2)'}}
                    />
                    <ErrorMessage name="name">
                      {(msg) => (
                        <div className={styles.error}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div>
                    <Field
                      type="phone"
                      id="phone"
                      name="phone"
                      placeholder={intl.formatMessage({ id: 'phone' })}
                    />
                    <ErrorMessage name="phone">
                      {(msg) => (
                        <div className={styles.error}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </Stack>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Field
                    name="message"
                    as="textarea"
                    rows={8}
                    placeholder={intl.formatMessage({ id: 'enter_your_message' })}
                    style={{
                      width: '65%',
                      padding: '30px 0px 0px 30px',
                      borderRadius: '50px',
                      border: 'none',
                      ...fonts.fontInter16_400,
                    }}
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className={styles.error}
                  />

                  <PinkButton
                    type="submit"
                    text={<FormattedMessage id={"send"} />}
                    width={'40%'}
                    height={'72px'}
                    optionStyle={{ margin: '10px' }}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </Stack>
      ) : (
        <Stack sx={{ ...stylesThisSection }}>
          <div style={pseudoElementStyle} />
          <PageTitle
            text={<FormattedMessage id={"app_consult"} />}
            optionStyle={{
              textAlign: 'center',
              zIndex: '10',
              marginBottom: '20px',
            }}
          />
          <Formik
            initialValues={initialState}
            onSubmit={handleSubmit}
            validationSchema={RequestForConsultationSchema}
          >
            {({ values, handleChange }) => (
              <Form className={styles.form}>
                <div>
                  <Field type="text" id="name" name="name" placeholder={intl.formatMessage({ id: 'name' })} />
                  <ErrorMessage name="name">
                      {(msg) => (
                        <div className={styles.error}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                </div>
                <div>
                  <Field
                    type="phone"
                    id="phone"
                    name="phone"
                    placeholder={intl.formatMessage({ id: 'phone' })}
                  />
                  <ErrorMessage name="phone">
                      {(msg) => (
                        <div className={styles.error}>
                          <FormattedMessage id={msg} />
                        </div>
                      )}
                    </ErrorMessage>
                </div>
                <div>
                  <Field
                    name="message"
                    as="textarea"
                    rows={8}
                    placeholder={intl.formatMessage({ id: 'enter_your_message' })}
                    style={{
                      width: 'calc(100% - 20px)',
                      padding: '10px 0px 10px 20px',
                      borderRadius: '25px',
                      border: 'none',
                      ...fonts.fontInter16_400,
                    }}
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className={styles.error}
                  />
                </div>
                <PinkButton
                  type="submit"
                  text={<FormattedMessage id={"send"} />}
                  width={'168px'}
                  height={'46px'}
                  optionStyle={{ margin: '10px' }}
                />
              </Form>
            )}
          </Formik>
        </Stack>
      )}
    </>
  );
};

export default injectIntl(RequestForConsultation);
